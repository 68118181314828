<div class="col-lg-6 col-sm-12">
  <div class="col-12">
    <h4>Bine ai venit, {{userLogat.s_numeprenume}}</h4>

  </div>
  <hr>
  <div class="form-row col-12">
    <div class="col-2">Email</div>
    <div class="col-10"><a href="mailto:{{userLogat.s_email}}">{{userLogat.s_email}}</a></div>
  </div>
  <hr>
  <div class="form-row col-12">
    <div class="col-6">
      <a class="btn btn-small btn-warning" href="javascript:void(0)" (click)="changePassword()">Schimba parola</a>
    </div>
    <div class="col-6">
      &nbsp;
    </div>
  </div>

</div>
