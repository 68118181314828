<div class="modal-header bg-info text-white">
  <h4 class="modal-title">{{dataPontaj}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="container form-horizontal" #f="ngForm">
    <h5>{{numefull}}</h5>
    <fieldset *ngIf="idpontaj">
      <div class="form-row col-12">
            <label class="col-form-label">Nota</label>
            <textarea name="textNota" class="md-textarea form-control" rows="3" [(ngModel)]="textNota">{{textNota}}</textarea>
          </div>
    </fieldset>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" (click)="save()" [disabled]="!idpontaj">Salveaza</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Anuleaza</button>
</div>
