
export class Personal {
    s_idpers: number;
    s_idpersonal: string;
    s_nume: string;
    s_prenume: string;
    s_cnp: string;
    s_idfunctie:string;
    s_marca: number;
    s_inactiv:number;
    s_data_inactiv: string;
    s_anluna_inactiv: number;
    s_idcompartiment: string;
    s_numefull: string;
    s_functie: string;
    s_compartiment: string;
    s_echipa: string;
    s_email: string;
    s_pwd: string;
    s_tipuser: number;
    s_passbtoa: string;
}

export class Persoana {
    s_idpers: number;
    s_idpersonal: string;
    s_nume: string;
    s_prenume: string;
    s_cnp: string;
    s_idfunctie:string;
    s_marca: number;
    s_inactiv:number;
    s_data_inactiv: string;
    s_anluna_inactiv: number;
    s_idcompartiment: string;
    s_numefull: string;
}

export class Functie {
    s_idfunc: number;
    s_idfunctie: string;
    s_functie: string;
}

export class PersonalColoana {
    s_idperscol: number;
    s_idpersonal: string;
    s_col: string;
    s_coloana: string;
    s_tipcoloana: string;
    
}

export class Compartiment {
    s_idcomp: number;
    s_idcompartiment: string;
    s_compartiment: string;
    s_idsefcompartiment: string;
    s_idsupervizor: string;
    s_numefull: string;
    s_functie: string;
    s_numefullsupervizor: string;
    s_functiesupervizor: string;
    s_stamp: string;
}

export class ListaCompartimente {
    s_idcomp: number;
    s_idcompartiment: string;
    s_compartiment: string;
    s_idsefcompartiment: string;
    s_idsupervizor: string;
    s_numefull: string;
    s_functie: string;
    s_numefullsupervizor: string;
    s_functiesupervizor: string;
    s_stamp: string;
    s_nume_user: string;
}

export class ListaUseri {
    s_iduser: number;
    s_cod_user: string;
    s_nume_user: string;
    s_user: string;
    s_admin: number;
    s_exploatare: number;
    s_tehnic: number;
    s_pwd: number;
    s_garaj: number;
    s_faz: number;
    s_consum: number;
    s_pontajraport: number;
    s_pontajfull: number;
    s_programarepersonal: number;
    s_data_creat: string;
    s_data_modpwd: string;
    s_flag_inactiv: number;
    s_data_inactiv: string;
}
export class Schimb {
    s_id: number;
	s_idschimb: string;
	s_codprg: string
    s_codprg_: string
	s_orastartsec: number;
	s_duratasec: number;
    s_duratasecnoapte: number;
    s_orastart: number;
	s_durata: number;
    s_duratanoapte: number;
	s_stamp:string;
	s_idcompartiment:string;
	s_flag_inactiv: number;

}

export class ListaSchimburi {
    s_id: number;
	s_idschimb: string;
	s_codprg: string
    s_codprg_: string
	s_orastartsec: number;
	s_duratasec: number;
    s_duratasecnoapte: number;
    s_orastart: number;
	s_durata: number;
    s_duratanoapte: number;
	s_stamp:string;
	s_idcompartiment:string;
	s_flag_inactiv: number;

}