<div class="modal-header bg-info text-white">
  <h4 class="modal-title">Schimb</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="container form-horizontal" #f="ngForm">

    <fieldset *ngIf="schimb">
      <div class="form-row">
        <div class="col-12">
          <label class="col-form-label">Compartiment</label>
          <select class="form-control form-control-sm" name="s_idcompartiment" [(ngModel)]="schimb.s_idcompartiment"
            [disabled]="idCompartiment">
            <option *ngFor="let f of listaCompartimente" [value]="f.s_idcompartiment">{{f.s_compartiment}}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="col-4">
          <label class="col-form-label">Simbol</label>
          <input type="text" class="form-control form-control-sm" name="s_codprg" [(ngModel)]="schimb.s_codprg">
        </div>
        <div class="col-8">
          <label class="col-form-label">Nume schimb</label>
          <input type="text" class="form-control form-control-sm" name="s_codprg_" [(ngModel)]="schimb.s_codprg_">
        </div>
      </div>
      <div class="form-row">
        <div class="col-4">
          <label class="col-form-label">Ora incepere</label>
          <div class="input-group input-group-sm">
            <input type="number" class="form-control form-control-sm col" name="oraStartHH" [(ngModel)]="oraStartHH"
              min="0" max="23" (blur)="calcOraSfarsit()">
            <div class="input-group-append">
              <span class="input-group-text" id="inputGroup-sizing-sm">:</span>
            </div>
            <input type="number" class="form-control form-control-sm col" name="oraStartMM" [(ngModel)]="oraStartMM"
              min="0" max="59" (blur)="calcOraSfarsit()">
          </div>
        </div>
        <div class="col-4">
          <label class="col-form-label">Durata</label>
          <div class="input-group input-group-sm">
            <input type="number" class="form-control form-control-sm col" name="durataHH" [(ngModel)]="durataHH" min="0"
              max="23" (blur)="calcOraSfarsit()">
            <div class="input-group-append">
              <span class="input-group-text" id="inputGroup-sizing-sm">:</span>
            </div>
            <input type="number" class="form-control form-control-sm col" name="durataMM" [(ngModel)]="durataMM" min="0"
              max="59" (blur)="calcOraSfarsit()">
          </div>
        </div>
        <div class="col-4">
          <label class="col-form-label">Ora sfarsit</label>
          <div class="input-group input-group-sm">
            <input type="number" class="form-control form-control-sm col" name="oraSfarsitHH" [(ngModel)]="oraSfarsitHH"
              min="0" max="23" disabled>
            <div class="input-group-append">
              <span class="input-group-text" id="inputGroup-sizing-sm">:</span>
            </div>
            <input type="number" class="form-control form-control-sm col" name="oraSfarsitMM" [(ngModel)]="oraSfarsitMM"
              min="0" max="59" disabled>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-4">
          <label class="col-form-label">Ore noapte pe acest schimb</label>
          <div class="input-group input-group-sm">
            <input type="number" class="form-control form-control-sm col" name="oreNoapteHH" [(ngModel)]="oreNoapteHH"
              min="0" max="23" disabled>
            <div class="input-group-append">
              <span class="input-group-text" id="inputGroup-sizing-sm">:</span>
            </div>
            <input type="number" class="form-control form-control-sm col" name="oreNoapteMM" [(ngModel)]="oreNoapteMM"
              min="0" max="59" disabled>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" (click)="save()">Salveaza</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Anuleaza</button>
</div>
