<div class="col-12">
    <h4>Puncte de lucru {{numeCompartiment}}</h4>
    <div class="alert alert-secondary col-12">
      <form #f="ngForm">
        <div class="col-12">
          <div class="form-group row">
            <div class="col-6 row">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fa fa-search"></i></div>
                </div>
  
                <input type="text" class="form-control form-control-sm col-4" name="cauta" [(ngModel)]="searchText"
                  (ngModelChange)="getListaPuncteLucru()">
              </div>
            </div>
            <div class="col-6 text-right">
              <button class="btn btn-sm btn-success ml-1" (click)="edit('')"><i
                  class="fa fa-plus-circle"></i>&nbsp;Adauga</button>
              <button class="btn btn-sm btn-primary ml-1" (click)="edit(idPunctLucru)" [disabled]="isDisabled()"><i
                  class="fa fa-edit"></i>&nbsp;Modifica</button>
              <button class="btn btn-sm btn-warning ml-1" (click)="setStatus(idPunctLucru)" [disabled]="isDisabled()"><i
                  class="{{isInactiv ? 'fa fa-lock-open' : 'fa fa-lock'}}"></i>&nbsp;Status</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  
  </div>
  <div class="col-12" *ngIf="idCompartiment">
    <navigaretab></navigaretab>
  
    </div>
    <div class="form-row col-12">
        <div class="col-6">
          <ag-grid-angular style="height: 70vh;" class="ag-theme-alpine" [gridOptions]="grid.gridOptions">
          </ag-grid-angular>
        </div>
    </div>
  
  