import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/_services';
import { UtilsService } from '@app/_services/utils.service';
import * as dayjs from 'dayjs'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AgGrid, AgGridColumnSize, ColDef, Renderers, RowNode } from '@app/controls';

@Component({
  selector: 'app-sarbatori-legale',
  templateUrl: './sarbatori-legale.component.html',
  styleUrls: ['./sarbatori-legale.component.css']
})
export class SarbatoriLegaleComponent implements OnInit {

  constructor(private utils: UtilsService, private api: ApiService) { }

  grid: AgGrid;
  listaZsl
  an
  dataZsl

  ngOnInit(): void {
    this.getAn();
    this.setupGrid()
    this.getGridData()
  }

  getAn() {
    var al = this.utils.getAlExtendedFromAnLuna(this.utils.anLunaPontaj);
    this.an = al.an;

  }

  setupGrid() {
    var colDefs: ColDef[] = [
      { headerName: 'Ziua', field: 's_datasl', },
    ];
    this.grid = new AgGrid(colDefs, false, true);
    this.grid.onDataLoaded = () => { }
    this.grid.onRowSelected = () => { }

  }

  getGridData() {
    this.api.fdbGetSarbatoriLegale(this.an, 0).then((d) => {
      this.listaZsl = d;
      this.grid.setDataSource(this.listaZsl)
    })

  }

  addZsl() {
    var dsl = this.utils.pickerToDate(this.dataZsl)
    this.api.fdbAddSarbatoareLegala(dsl).subscribe((d) => {
      this.getGridData()
    })
  }

  delZsl() {
    var where = " s_datasl='" + this.grid.selectedRow.s_datasl + "' ";
    this.api.fdbDeleteElementByWhere('s_sarbatorilegale', where).subscribe(()=>{
      this.getGridData();
    })
  }

  isDisabled(tip) {
    switch (tip) {
      case 'add': return !this.dataZsl; break;
      case 'del': return !this.grid.selectedRow.s_datasl; break;
      default: return true;

    }
  }

}
