import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '@app/_services/authentication.service';
import { PontajSet } from './pontaj.set';
import { UtilsService } from './../_services/utils.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, DialogService } from '@app/_services';
import * as XLSX from 'xlsx';
import { SetNotaZi } from './setNotaZi';
import { PersonalPontaj } from '@app/_models/pontaj';
import * as dayjs from 'dayjs';
import { PontajAddPersonal } from './pontaj.add.personal';
import { PontajUpdate } from './pontaj.update';
import { PontajPunctLucru } from './pontaj.punct.lucru';

@Component({
  selector: 'app-pontaj',
  templateUrl: './pontaj.component.html',
  styleUrls: ['./pontaj.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PontajComponent implements OnInit {

  constructor(private apiService: ApiService, private dialog: DialogService, private log: ToastrService,
    private activatedRoute: ActivatedRoute, private utils: UtilsService, private router: Router, private auth: AuthenticationService) {

  }

  listaPersonalPontaj: PersonalPontaj[];
  listaSchimburiCompartiment: any[] = [];
  campSort: string = 'echipa';
  ordineSort: string = 'ASC'
  inactiv: number = 0;
  searchText: string = '';
  idCompartiment;
  luni
  indexLunaPontaj: number;
  numarLunaPontaj: number
  anPontaj: number;
  zl: number;
  arrZl: any[] = [];
  butAddOre = "<i class='fa fa-clock-o'></i>";
  numeCompartiment: string;
  oreLucratoare: number = 0;
  zileLucratoare;
  anLunaPontaj
  isExport: boolean = false
  isPrint: boolean = false;
  isPrintProgram: boolean = false;
  isDecimal: boolean = false
  colspanZile: number = 25;
  titluPontaj: string;
  total = {
    totalSecpontaj: 0, totalSecpontajzl: 0, totalSecnoapte: 0, totalSecdepasiri: 0, totalSecduratasuplimentara: 0, totalSecpontajlucrat: 0,
    totalZb: 0, totalZco: 0, totalZcfs: 0, totalZds: 0,
    totalZes: 0, totalZlp: 0, totalZpsi: 0, totalZr: 0, totalZrv: 0,
    totalZrs: 0, totalZvma: 0, totalZn: 0,
    totalZsd: 0, totalZL: 0, totalZm: 0, totalZa: 0,
    totalSecosl: 0, totalSosp: 0, totalSosrep: 0,
    totalSecOc: 0, totalSecOg: 0, totalSecOcn: 0,
    totalSrlp: 0
  }

  zileSL

  listaActivitate: any[] = [];
  intocmit
  dataIntocmit
  listaPuncteLucru
  aprobDirectorGeneral


  ngOnInit(): void {
    localStorage.setItem('curentUrl', this.router.url)
    this.auth.getUserLogat.emit(this.auth.currentUserValue)

    this.intocmit = this.auth.currentUserValue
    this.dataIntocmit = dayjs(new Date()).format('DD/MM/YYYY')
    this.idCompartiment = this.activatedRoute.snapshot.paramMap.get('idCompartiment') || "";
    //console.log(this.idCompartiment)
    // this.utils.anLunaPontaj = +this.activatedRoute.snapshot.paramMap.get('anLuna');
    this.anLunaPontaj = this.utils.anLunaPontaj
    this.indexLunaPontaj = (this.anLunaPontaj - Math.floor(this.anLunaPontaj / 100) * 100) - 1
    this.numarLunaPontaj = (this.anLunaPontaj - Math.floor(this.anLunaPontaj / 100) * 100)
    this.anPontaj = Math.floor(this.anLunaPontaj / 100);
    this.apiService.fdbGetCompartimentById(this.idCompartiment).subscribe((d) => {
      this.numeCompartiment = d[0].s_compartiment;
      this.titluPontaj = 'Pontaj ' + this.numeCompartiment.trim() + ' - ' + this.utils.getPerioadaByIndex(this.anPontaj, this.indexLunaPontaj)
    });

    this.apiService.fdbGetListaPuncteLucru(this.idCompartiment, '').then((d)=>{
      this.listaPuncteLucru = d;
    })


    this.luni = this.utils.luni2

    this.zileLucratoare = this.utils.getZileLucratoareLuna(this.anPontaj, this.indexLunaPontaj);

    this.utils.getSl(this.anPontaj, this.numarLunaPontaj).then((res) => {
      if (res) {
        this.zileSL = res;
        //console.log('res', res)
        for (var i = 0; i < this.zileSL.length; i++) {
          if (this.zileSL[i].s_zisapt > 1 && this.zileSL[i].s_zisapt < 7)
            this.zileLucratoare -= 1;
        }

      }
      this.oreLucratoare = this.zileLucratoare * 8;
      this.getZileLuna()

    });

    this.apiService.fdbGeSchimbForPontaj(this.idCompartiment, 'tot').subscribe((d: any[]) => {
      this.listaSchimburiCompartiment = d;
      //console.log(this.listaSchimburiCompartiment)
      for (var i = 0; i < this.listaSchimburiCompartiment.length; i++) {
        if (this.listaSchimburiCompartiment[i].s_isschimb == 'A') {
          this.listaActivitate.push(this.listaSchimburiCompartiment[i]);
        }
      }
      //console.log(this.listaActivitate)
      this.afiseaza()
    });

  }

  getDirectorGeneral(){
    this.apiService.fdbGetElementById('s_personal', 's_idfunctie', 'FCT_EDCBXXX1DXACXXEBXXCCXXXXXE6BXXX', 1).subscribe((d)=>{
        this.aprobDirectorGeneral = d[0].s_numefull;
    });
  }
  getHHMM(sec) {
    return this.utils.getHourMinFromSec(sec, true)
  }

  isZiLucratoare(zi) {
    // verifica daca e zi lucratoare sau nu
    if (this.utils.getDow(zi, this.anPontaj, this.indexLunaPontaj) == "S" || this.utils.getDow(zi, this.anPontaj, this.indexLunaPontaj) == "D") {
      return false
    } else {
      return true
    }

  }

  isSarbatoareLegala(ziluna) {
    var sl = false;
    for (var i = 0; i < this.zileSL.length; i++) {
      if (parseInt(this.zileSL[i].s_zsl) == ziluna) {
        //console.log('sarbatoare', ziluna);
        //console.log(typeof (this.zileSL[i].s_zsl))

        sl = true
        break;
      }
    }
    return sl;

  }

  addPersoanaForPontaj() {
    this.dialog.custom(PontajAddPersonal,
      {
        anLuna: this.anLunaPontaj,
        idCompartiment: this.idCompartiment,
      }, { size: 'lg' }).result.then(() => {
        this.afiseaza();
      })

  }

  afiseaza() {

    var secOreLucratoare = this.utils.getSeconds(this.oreLucratoare, 0)
    this.apiService.fdbGetPersonalPontaj(this.idCompartiment, this.campSort, this.ordineSort, this.inactiv, this.anLunaPontaj, this.searchText).subscribe((d) => {
      this.listaPersonalPontaj = d;
      console.log(this.listaPersonalPontaj)
      for (var i = 0; i < this.listaPersonalPontaj.length; i++) {
        var pi = this.listaPersonalPontaj[i];
        //pi.srvnoapte = 0;
        var secpontaj: number = 0;   // ore pontate din schimburi (in modul pentru export se adauga si ore suplimentare)
        var secpontajzl: number = 0 // ore numai din zilele lucratoare
        var secnoapte: number = 0; // ore noapte
        var secdepasiri: number = 0; // depasiri fata de orele din luna
        var secduratasuplimentara: number = 0; // durata suplimentara in fiecare zi
        var secpontajlucrat: number = 0 //secpontaj + secduratasuplimentara
        var secdepasiriA: number = 0; // depasiri din concedii si din alte alea
        /*
        var secslc: number = 0 // sec suplimentare luna curenta
        var secspl: number = 0 // sec suplimentare luna curenta platite - se pun manual

        var secCO: number = 0 // ore din concedii odihna
        var secB: number = 0 // ore din concedii medicale
        var secCFS: number = 0 // ore din concedii fara salar
        var secR: number = 0
        var secVMA: number = 0
        var secES: number = 0 // ore din eveniment special
        var secLP: number = 0
        var secRS: number = 0
        var secDS: number = 0 // ore din eveniment special
        var secPSI: number = 0 // ore din ex. psi.
        var secN: number = 0
        var secM: number = 0 //ore din maternitate/paternitate
        */
        var za: number = 0;

        var an = this.anPontaj
        var luna = this.indexLunaPontaj

        // ORE LUCRATE
        for (var j = 1; j < 32; j++) {
          if (pi['s_z' + j + '_sch'] == "S") {
            secpontaj += (1 * pi['s_z' + j + '_sdurata']);
            secnoapte += (1 * pi['s_z' + j + '_sduratanoapte']);
            secduratasuplimentara += (1 * pi['s_z' + j + '_sduratasuplimentara']);
            //console.log('secpontajlucrat', secpontajlucrat);
            if (this.isZiLucratoare(j)) {
              secpontajzl += (1 * pi['s_z' + j + '_sdurata']);
              //za = za + 1;
            }
          }

          // sambete si duminici
          if (pi['s_z' + j + '_sdurata'] > 0 && (!this.isZiLucratoare(j))) {
            pi.zsd++;
          }
          // zile activitate + ore activitate (daca au in programarsimbolzi)
          if (pi['s_z' + j + '_codprg'] == "CO") {
            pi.zco++;
            var sco = (1 * this.getDurataActivitate("CO"))
            pi.sco = pi.zco * sco
            secdepasiriA += sco
            //console.log('pi.sco', pi.sco)

          }
          if (pi['s_z' + j + '_codprg'] == "B") {
            //console.log('B', pi)
            pi.zb++;
            var sb = (1 * this.getDurataActivitate("B"))
            pi.sb = pi.zb * sb
            secdepasiriA += sb
          }
          if (pi['s_z' + j + '_codprg'] == "CFS") {
            pi.zcfs++;
            var scfs = (1 * this.getDurataActivitate("CFS"))
            pi.scfs = pi.zcfs * scfs
            secdepasiriA += scfs
          }
          if (pi['s_z' + j + '_codprg'] == "VMA") {
            pi.zvma++;
            var svma = 1 * this.getDurataActivitate("VMA")
            pi.svma = pi.zvma * svma
            secdepasiriA += svma
          }
          if (pi['s_z' + j + '_codprg'] == "R") {
            pi.zr++;
            var sr = 1 * this.getDurataActivitate("R")
            pi.sr = pi.zr * sr
            secdepasiriA += sr
          }
          if (pi['s_z' + j + '_codprg'] == "RV") {
            pi.zrv++;
            var srv = (1 * parseInt(pi['s_z' + j + '_sdurata']))
            pi.srv = +pi.srv + srv
            var srvnoapte = (1 * parseInt(pi['s_z' + j + '_sduratanoapte']));
            //console.log('srvnoapte', 1 * srvnoapte)
            pi.srvnoapte = 1 * pi.srvnoapte + 1 * srvnoapte;
            //console.log('pi.srvnoapte', pi.srvnoapte)
            secdepasiriA += srv;
          }
          if (pi['s_z' + j + '_codprg'] == "ES") {
            pi.zes++;
            var ses = 1 * this.getDurataActivitate("ES")
            pi.ses = pi.zes * ses
            secdepasiriA += ses
          }
          if (pi['s_z' + j + '_codprg'] == "LP") {
            pi.zlp++;
            var slp = (1 * parseInt(pi['s_z' + j + '_sdurata']))
            pi.slp = +pi.slp + slp;
            // console.log(typeof (pi.slp))
            //secdepasiriA += slp
          }
          if (pi['s_z' + j + '_codprg'] == "RS") {
            pi.zrs++;
            var srs = 1 * this.getDurataActivitate("RS")
            pi.srs = pi.zrs * srs
            secdepasiriA += srs
          }
          if (pi['s_z' + j + '_codprg'] == "DS") {
            pi.zds++;
            var sds = 1 * this.getDurataActivitate("DS")
            pi.sds = pi.zds * sds
            secdepasiriA += sds
          }
          if (pi['s_z' + j + '_codprg'] == "PSI") {
            pi.zpsi++;
            var spsi = 1 * this.getDurataActivitate("PSI")
            pi.spsi = pi.zpsi * spsi
            secdepasiriA += spsi
          }
          if (pi['s_z' + j + '_codprg'] == "N") {
            pi.zn++;
            var sn = 1 * this.getDurataActivitate("N")
            pi.sn = pi.zn * sn
            secdepasiriA += sn
          }
          if (pi['s_z' + j + '_codprg'] == "M") {
            pi.zm++;
            var sm = 1 * this.getDurataActivitate("M")
            pi.sm = pi.zm * sm
            secdepasiriA += sm
          }
          if (!pi['s_z' + j + '_codprg'] && this.isZiLucratoare(j) && !this.isSarbatoareLegala(j)) {
            // console.log('j',j)
            //console.log('codprg',pi['s_z' + j + '_codprg'])
            pi.zgol++;
          }

        }
        if (secpontaj > secOreLucratoare) {
          secdepasiri = secpontaj - secOreLucratoare
          //depasiri = this.utils.getHourMinFromSec(secDepasiri, true)
        }
        secdepasiri += (secdepasiriA + secduratasuplimentara);
        console.log('pi.srvnoapte', pi.s_nume + pi.srvnoapte)
        pi.secpontaj = secpontaj + (1 * pi.slp) + (1 * pi.srv);
        pi.secpontajzl = secpontajzl;
        pi.secnoapte = 1 * secnoapte +  1 * pi.srvnoapte;
        pi.secdepasiri = secdepasiri;
        pi.secduratasuplimentara = secduratasuplimentara;
        pi.secpontajlucrat = pi.secpontaj + pi.secduratasuplimentara;

        //pi.za = this.zileLucratoare - pi.zb - pi.zco - pi.zes - pi.zcfs - pi.zr - pi.zds - pi.zm- pi.zn;
        //sconsole.log("pi.zgol", pi.zgol)

        // am scos pi.zr din formula ca asa a zis nelu 12.04.2022
        pi.za = this.zileLucratoare - pi.zb - pi.zco - pi.zes - pi.zcfs - pi.zds - pi.zm - pi.zn;

        //console.log(pi.za)
        if (pi.za > this.zileLucratoare) pi.za = this.zileLucratoare;

        pi.s_sosl = (pi.secpontajlucrat
          + 1 * pi.sco
          + 1 * pi.sb
          + 1 * pi.ses
          + 1 * pi.sn
          + 1 * pi.scfs)
          - 3600 * this.oreLucratoare;

        //pi.za = Math.floor((pi.secpontajlucrat / 8) / 3600);

        if (pi.s_sosl < 0) pi.s_sosl = 0;
        //console.log('pi.s_sosl', pi.s_sosl)
        pi.s_sosl += 1 * pi.s_srlp;
        pi.s_sosl -= 1 * pi.slp;
        pi.secpontajzl = pi.secpontajlucrat - (1 * pi.s_sosl) + (1 * pi.s_sosp)
        pi.s_sosrep = pi.s_sosl - pi.s_sosp
      
        var listaCampuri = 's_sosl|s_sosrep|s_co|s_b|s_cfs|s_r|s_vm|s_es|s_lp|s_rs|s_rv|s_ds|s_psi|s_n|s_m';
        var listaValori = pi.s_sosl + '|' + pi.s_sosrep + '|' + pi.zco + '|' + pi.zb + '|' + pi.zcfs + '|' + pi.zr + '|' + pi.zvma + '|' + pi.zes + '|' + pi.zlp + '|' + pi.zrs + '|' + pi.zrv + '|' + pi.zds + '|' + pi.zpsi + '|' + pi.zn + '|' + pi.zm
        this.apiService.fdbUpdatePontajMultiple(pi.s_idpontaj, listaCampuri, listaValori).subscribe(() => {
        });
        this.calcTotal()
      }
    })

  }
  getDurataActivitate(a) {
    var durataA = 0
    for (var i = 0; i < this.listaActivitate.length; i++) {
      if (this.listaActivitate[i].s_codprg == a) {
        durataA = this.listaActivitate[i].s_duratasecactivitate
      }
    }
    return durataA
  }

  afiseazaOre(sec) {
    var hd = this.utils.getHourMinFromSec(sec, false);
    var valoare = hd[2]
    if (this.isDecimal) {
      valoare = hd[3]
    }
    return valoare
  }

  calcTotal() {
    var msg = '';
    this.total.totalSecpontaj = 0
    this.total.totalSecpontajzl = 0
    this.total.totalSecnoapte = 0
    this.total.totalSecdepasiri = 0
    this.total.totalSecduratasuplimentara = 0
    this.total.totalSecpontajlucrat = 0;
    this.total.totalZb = 0
    this.total.totalZco = 0
    this.total.totalZcfs = 0
    this.total.totalZds = 0
    this.total.totalZes = 0
    this.total.totalZlp = 0
    this.total.totalZpsi = 0
    this.total.totalZr = 0
    this.total.totalZrs = 0
    this.total.totalZrv = 0
    this.total.totalZvma = 0
    this.total.totalZn = 0
    this.total.totalZm = 0
    this.total.totalZsd = 0
    this.total.totalZa = 0
    this.total.totalSecosl = 0
    this.total.totalSosp = 0
    this.total.totalSosrep = 0
    this.total.totalSecOc = 0
    this.total.totalSecOg = 0
    this.total.totalSecOcn = 0
    this.total.totalSrlp = 0;
    for (var i = 0; i < this.listaPersonalPontaj.length; i++) {
      var pi = this.listaPersonalPontaj[i];
      this.total.totalSecpontaj += pi.secpontaj
      this.total.totalSecpontajzl += pi.secpontajzl;
      this.total.totalSecnoapte += 1 * pi.secnoapte
      this.total.totalSecdepasiri += pi.secdepasiri
      this.total.totalSecduratasuplimentara += pi.secduratasuplimentara
      this.total.totalSecpontajlucrat += pi.secpontajlucrat
      this.total.totalZb += pi.zb * 1
      this.total.totalZco += pi.zco * 1
      this.total.totalZcfs += pi.zcfs * 1
      this.total.totalZds += pi.zds * 1
      this.total.totalZes += pi.zes * 1
      this.total.totalZlp += pi.zlp * 1
      this.total.totalZpsi += pi.zpsi * 1
      this.total.totalZr += pi.zr * 1
      this.total.totalZrv += pi.zrv * 1
      this.total.totalZrs += pi.zrs * 1
      this.total.totalZvma += pi.zvma * 1
      this.total.totalZn += pi.zn * 1
      this.total.totalZm += pi.zm * 1
      this.total.totalZsd += pi.zsd * 1
      this.total.totalZa += pi.za * 1
      this.total.totalSecosl += pi.s_sosl * 1
      this.total.totalSosp += pi.s_sosp * 1
      this.total.totalSosrep += pi.s_sosrep * 1
      this.total.totalSecOc += pi.s_soc * 1
      this.total.totalSecOg += pi.s_sog * 1
      this.total.totalSecOcn += pi.s_socn * 1
      this.total.totalSrlp += pi.s_srlp * 1
    }
  }

  checkIsPontajToti() {
    var msg = '';
    if (!this.listaPersonalPontaj && !this.listaPersonalPontaj.length) {
      msg = 'Nu exista personal programat in pontaj'
    }
    return msg;
  }


  getZileLuna() {
    this.zl = this.utils.getZileInLuna(this.anPontaj, this.indexLunaPontaj)
    for (var i = 0; i < this.zl; i++) {
      //console.log(i)

      this.arrZl.push({ zi: i + 1, zisapt: this.getCodZi(i + 1), numezi: this.getNumeZi(i + 1) })

    }

    //this.arrZl = Array(this.zl)
    this.colspanZile = this.arrZl.length
    //console.log("arrzl", this.arrZl)
  }

  pontajZi(idpontaj, idechipa, numefull, zi, idschimb?, durata?, duratasuplimentara?, duratanoapte?) {
    if (this.intocmit.s_tipuser == 1 || this.intocmit.s_tipuser == 2 || this.intocmit.s_tipuser == 4) {
      var tipzi = this.getCodZi(zi)
      this.dialog.custom(PontajSet,
        {
          idpontaj: idpontaj,
          durata: durata,
          durataSuplimentara: duratasuplimentara,
          duratasecnoapte: duratanoapte,
          idechipa: idechipa,
          idCompartiment: this.idCompartiment,
          idschimb: idschimb,
          numefull: numefull,
          zi: zi,
          tipzi: tipzi,
          anLuna: this.anLunaPontaj
        }, { size: 'xs' }).result.then(() => {
          this.afiseaza();
        })
    } else {
      this.log.error('Nu aveti dreptul sa modificati pontajul')
    }

  }

  notaZi(idpontaj, zi, nota, numefull) {
    var tipzi = this.getCodZi(zi)
    this.dialog.custom(SetNotaZi,
      {
        idpontaj: idpontaj,
        zi: zi,
        tipzi: tipzi,
        anLuna: this.anLunaPontaj,
        numefull: numefull
      }, { size: 'xs' }).result.then(() => {
        this.afiseaza();
      })
  }

  setPunctLucruZi(idpontaj, zi, idpunctlucru, numefull){
    this.dialog.custom(PontajPunctLucru,
      {
        idpunctlucru: idpunctlucru,
        zi: zi,
        idCompartiment: this.idCompartiment,
        idpontaj: idpontaj,
        numefull: numefull,
        anLuna: this.anLunaPontaj
      }, { size: 'xs' }).result.then(() => {
        this.afiseaza();
      })
  }

  getField(tip, indexPontaj, nrZi) {
    var fieldp = ""
    switch (tip) {
      case 'codprg': fieldp = 's_z' + nrZi + '_codprg'; break;
      case 'durata': fieldp = 's_z' + nrZi + '_sdurata'; break;
      case 'duratasuplimentara': fieldp = 's_z' + nrZi + '_sduratasuplimentara'; break;
      case 'duratanoapte': fieldp = 's_z' + nrZi + '_sduratanoapte'; break;
      case 'nota': fieldp = 's_z' + nrZi + '_nota'; break;
      case 'punctlucru': fieldp = 's_z' + nrZi + '_nr_inmatr'; break;
      case 'tipactivitate': fieldp = 's_z' + nrZi + '_sch'; break;
    }
    //console.log(this.listaPersonalPontaj[indexPontaj][fieldp])
    return this.listaPersonalPontaj[indexPontaj][fieldp];
  }

  getSchimbById(id, sdurata, sduratasuplimentara) {
    var schimb = '<div class="zigol"><i class="far fa-clock"></i></div>';
    var hhmm = this.utils.getHourMinFromSec(sdurata)
    var sdurataTotalaZi = parseInt(sdurata) + parseInt(sduratasuplimentara)
    if (this.isExport) {
      for (var i = 0; i < this.listaSchimburiCompartiment.length; i++) {
        if (this.listaSchimburiCompartiment[i].s_idschimb == id) {
          schimb = '<div class="' + this.listaSchimburiCompartiment[i].s_codprg + '">'
            + (sduratasuplimentara == 0 ? '<div class="zi-codprg">' : '<div class="OSL">')
            + ((this.listaSchimburiCompartiment[i].s_isschimb == "A" && this.listaSchimburiCompartiment[i].s_idschimb != 'LP') ? this.listaSchimburiCompartiment[i].s_codprg : this.afiseazaOre(sdurataTotalaZi))
            + '</div> '
          break;
        }
      }
    } else {
      for (var i = 0; i < this.listaSchimburiCompartiment.length; i++) {
        if (this.listaSchimburiCompartiment[i].s_idschimb == id) {
          schimb = '<div class="' + this.listaSchimburiCompartiment[i].s_codprg + '">'
            + '<div class="zi-codprg">' + this.listaSchimburiCompartiment[i].s_codprg + '</div> ';
          if (!this.isPrintProgram)  {
            schimb +='<div class="zi-oreprg">' + this.afiseazaOre(sdurata) + '</div>';
            if (sduratasuplimentara > 0) {
              schimb += '<div class="OSL">' + this.afiseazaOre(sduratasuplimentara) + '</div>';
            }
          }
         
          break;
        }
      }
    }
    return schimb;
  }

  getPunctLucruById(id){
    var punctlucru = '<div class="zigol"><i class="fa fa-map-marker"></i></div>';
    var pl = this.listaPuncteLucru
    for (var i=0; i<pl.length; i++){
      if (pl[i].s_idpunctlucru == id){
        punctlucru = '<div class="zipunctlucru">' + pl[i].s_punctlucru + '</div>';
      }
    }
    return punctlucru;
  }

  getOreSuplimentare(secpontaj) {
    var depasiri: string | any[] = '00:00'
    var secOreLucratoare = this.utils.getSeconds(this.oreLucratoare, 0)
    //console.log(secOreLucratoare)
    if (secpontaj > secOreLucratoare) {
      var secDepasiri = secpontaj - secOreLucratoare
      depasiri = this.utils.getHourMinFromSec(secDepasiri, true)
    }
    return secDepasiri
  }

  getCodZi(zi) {
    return this.utils.getDowSL(zi, this.anPontaj, this.indexLunaPontaj, this.zileSL)
  }

  getNumeZi(zi) {
    return this.utils.getZiSaptamana(zi, this.anPontaj, this.indexLunaPontaj)
  }


  addPontaj(idPersonal: string, anluna: number, idpontaj?: string) {
    if (!idpontaj) idpontaj = "";
    var anLunaPrec = this.utils.getAnlunaPrecedenta(anluna)
    //console.log(anLunaPrec)
    this.apiService.fdbAddPontaj(idPersonal, anluna, anLunaPrec, 0).subscribe(() => {
      this.afiseaza()
    });
  }

  addPontajCompartiment(anluna) {
    var anLunaPrec = this.utils.getAnlunaPrecedenta(anluna)
    if (this.visibleButton()) {
      this.apiService.fdbAddPontajCompartiment(this.idCompartiment, anluna, anLunaPrec, 0).subscribe(() => {
        this.afiseaza()
      });
    } else {
      this.log.error('Nu aveti dreptul de a modifica pontajul')
    }
  }
  stergPontaj(idPontaj) {
    if (this.intocmit.s_tipuser == 1 || this.intocmit.s_tipuser == 2 || this.intocmit.s_tipuser == 4) {
      if (this.visibleButton()) {
        this.apiService.fdbDeleteElementById('s_programarepersonal', 's_idpontaj', idPontaj, 1).subscribe(() => {
          this.afiseaza();
        })
      } else {
        this.log.error('Nu aveti dreptul de a modifica pontajul')
      }
    }else{
      this.log.error('Nu aveti voie sa modificati pontajul');
    }
  }

  checkOreLucrateComplet(idx) {
    var clasa = 'LV';
    var secOreLucratoare = this.utils.getSeconds(this.oreLucratoare, 0)
    var p = this.listaPersonalPontaj[idx];
    var orePersoana = 1 * p.secpontajlucrat + (1 * p.zco + 1 * p.zb + 1 * p.zcfs + 1 * p.zes + 1 * p.zn) * 8 * 3600;
    if (secOreLucratoare <= orePersoana) {
      clasa = 'LV'
    } else {
      clasa = 'RED'
    }

    return clasa;
  }

  setEchipeForPerioadaPontaj() {
    if (this.visibleButton()) {
      this.apiService.fdbSetEchipeForPerioadaPontaj(this.idCompartiment, this.anLunaPontaj).subscribe(() => {
        this.afiseaza();
      });
    } else {
      this.log.error('Nu aveti dreptul de a modifica pontajul')
    }
  }

  pontajUpdate(camp, valoareCamp, idPontaj, transformSec, descriere, numePersoana) {
    if (this.intocmit.s_tipuser == 1 || this.intocmit.s_tipuser == 2 || this.intocmit.s_tipuser == 4) {
      this.dialog.custom(PontajUpdate,
        {
          idPontaj: idPontaj,
          campUpdate: camp,
          valoareUpdate: valoareCamp,
          descriereUpdate: descriere,
          transformSec: true,
        }, { size: 'md', backdrop: false }).result.then(() => {
          this.afiseaza();
        })
    } else {
      this.log.error('Nu aveti dreptul sa modificati pontajul')
    }

  }

  exportExcel() {

    var fileName = this.titluPontaj + '-' + Date.now() + '.xlsx';
    /* table id is passed over here */
    let element = document.getElementById('tblPontaj');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName.replace(/\s+/g, '-').toLowerCase())

  }

  printPontaj() {
    this.isPrint = true;
    setTimeout(() => {
      var titlu = '<title>' + this.titluPontaj + '-' + Date.now() + '</title>'
      var prtContent = document.getElementById("div-pontaj");

      var WinPrint = window.open('', '', 'left=0,top=0,width=1200,height=900,toolbar=0,scrollbars=0,status=0');
      WinPrint.document.write(titlu.replace(/\s+/g, '-').toLowerCase());
      WinPrint.document.write('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css" rel="stylesheet" >');
      WinPrint.document.write('<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/js/bootstrap.min.js" integrity="sha384-Atwg2Pkwv9vp0ygtn1JAojH0nYbwNJLPhwyoVbhoPwBhjQPR5VtM2+xf0Uwh9KtT" crossorigin="anonymous"></script>');
      WinPrint.document.write('<link rel="stylesheet" href="./styles.css">');
      WinPrint.document.write('<link rel="stylesheet" href="./assets/pontajbw.css">');
      WinPrint.document.write('</head><body onload="print();close();">');
      WinPrint.document.write('</head>');
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.write('</body></html>');
      this.isPrint = false;
      WinPrint.document.close();
      WinPrint.focus();
    }, 1000);
  }

  printProgram() {
    this.isPrint = true;
    this.isPrintProgram = true;
    this.titluPontaj = 'Programare personal ' + this.numeCompartiment.trim() + ' ' + this.anPontaj + '_' + this.numarLunaPontaj

    setTimeout(() => {
      var titlu = '<title>' + this.titluPontaj + '-' + Date.now() + '</title>'
      var prtContent = document.getElementById("div-pontaj");

      var WinPrint = window.open('', '', 'left=0,top=0,width=1200,height=900,toolbar=0,scrollbars=0,status=0');
      WinPrint.document.write(titlu.replace(/\s+/g, '-').toLowerCase());
      WinPrint.document.write('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css" rel="stylesheet" >');
      WinPrint.document.write('<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/js/bootstrap.min.js" integrity="sha384-Atwg2Pkwv9vp0ygtn1JAojH0nYbwNJLPhwyoVbhoPwBhjQPR5VtM2+xf0Uwh9KtT" crossorigin="anonymous"></script>');
      WinPrint.document.write('<link rel="stylesheet" href="./styles.css">');
      WinPrint.document.write('<link rel="stylesheet" href="./assets/pontajbw.css">');
      WinPrint.document.write('</head><body onload="print();close();">');
      WinPrint.document.write('</head>');
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.write('</body></html>');
      this.isPrint = false;
      this.isPrintProgram = false;
      this.titluPontaj = 'Pontaj ' + this.numeCompartiment.trim() + ' ' + this.anPontaj + '_' + this.numarLunaPontaj

      WinPrint.document.close();
      WinPrint.focus();
    }, 1000);

  }

  visibleButton() {
    if (this.intocmit.s_tipuser == 1 || this.intocmit.s_tipuser == 2 || this.intocmit.s_tipuser == 4) {
      return true
    } else {
      return false
    }
  }


  allowDrop($event): void {
    $event.preventDefault();
  }

  private draggedIndex: number;
  onDragStart(index): void {
    this.draggedIndex = index;
  }

  onDrop($event, index): void {
    $event.preventDefault();
    const item = this.listaPersonalPontaj[this.draggedIndex];
    //console.log('item', item)
    this.listaPersonalPontaj.splice(this.draggedIndex, 1);
    this.listaPersonalPontaj.splice(index, 0, item);
    this.draggedIndex = -1;
    console.log(index)
    console.log(this.draggedIndex)
    this.apiService.fdbReordonare(index + 1, this.idCompartiment, this.anLunaPontaj, item.s_idpersonal).subscribe(() => {
      this.afiseaza();
    })

  }

  ordoneazaNume(camp) {
    this.campSort = camp;
    this.apiService.fdbReordonareCamp(this.campSort, this.idCompartiment, this.anLunaPontaj).subscribe(() => {
      this.afiseaza();
    })
  }

}




