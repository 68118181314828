import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { User } from '@app/_models/user';
import { UserService } from '@app/_services/user.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { DialogService } from '@app/_services';
import { ChangePassword } from '@app/login/change.password';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  loading = false;
  userLogat
  id
  //users

  constructor(private userService: UserService, 
    private auth: AuthenticationService, 
    private router: Router,
     private activatedRoute: ActivatedRoute,
     private dialog: DialogService) { }

  ngOnInit() {
    this.auth.getUserLogat.emit(this.auth.currentUserValue)
    // pune un id la dashboard daca vrei sa ajungi aici, ca sa nu redirecteze la curentUrl din localstorage
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    
    var userData = JSON.parse(localStorage.getItem('currentUser'))
    // console.log(userData)
    console.log('id', this.id)
    this.userLogat = this.auth.currentUserValue
    if (this.id) {
      console.log(this.id)
      //localStorage.setItem('curentUrl', '/')
    } else {
      var urlToRedirect = localStorage.getItem('curentUrl');
      // console.log(urlToRedirect)
      // console.log(urlToRedirect.includes(';'))
      
      // if (urlToRedirect) {
      //   if (urlToRedirect.includes(';') == false) {
      //     console.log('urlToRedirect',urlToRedirect)
      //     this.router.navigate([urlToRedirect])
      //   }else{
      //     var urlSimplu = 
      //     localStorage.setItem('curentUrl', './login')
      //     this.router.navigate(['./personal']);
      //   }
      // }

      if (urlToRedirect) {
          console.log('urlToRedirect',urlToRedirect)
          this.router.navigate([urlToRedirect])
         }
    }


  }

  logout() {
    this.auth.logout();
    this.router.navigate(['./login'])
  }

  changePassword() {
    this.dialog.custom(ChangePassword, {}, { size: 'md', backdrop: false },).result.then(() => {
      this.router.navigate(['./login']);
    })
}
}


