<div class="modal-header bg-info text-white">
    <h4 class="modal-title">Modifica</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="container form-horizontal" #f="ngForm">
      <fieldset>
        <div class="form-row col-12" *ngIf="!transformSec">
          <div class="col-12">
            <label class="col-form-label">{{descriereUpdate}}</label>
              <input type="number" class="form-control form-control-sm col" name="valoareUpdateH" [(ngModel)]="valoareUpdate">
          </div>
        </div>
        <div class="form-row col-12" *ngIf="transformSec">
          <div class="col-6">
            <label class="col-form-label">Durata (HH:MM)</label>
            <div class="input-group input-group-sm">
              <input type="number" class="form-control form-control-sm col" name="durataHH" [(ngModel)]="durataHH" min="0"
                max="12" >
              <div class="input-group-append">
                <span class="input-group-text" id="inputGroup-sizing-sm">:</span>
              </div>
              <input type="number" class="form-control form-control-sm col" name="durataMM" [(ngModel)]="durataMM" min="0"
                max="59" >
            </div>
          </div>
         </div>
      </fieldset>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="save()">Salveaza</button>
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Anuleaza</button>
  </div>
  