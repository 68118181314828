import { AuthenticationService } from '@app/_services/authentication.service';
import { DialogService } from '@app/_services/dialog.service';
import { ApiService } from '@app/_services/api.service';
import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AgGrid, AgGridColumnSize, ColDef, Renderers, RowNode } from '@app/controls';
import { UtilizatorEdit } from './utilizator.edit';


@Component({
  selector: 'app-utilizatori',
  templateUrl: './utilizatori.component.html',
  styleUrls: ['./utilizatori.component.css']
})
export class UtilizatoriComponent implements OnInit {

  constructor(private apiService: ApiService,
    private dialog: DialogService, private activatedRoute: ActivatedRoute, private router: Router,
    private log: ToastrService,
    private auth: AuthenticationService) { }

  gridUtilizatori: AgGrid;
  gridCompartiment: AgGrid;
  searchText: string = "";
  idUtilizator: string = "";
  tipUser: number = 0;
  listaUtilizatori
  listaCompartimente
  numeUtilizator: string;


  ngOnInit(): void {
    localStorage.setItem('curentUrl', this.router.url)
    this.auth.getUserLogat.emit(this.auth.currentUserValue)
    this.setupGridUtilizatori();
    this.getListaUtilizatori();
    this.setupGridCompartiment();
  }

  getListaUtilizatori() {
    this.apiService.fdbGetUtilizatori("", this.searchText).subscribe((d) => {
      this.listaUtilizatori = d;
      this.gridUtilizatori.setDataSource(this.listaUtilizatori).then(() => {
        this.selectNode();
      })
    })
  }

  selectNode() {
    this.gridUtilizatori.gridOptions.api.forEachNode(node => {
      if (node.data.s_idpersonal == this.idUtilizator) {
        node.setSelected(true);
      }
    });
  }

  setupGridUtilizatori() {
    var colDefs: ColDef[] = [
      { headerName: 'Nume', field: 's_numefull' },
      { headerName: 'Email', field: 's_email' },
      { headerName: 'Functie', field: 's_functie' },
      { headerName: 'Compartiment', field: 's_compartiment' },
    ];
    this.gridUtilizatori = new AgGrid(colDefs, null, null);
    this.gridUtilizatori.onDataLoaded = () => {
      //this.gridDetaliu.gridOptions.api.sizeColumnsToFit();
      var allColumnIds = [];
      this.gridUtilizatori.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.getColId());
      });

      this.gridUtilizatori.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);

    }

    this.gridUtilizatori.onAddKey = () => { }
    this.gridUtilizatori.onEditKey = () => { }
    this.gridUtilizatori.onDeleteKey = () => { }
    this.gridUtilizatori.onRowDoubleClick = () => {
      // this.idPersonal = this.gridUtilizatori.selectedRow.s_idpersonal
      // this.edit(this.idPersonal);
    }
    this.gridUtilizatori.onRowSelected = () => {
      this.tipUser = this.gridUtilizatori.selectedRow.s_tipuser
      this.idUtilizator = this.gridUtilizatori.selectedRow.s_idpersonal
      this.numeUtilizator = this.gridUtilizatori.selectedRow.s_numefull
      this.getListaCompartimente();
    }

    this.gridUtilizatori.gridOptions.getRowClass = (node) => {
      if (node.data.s_inactiv == 1) { return 'text-warning' }
    }
  }

  getListaCompartimente() {
    this.apiService.fdbGetUtilizatorCompartiment(this.idUtilizator, this.tipUser, this.searchText).subscribe((d) => {
      this.listaCompartimente = d;
      this.gridCompartiment.setDataSource(this.listaCompartimente)
    })
  }

  setupGridCompartiment() {
    var colDefs: ColDef[] = [
      { headerName: 'Compartiment', field: 's_compartiment' },
    ];
    this.gridCompartiment = new AgGrid(colDefs, null, null);
    this.gridCompartiment.onDataLoaded = () => {
      //this.gridDetaliu.gridOptions.api.sizeColumnsToFit();
      var allColumnIds = [];
      this.gridCompartiment.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.getColId());
      });

      this.gridCompartiment.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);

    }

    this.gridCompartiment.onAddKey = () => { }
    this.gridCompartiment.onEditKey = () => { }
    this.gridCompartiment.onDeleteKey = () => { }
    this.gridCompartiment.onRowDoubleClick = () => {
      // this.idPersonal = this.gridUtilizatori.selectedRow.s_idpersonal
      // this.edit(this.idPersonal);
    }
    this.gridCompartiment.onRowSelected = () => {
      // this.isInactiv = this.gridUtilizatori.selectedRow.s_inactiv
      // this.idPersonal = this.gridUtilizatori.selectedRow.s_idpersonal
    }

    this.gridCompartiment.gridOptions.getRowClass = (node) => {
      if (node.data.s_inactiv == 1) { return 'text-warning' }
    }
  }

  addUtilizator() {
    this.dialog.custom(UtilizatorEdit,
      {
        idUtilizator: 0,
      }, { size: 'lg' }).result.then(() => {
        this.getListaUtilizatori();
      })
  }

  editUtilizator() {
    this.dialog.custom(UtilizatorEdit,
      {
        idUtilizator: this.idUtilizator,
      }, { size: 'lg' }).result.then(() => {
        this.getListaUtilizatori();
      })
  }

  stergCompartiment() {
    var idCompartiment = this.gridCompartiment.selectedRow.s_idcompartiment
    var msg = 'Utilizatorul ' + this.gridUtilizatori.selectedRow.s_numefull + '\n nu va mai efectua pontaj la compartimentul\n' + this.gridCompartiment.selectedRow.s_compartiment;
    var where = " s_idpersonal='" + this.idUtilizator + "' AND s_idcompartiment='" + idCompartiment + "' ";
    console.log(where)
    this.dialog.confirm({ message: msg, confirmBtn: 'Sterge', cancelBtn: 'Anulare' }).then(() => {
      this.apiService.fdbDeleteElementByWhere('s_utilizatorcompartiment', where).subscribe(() => {
        this.getListaCompartimente();
      });
    })
  }

  stergUtilizator() {
    var countc: number = 0
    if (this.gridUtilizatori.selectedRow.s_tipuser != 1) {
      countc = this.gridCompartiment.rowCount
    }
    console.log(countc)
    var msg = 'Doriti sa stergeii utilizatorul\n' + this.gridUtilizatori.selectedRow.s_numefull;
    if (countc == 0) {
      this.dialog.confirm({ message: msg, confirmBtn: 'Sterge', cancelBtn: 'Anulare' }).then(() => {
        this.apiService.fdbStergUtilizator(this.idUtilizator).subscribe(() => {
          this.getListaUtilizatori();
        });
      })
    } else {

      this.log.warning('Nu se poate sterge utilizatorul deoarece are compartimente repartizate')
    }

  }

  disableButtonUtilizatori() {
    if (this.gridUtilizatori.selectedRow.s_idpersonal) {
      return false;
    } else {
      return true;
    }
  }

  disableButton(but) {
    switch (but) {
      case 'stergCompartiment': if (!this.gridCompartiment.selectedRow.s_idcompartiment || !this.idUtilizator || this.gridUtilizatori.selectedRow.s_tipuser == 1) return true; break;
      case 'editUtilizator': if (!this.gridUtilizatori.selectedRow.s_idpersonal || this.gridUtilizatori.selectedRow.s_tipuser == 1) return true; break;
      case 'stergUtilizator': if (!this.gridUtilizatori.selectedRow.s_idpersonal) return true; break;
    }
  }

}
