<div class="modal-header bg-info text-white">
    <h4 class="modal-title">Selecteaza personal in programare pontaj</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="container form-horizontal" #f="ngForm">
      <fieldset>
        <div class="col-12">
          <ag-grid-angular style="height: 50vh;" class="ag-theme-alpine" [gridOptions]="grid.gridOptions">
          </ag-grid-angular>
        </div>
      </fieldset>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="isDisabled()" (click)="save()">Salveaza</button>
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Anuleaza</button>
  </div>