import { UtilsService } from './../_services/utils.service';
import { Component, Input, OnInit, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Schimb, ListaCompartimente } from '@app/_models/personal';
import { ApiService } from '@app/_services/api.service';
import { ToastrService } from 'ngx-toastr';
import { AgGrid } from './../controls/agGrid/agGrid';
import { ColDef } from '@app/controls';



@Component({
    selector: 'pontaj-add-personal',
    templateUrl: './pontaj.add.personal.html',
})

export class PontajAddPersonal implements OnInit {
    constructor(public activeModal: NgbActiveModal, private apiService: ApiService, private utils: UtilsService, private log: ToastrService) { }

    @Input() anLuna;
    @Input() idCompartiment
    grid: AgGrid;
    persoaneForPontaj;
    anLunaPrecedenta: number;


    ngOnInit() {
        this.anLunaPrecedenta = this.utils.getAnlunaPrecedenta(this.anLuna)

        this.getPersoaneForForPontaj();
        this.setupGrid()

    }

    setupGrid() {
        var colDefs: ColDef[] = [
            { headerName: 'Nume', field: 's_numefull', checkboxSelection: true, headerCheckboxSelection: true, },
            { headerName: 'Echipa', field: 's_echipa' },
            { headerName: 'Functie', field: 's_functie' },
        ];
        this.grid = new AgGrid(colDefs, false, true);
        this.grid.onDataLoaded = () => {
            //this.gridDetaliu.gridOptions.api.sizeColumnsToFit();
            var allColumnIds = [];
            this.grid.gridOptions.columnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.getColId());
            });

            this.grid.gridOptions.api.getRenderedNodes().forEach(function (node: any) {
                if (node.data.s_idechipa) {
                    node.setSelected(true);
                }
            });

            this.grid.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);
        }
        this.grid.onRowSelected = () => {
        }
    }

    getPersoaneForForPontaj() {
        this.apiService.fdbGetPersoaneForPontaj(this.idCompartiment, this.anLuna).then((d) => {
            this.persoaneForPontaj = d;
            this.grid.setDataSource(this.persoaneForPontaj)
        })

    }

    isDisabled(){
        return !this.grid.getSelectedRows().length;
    }

    save() {
        var msgErr = ""
        var listaIds = "";
        const ids = this.grid.getSelectedRows().map(r => r.s_idpersonal);
        console.log(ids);
        for (var i = 0; i < ids.length; i++) {
            listaIds +=  ids[i] + ",";
        }
        listaIds = listaIds.slice(0, -1);
        this.apiService.fdbSetPersoaneForPontaj(this.idCompartiment, this.anLuna, listaIds).subscribe(() => {
            this.log.success('Persoanele au fost adaugate in programare pontaj');
            this.activeModal.close();
        })
    }
    anuleaza() {

    }

}
