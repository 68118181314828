import { Component, OnInit } from '@angular/core';
import { UtilsService } from '@app/_services/utils.service';

@Component({
  selector: 'app-setari',
  templateUrl: './setari.component.html',
  styleUrls: ['./setari.component.css']
})
export class SetariComponent implements OnInit {

  constructor(private utils: UtilsService) { }

  an

  ngOnInit(): void {
    var al = this.utils.getAlExtendedFromAnLuna(this.utils.anLunaPontaj)
    this.an = al.an;

  }

}
