import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@app/_services/api.service';
import { Router } from '@angular/router';



@Component({
    selector: 'forgot-password',
    templateUrl: './forgot.password.html',
})

export class ForgotPassword implements OnInit {
    constructor(public activeModal: NgbActiveModal, 
        private apiService: ApiService, 
        private log: ToastrService,  
        private router: Router,) { }

    email: string;
    isSuccess: boolean = false;
    
    ngOnInit() {

        var generatePassword = (
            length = 12,
            wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
          ) =>
            Array.from(crypto.getRandomValues(new Uint32Array(length)))
              .map((x) => wishlist[x % wishlist.length])
              .join('')
          
          console.log(generatePassword())
       
    }
    isDisabled(){
        return !this.email
    }
    save() {
    
        // stop here if form is invalid
       
    
        this.apiService.fdbRecuperareParola(this.email).subscribe((d)=>{
          console.log('d', d)
          if (d === 'Success'){
            this.router.navigate(['./login']);
            this.isSuccess = true;
            this.activeModal.close();
          }else{
            this.log.error('A survenit o eroare. Nu s-a putut reseta parola');
          }
        })
      }

    





}
