import { UtilsService } from './../_services/utils.service';
import { Component, Input, OnInit, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Schimb, ListaCompartimente } from '@app/_models/personal';
import { ApiService } from '@app/_services/api.service';
import { ToastrService } from 'ngx-toastr';
import { AppConfiguration } from '@app/_services/app-config.service';


@Component({
    selector: 'pontaj-set',
    templateUrl: './pontaj.set.html',
})

export class PontajSet implements OnInit {
    constructor(public activeModal: NgbActiveModal,
        private apiService: ApiService, private utils: UtilsService, private log: ToastrService,
        private appConfig: AppConfiguration) { }

    @Input() idschimb;
    @Input() zi;
    @Input() idCompartiment
    @Input() tipzi: string
    @Input() anLuna: number;
    @Input() idpontaj: string;
    @Input() idechipa: string;
    @Input() numefull: string;
    dataPontaj: string;
    schimb: Schimb;
    listaOptiuniPontaj: any;
    oraStartHH: number = 0
    oraStartMM: number = 0
    durataHH: string = '0'
    durataMM: string = '0'
    oraSfarsitHH: number = 0
    oraSfarsitMM: number = 0

    duratasec: number = 0;

    duratasecnoapte: number = 0;
    oreNoapteHH: number = 0;
    oreNoapteMM: number = 0

    durataSuplimentara: number = 0;
    durataSuplMM: string;
    durataSuplHH: string;

    ziSapt: number;
    toataSaptamana: boolean = false;
    toataEchipa: boolean = false;
    isNou: boolean = true;

    isschimb: string
    iseditabil: number = 0;
    isorenoapte: number = 0;
    ziSalar: number;

    ngOnInit() {
        this.ziSalar = this.appConfig.ziSalar;
        console.log(this.idschimb);
        if (this.idschimb) this.isNou = false;
        this.apiService.fdbGeSchimbForPontaj(this.idCompartiment, this.tipzi, 'activ').subscribe((d) => {
            this.listaOptiuniPontaj = d;
            console.log(this.listaOptiuniPontaj)
            if (!this.isNou) {
                this.apiService.fdbGetPontajZi(this.idpontaj, this.zi).then((d) => {
                    console.log(d)
                    console.log(d[0]['s_z' + this.zi + '_sduratasuplimentara'])

                    this.duratasec = d[0]['s_z' + this.zi + '_sdurata'];
                    var a = this.utils.getHourMinFromSec(this.duratasec)
                    this.durataHH = a[0];
                    this.durataMM = a[1];

                    this.durataSuplimentara = d[0]['s_z' + this.zi + '_sduratasuplimentara'];
                    var durataSupl = this.utils.getHourMinFromSec(this.durataSuplimentara);
                    this.durataSuplHH = durataSupl[0];
                    this.durataSuplMM = durataSupl[1];

                    this.duratasecnoapte = d[0]['s_z' + this.zi + '_sduratanoapte'];
                    var n = this.utils.getHourMinFromSec(this.duratasecnoapte);
                    this.oreNoapteHH = n[0];
                    this.oreNoapteMM = n[1];

                    this.isschimb = d[0]['s_z' + this.zi + '_sch'];
                    console.log(this.isschimb);
                    for (var i = 0; i < this.listaOptiuniPontaj.length; i++) {
                        if (this.listaOptiuniPontaj[i].s_idschimb == this.idschimb) {
                            this.iseditabil = this.listaOptiuniPontaj[i].s_flag_editabil;
                            this.isorenoapte = this.listaOptiuniPontaj[i].s_flag_orenoapte;
                        }
                    }
                    console.log(this.iseditabil);
                })
            } else {
                var durataSupl = this.utils.getHourMinFromSec(this.durataSuplimentara);
                this.durataSuplHH = durataSupl[0];
                this.durataSuplMM = durataSupl[1];
                var n = this.utils.getHourMinFromSec(this.duratasecnoapte);
                this.oreNoapteHH = n[0];
                this.oreNoapteMM = n[1];
                var a = this.utils.getHourMinFromSec(this.duratasec)
                this.durataHH = a[0];
                this.durataMM = a[1];

            }


            //this.setDurata();
        });

        var al = this.anLuna.toString();
        this.dataPontaj = this.zi + "." + al.substring(4, 6) + "." + al.substring(0, 4);
        var dp = new Date(+al.substring(0, 4), +al.substring(4, 6) - 1, this.zi)
        this.ziSapt = dp.getDay();
    }

    canSave() {
        var l = this.utils.getAnLunaExtended;
        console.log(l);
        var lprec = l[1] - 1;
        var saveDisabled: boolean = true;
        var al = this.anLuna.toString();
        var dp = new Date(+al.substring(0, 4), +al.substring(4, 6) - 1, this.zi)
        var d = new Date();
        if (d.getDate() > this.ziSalar && dp.getMonth() == (+al.substring(4, 6) - 1)) {
            saveDisabled = false;
        }
        console.log(saveDisabled);
        return saveDisabled
    }

    setDurata() {
        console.log(this.idschimb)
        for (var i = 0; i < this.listaOptiuniPontaj.length; i++) {
            if (this.listaOptiuniPontaj[i].s_idschimb == this.idschimb) {
                var a = this.utils.getHourMinFromSec(this.listaOptiuniPontaj[i].s_duratasec)
                this.durataHH = a[0];
                this.durataMM = a[1];
                this.duratasec = this.listaOptiuniPontaj[i].s_duratasec;

                var n = this.utils.getHourMinFromSec(this.listaOptiuniPontaj[i].s_duratasecnoapte);
                this.oreNoapteHH = n[0];
                this.oreNoapteMM = n[1];
                this.duratasecnoapte = this.listaOptiuniPontaj[i].s_duratasecnoapte;

                this.isschimb = this.listaOptiuniPontaj[i].s_isschimb
                this.iseditabil = this.listaOptiuniPontaj[i].s_flag_editabil
                this.isorenoapte = this.listaOptiuniPontaj[i].s_flag_orenoapte

                console.log(this.isschimb);
            }

            if (this.isschimb == 'A') {
                this.durataSuplimentara = 0;
                var durataSupl = this.utils.getHourMinFromSec(this.durataSuplimentara);
                this.durataSuplHH = durataSupl[0];
                this.durataSuplMM = durataSupl[1];

            }

        }
    }


    save() {
        if (this.isschimb == 'A' && this.idschimb == 'RV') {
            this.duratasecnoapte = 0;
            console.log(this.oreNoapteHH, this.oreNoapteMM)
            this.duratasecnoapte = this.utils.getSeconds(this.oreNoapteHH, this.oreNoapteMM)
        }
        if (this.iseditabil) {
            this.duratasec = this.utils.getSeconds(this.durataHH, this.durataMM)
            if (this.duratasec > 42300) {
                this.log.warning('Durata nu poate fi mai mare de 12 ore');
                //return
            }
        }
        this.durataSuplimentara = this.utils.getSeconds(this.durataSuplHH, this.durataSuplMM)
        if (this.toataSaptamana) {
            this.apiService.fdbSaveSapt(this.zi, this.idschimb, this.isschimb, this.duratasec, this.duratasecnoapte, this.durataSuplimentara, this.idpontaj, (this.toataEchipa ? this.idechipa : ""), this.anLuna).subscribe(() => {
                this.log.success('S-a salvat toata saptamana');
                this.activeModal.close()
            });
        } else {
            this.apiService.fdbSaveZi(this.zi, this.idschimb, this.isschimb, this.duratasec, this.duratasecnoapte, this.durataSuplimentara, this.idpontaj, (this.toataEchipa ? this.idechipa : ""), this.anLuna).subscribe(() => {
                this.log.success('S-a salvat');
                this.activeModal.close()
            });
        }
    }

    sterge() {
        if (this.toataSaptamana) {
            this.apiService.fdbStergSaptamana(this.zi, this.idschimb, this.isschimb, this.duratasec, this.duratasecnoapte, this.idpontaj).subscribe(() => {
                this.log.warning('Sters toata saptamana');
                this.activeModal.close()
            });
        } else {
            this.apiService.fdbStergZiua(this.zi, this.idschimb, this.isschimb, this.duratasec, this.duratasecnoapte, this.idpontaj).subscribe(() => {
                this.log.warning('Sters ziua');
                this.activeModal.close()
            });
        }
    }

    oreNoapteDisable(idschimb) {
        var isDisabled = true;
        if (idschimb == 'RV') isDisabled = false;
        return isDisabled;
    }

}
