<div class="col-12">
  <h4>Schimburi {{numeCompartiment}}</h4>
  <div class="alert alert-primary col-12">
    <form #f="ngForm">
      <div class="col-12">
        <div class="form-group row">
          <div class="col-6 row">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-search"></i></div>
              </div>

              <input type="text" class="form-control form-control-sm col-4" name="cauta" [(ngModel)]="searchText"
                (ngModelChange)="getListaSchimburi()">
            </div>
          </div>
          <div class="col-6 text-right">
            <button class="btn btn-sm btn-success ml-1" (click)="edit(0)"><i
                class="fa fa-plus-circle"></i>&nbsp;Adauga</button>
            <button class="btn btn-sm btn-primary ml-1" (click)="edit(idSchimb)" [disabled]="isDisabled()"><i
                class="fa fa-edit"></i>&nbsp;Modifica</button>
            <button class="btn btn-sm btn-warning ml-1" (click)="setStatus(idSchimb)" [disabled]="isDisabled()"><i
                class="{{isInactiv ? 'fa fa-lock-open' : 'fa fa-lock'}}"></i>&nbsp;Status</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-12" *ngIf="idCompartiment">
    <navigaretab></navigaretab>
  </div>
  <!-- <div>
      <table class="table table-sm table-striped table-hover table-bordered">
        <thead class="thead-dark">
          <tr>
            <th>Nr.</th>
            <th>Cod schimb</th>
            <th>Ora start</th>
            <th>Durata</th>
            <th>Ore noapte</th>
            <th>Compartiment</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let p of listaSchimburi; index as i">
            <td class="text-right">{{ i+1 }}</td>
            <td>{{ p.s_codprg }}</td>
            <td>{{ getTimp(p.s_orastartsec) }}</td>
            <td>{{ getTimp(p.s_duratasec) }}</td>
            <td>{{ getTimp(p.s_duratasecnoapte) }}</td>
            <td>{{ p.s_compartiment }}</td>
            <td class="text-center">
              <button class="btn btn-sm btn-success" (click)="edit(p.s_idschimb)"><i
                  class="fa fa-edit"></i></button>
          </tr>
        </tbody>
      </table>
    </div> -->
  <ag-grid-angular style="height: 70vh;" class="ag-theme-alpine" [gridOptions]="grid.gridOptions">
  </ag-grid-angular>
