import { TypeaheadConfigService } from './controls/typeahead/typeahead.config.service';
import { EchipaEdit } from './echipa/echipa.edit';
import { TypeaheadModule } from './controls/typeahead/typeahead.module';
import { DialogService } from '@app/_services/dialog.service';
import { PersonalEdit } from './personal/personal.edit';
import { CompartimentEdit } from './compartiment/compartiment.edit';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common'


import { appRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { HeaderComponent } from './header/header.component';
import { CompartimentComponent } from './compartiment/compartiment.component';
import { PersonalComponent } from './personal/personal.component';
import { ConfirmDialog } from './controls/modal/dialog.confirm';
import { NavigareTab } from './controls/navigaretab/navigaretab';

import { SchimburiComponent } from './schimburi/schimburi.component';
import { SchimbEdit } from './schimburi/schimb.edit';
import { PontajComponent } from './pontaj/pontaj.component';
import { PontajSet } from './pontaj/pontaj.set';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GridModule } from './controls/agGrid.module';
import { UtilizatoriComponent } from './utilizatori/utilizatori.component';
import { UtilizatorEdit } from './utilizatori/utilizator.edit';
import { EchipaComponent } from './echipa/echipa.component';
import { SetAnLunaPontaj } from './header/setAnLunaPontaj';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SetNotaZi } from './pontaj/setNotaZi';
import { PersonalStatus } from './personal/personal.status';
import { PontajAddPersonal } from './pontaj/pontaj.add.personal';
import { ForgotPassword } from './login/forgot.password';
import { ChangePassword } from './login/change.password';
import { PontajUpdate } from './pontaj/pontaj.update';
import { AppConfiguration } from './_services/app-config.service';
import { PersonalZileConcediu } from './personal/personal.ZileConcediu';
import { SetariComponent } from './setari/setari.component';
import { SarbatoriLegaleComponent } from './setari/sarbatori-legale/sarbatori-legale.component';
import { ConcediiComponent } from './concedii/concedii.component';
import { PunctLucruComponent } from './punct-lucru/punct-lucru.component';
import { PunctlucruEdit } from './punct-lucru/punct-lucru.edit';
import { PontajPunctLucru } from './pontaj/pontaj.punct.lucru';



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent, ForgotPassword, ChangePassword,
    HeaderComponent,
    CompartimentComponent, CompartimentEdit, PersonalComponent, PersonalEdit, PersonalZileConcediu, ConfirmDialog, NavigareTab,PersonalStatus,
    SchimburiComponent, SchimbEdit, PontajComponent, PontajSet, PontajAddPersonal,
    UtilizatoriComponent, UtilizatorEdit, EchipaComponent, EchipaEdit,
    SetAnLunaPontaj, SetNotaZi, PontajUpdate, SetariComponent, SarbatoriLegaleComponent, ConcediiComponent, PunctLucruComponent, PunctlucruEdit, PontajPunctLucru

  ],
  imports: [
    BrowserModule,
    appRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    BrowserAnimationsModule,
    GridModule,
    TypeaheadModule,
  ],
  providers: [
    AppConfiguration,
    {
      provide: APP_INITIALIZER,
      useFactory: AppConfigurationFactory,
      deps: [AppConfiguration, HttpClient], multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DialogService,
    TypeaheadConfigService,
    // provider used to create fake backend
    //fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function AppConfigurationFactory(
  appConfig: AppConfiguration) {
  return () => appConfig.ensureInit();
}
