<div class="modal-header bg-info text-white">
    <h4 class="modal-title">Echipa</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="container form-horizontal" #f="ngForm">
      <fieldset>
        <div class="form-row col-12">
          <div class="col-8">
            <label class="col-form-label">Echipa</label>
            <input type="text" class="form-control form-control-sm" required [(ngModel)]="echipa.s_echipa"
            name="s_echipa" />
          </div>
        </div>
        <div class="col-12">
          <h5>Selecteaza persoane pentru echipa</h5>
          <ag-grid-angular style="height: 30vh;" class="ag-theme-alpine" [gridOptions]="grid.gridOptions">
          </ag-grid-angular>
        </div>
      </fieldset>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="save()" [disabled]="isDisabled()">Salveaza</button>
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Anuleaza</button>
  </div>