import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '@app/_services/authentication.service';
import { UtilsService } from './../_services/utils.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, DialogService } from '@app/_services';
import * as XLSX from 'xlsx';
import { PersonalPontaj } from '@app/_models/pontaj';
import * as dayjs from 'dayjs';
import { createFalse, parseIsolatedEntityName } from 'typescript';


@Component({
  selector: 'app-concedii',
  templateUrl: './concedii.component.html',
  styleUrls: ['./concedii.component.css']
})
export class ConcediiComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private dialog: DialogService, private activatedRoute: ActivatedRoute,
    private log: ToastrService, private auth: AuthenticationService,
    private utils: UtilsService, private router: Router
  ) { }

  listaPersonalConcediu
  campSort: string = 's_echipa';
  ordineSort: string = 'ASC'
  inactiv: number = 0;
  searchText: string = '';
  idCompartiment;
  anPontaj: number;
  numeCompartiment: string;
  anLunaPontaj
  anlunaPontajString
  isExport: boolean = false
  isPrint: boolean = false;
  titluTabel: string;
  intocmit
  dataIntocmit

  total = {
    ian: 0, feb: 0, mar: 0, apr: 0, mai: 0, iun: 0,
    iul: 0, aug: 0, sep: 0, oct: 0,
    nov: 0, dec: 0
  }


  ngOnInit(): void {
    localStorage.setItem('curentUrl', this.router.url)
    this.auth.getUserLogat.emit(this.auth.currentUserValue)

    this.idCompartiment = this.activatedRoute.snapshot.paramMap.get('idCompartiment') || "";

    this.intocmit = this.auth.currentUserValue
    this.dataIntocmit = dayjs(new Date()).format('DD/MM/YYYY')

    this.anLunaPontaj = this.utils.anLunaPontaj
    var alext = this.utils.getAlExtendedFromAnLuna(this.anLunaPontaj)
    this.anlunaPontajString = alext.numeLuna + ' ' + alext.an;
    this.anPontaj = Math.floor(this.anLunaPontaj / 100);

    this.apiService.fdbGetCompartimentById(this.idCompartiment).subscribe((d) => {
      this.numeCompartiment = d[0].s_compartiment;
      this.titluTabel = this.numeCompartiment.trim() + ' - ' + "Situatia concediilor pe anul " + this.anPontaj;
    });

    this.afiseazaConcedii();
  }



  afiseazaConcedii() {
    this.apiService.fdbGetPersonalConcediu(
      this.idCompartiment, this.campSort, this.ordineSort, this.inactiv,
      this.auth.currentUserValue.s_idpersonal, this.auth.currentUserValue.s_tipuser, this.searchText
    ).subscribe((d) => {
      this.listaPersonalConcediu = d;
      console.log(this.listaPersonalConcediu)
      this.calcZcEfectuat()
      this.calcTotal();

    })

  }

  calcZcEfectuat() {
    var pc = this.listaPersonalConcediu;
    var nrluna = (this.anLunaPontaj - Math.floor(this.anLunaPontaj / 100) * 100)
    for (var i = 0; i < pc.length; i++) {
      pc[i].s_zcprogramat =
        1 * pc[i].co_ian
        + 1 * pc[i].co_feb
        + 1 * pc[i].co_mar
        + 1 * pc[i].co_apr
        + 1 * pc[i].co_mai
        + 1 * pc[i].co_iun
        + 1 * pc[i].co_iul
        + 1 * pc[i].co_aug
        + 1 * pc[i].co_sep
        + 1 * pc[i].co_oct
        + 1 * pc[i].co_nov
        + 1 * pc[i].co_dec

       
        if (nrluna == 1) pc[i].s_zcefectuat = 1 * pc[i].co_ian
        if (nrluna == 2) pc[i].s_zcefectuat = 1 * pc[i].co_ian + 1 * pc[i].co_feb
        if (nrluna == 3) pc[i].s_zcefectuat = 1 * pc[i].co_ian + 1 * pc[i].co_feb + 1 * pc[i].co_mar
        if (nrluna == 4) pc[i].s_zcefectuat = 1 * pc[i].co_ian + 1 * pc[i].co_feb + 1 * pc[i].co_mar+ 1 * pc[i].co_apr
        if (nrluna == 5) pc[i].s_zcefectuat = 1 * pc[i].co_ian + 1 * pc[i].co_feb + 1 * pc[i].co_mar+ 1 * pc[i].co_apr+ 1 * pc[i].co_mai
        if (nrluna == 6) pc[i].s_zcefectuat = 1 * pc[i].co_ian + 1 * pc[i].co_feb + 1 * pc[i].co_mar+ 1 * pc[i].co_apr+ 1 * pc[i].co_mai+ 1 * pc[i].co_iun
        if (nrluna == 7) pc[i].s_zcefectuat = 1 * pc[i].co_ian + 1 * pc[i].co_feb + 1 * pc[i].co_mar+ 1 * pc[i].co_apr+ 1 * pc[i].co_mai+ 1 * pc[i].co_iun + 1 * pc[i].co_iul
        if (nrluna == 8) pc[i].s_zcefectuat = 1 * pc[i].co_ian + 1 * pc[i].co_feb + 1 * pc[i].co_mar+ 1 * pc[i].co_apr+ 1 * pc[i].co_mai+ 1 * pc[i].co_iun + 1 * pc[i].co_iul + 1 * pc[i].co_aug
        if (nrluna == 9) pc[i].s_zcefectuat = 1 * pc[i].co_ian + 1 * pc[i].co_feb + 1 * pc[i].co_mar+ 1 * pc[i].co_apr+ 1 * pc[i].co_mai+ 1 * pc[i].co_iun + 1 * pc[i].co_iul + 1 * pc[i].co_aug + 1 * pc[i].co_sep
        if (nrluna == 10) pc[i].s_zcefectuat = 1 * pc[i].co_ian + 1 * pc[i].co_feb + 1 * pc[i].co_mar+ 1 * pc[i].co_apr+ 1 * pc[i].co_mai+ 1 * pc[i].co_iun + 1 * pc[i].co_iul + 1 * pc[i].co_aug + 1 * pc[i].co_sep+ 1 * pc[i].co_oct
        if (nrluna == 11) pc[i].s_zcefectuat = 1 * pc[i].co_ian + 1 * pc[i].co_feb + 1 * pc[i].co_mar+ 1 * pc[i].co_apr+ 1 * pc[i].co_mai+ 1 * pc[i].co_iun + 1 * pc[i].co_iul + 1 * pc[i].co_aug + 1 * pc[i].co_sep+ 1 * pc[i].co_oct+ 1 * pc[i].co_nov
        if (nrluna == 12) pc[i].s_zcefectuat = 1 * pc[i].co_ian + 1 * pc[i].co_feb + 1 * pc[i].co_mar+ 1 * pc[i].co_apr+ 1 * pc[i].co_mai+ 1 * pc[i].co_iun + 1 * pc[i].co_iul + 1 * pc[i].co_aug + 1 * pc[i].co_sep+ 1 * pc[i].co_oct+ 1 * pc[i].co_nov+ 1 * pc[i].co_dec


        pc[i].s_zcramas = pc[i].s_zc - pc[i].s_zcefectuat;

    }
  }

  calcTotal() {
    this.total.ian = 0;
    this.total.feb = 0;
    this.total.mar = 0;
    this.total.apr = 0;
    this.total.mai = 0;
    this.total.iun = 0;
    this.total.iul = 0;
    this.total.aug = 0;
    this.total.sep = 0;
    this.total.oct = 0;
    this.total.nov = 0;
    this.total.dec = 0;
    for (var i = 0; i < this.listaPersonalConcediu.length; i++) {
      this.total.ian += 1 * this.listaPersonalConcediu[i].co_ian
      this.total.feb += 1 * this.listaPersonalConcediu[i].co_feb
      this.total.mar += 1 * this.listaPersonalConcediu[i].co_mar
      this.total.apr += 1 * this.listaPersonalConcediu[i].co_apr
      this.total.mai += 1 * this.listaPersonalConcediu[i].co_mai
      this.total.iun += 1 * this.listaPersonalConcediu[i].co_iun
      this.total.iul += 1 * this.listaPersonalConcediu[i].co_iul
      this.total.aug += 1 * this.listaPersonalConcediu[i].co_aug
      this.total.sep += 1 * this.listaPersonalConcediu[i].co_sep
      this.total.oct += 1 * this.listaPersonalConcediu[i].co_oct
      this.total.nov += 1 * this.listaPersonalConcediu[i].co_nov
      this.total.dec += 1 * this.listaPersonalConcediu[i].co_dec
    }

  }

  ordoneazaNume(camp) {
    this.campSort = camp;
    this.apiService.fdbReordonareCamp(this.campSort, this.idCompartiment, this.anLunaPontaj).subscribe(() => {
      this.afiseazaConcedii();
    })
  }

  printConcedii() {
    this.isPrint = true;
    setTimeout(() => {
      var titlu = '<title>' + this.titluTabel + '-' + Date.now() + '</title>'
      var prtContent = document.getElementById("div-pontaj");

      var WinPrint = window.open('', '', 'left=0,top=0,width=1200,height=900,toolbar=0,scrollbars=0,status=0');
      WinPrint.document.write(titlu.replace(/\s+/g, '-').toLowerCase());
      WinPrint.document.write('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css" rel="stylesheet" >');
      WinPrint.document.write('<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/js/bootstrap.min.js" integrity="sha384-Atwg2Pkwv9vp0ygtn1JAojH0nYbwNJLPhwyoVbhoPwBhjQPR5VtM2+xf0Uwh9KtT" crossorigin="anonymous"></script>');
      WinPrint.document.write('<link rel="stylesheet" href="./styles.css">');
      WinPrint.document.write('<link rel="stylesheet" href="./assets/pontajbw.css">');
      WinPrint.document.write('</head><body onload="print();close();">');
      WinPrint.document.write('</head>');
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.write('</body></html>');
      this.isPrint = false;
      WinPrint.document.close();
      WinPrint.focus();
    }, 1000);

  }

  exportExcel() {
    var fileName = this.titluTabel + '-' + Date.now() + '.xlsx';
    /* table id is passed over here */
    let element = document.getElementById('tblPontaj');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName.replace(/\s+/g, '-').toLowerCase())

  }
}
