<div class="modal-header bg-info text-white">
    <h4 class="modal-title">Schimbare parola</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="container form-horizontal" #f="ngForm">
      <fieldset>
        <div class="form-row col-12">
          <div class="col-12">
            <label for="currentPassword" class="text-dark">Introdu parola curenta</label>
            <input type="password" class="form-control form-control-sm" required [(ngModel)]="currentPassword" name="currentPassword" />
          </div>
        </div>
        <div class="form-row col-12">
            <div class="col-12">
              <label for="newPassword" class="text-dark">Introdu parola noua</label>
              <input type="password" class="form-control form-control-sm" required [(ngModel)]="newPassword" name="newPassword" />
            </div>
        </div>
        <div class="form-row col-12">
            <div class="col-12">
              <label for="confirmPassword" class="text-dark">Confirma parola noua</label>
              <input type="password" class="form-control form-control-sm" required [(ngModel)]="confirmPassword" name="confirmPassword" />
            </div>
        </div>
      </fieldset>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="save()" [disabled]="isDisabled()">Schimba parola</button>
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Anuleaza</button>
  </div>