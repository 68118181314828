<div class="modal-header bg-info text-white">
    <h4 class="modal-title">Luna de lucru</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-light">
    <form class="container form-horizontal" #f="ngForm">
      <fieldset>
        <div class="form-group row col-12">
            <div class="col-8">
              <select class="form-select" name="s_indexLunaPontaj" [(ngModel)]="indexLunaPontaj">
                <option *ngFor="let l of luni" [value]="l.indexLuna">{{l.numeLuna}}</option>
              </select>
            </div>
            <div class="col-4">
              <input type="number" class="form-control" name="anPontaj" [(ngModel)]="anPontaj">
            </div>
          </div>
      </fieldset>
    </form>
  </div>
  <div class="modal-footer bg-light">
    <button type="button" class="btn btn-success" (click)="setAnLunaPontaj()" [disabled]="!indexLunaPontaj || !anPontaj">Ok</button>
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Anuleaza</button>
  </div>
  