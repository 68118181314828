<div class="modal-header bg-info text-white">
    <h4 class="modal-title">{{dataPontaj}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="container form-horizontal" #f="ngForm">
      <h5>{{numefull}}</h5>
      <fieldset *ngIf="listaPuncteLucru">
        <div class="form-row col-12">
          <div class="col-4">
            <label class="col-form-label">Punct de lucru</label>
          </div>
          <div class="col-8">
            <select class="form-select form-select-sm" name="idpunctlucru" [(ngModel)]="idpunctlucru">
                <option *ngFor="let p of listaPuncteLucru" [value]="p.s_idpunctlucru">{{p.s_punctlucru}}</option>
              </select>
            </div>    
        </div>
        <br />
        <div class="form-row col-12">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [(ngModel)]="toataSaptamana" id="toataSaptamana"
                name="toataSaptamana" [disabled]="ziSapt != 1">
              <label class="form-check-label" for="toataSaptamana">
                Toata saptamana
              </label>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-warning mr-auto" (click)="sterge()">Sterge</button>
    <button type="button" class="btn btn-success" (click)="save()" [disabled]="!idpunctlucru">Salveaza</button>
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Anuleaza</button>
  </div>
  