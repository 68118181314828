<div class="modal-header bg-info text-white">
    <h4 class="modal-title">Personal</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="container form-horizontal" #f="ngForm">
  
      <fieldset *ngIf="id">
        <div class="form-row">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="col-form-label">Data Dezactivarii</label>
                  <div class="input-group">
                    <input class="form-control" placeholder="dd.mm.yyyy" name="dataInactiv" [(ngModel)]="dataInactiv"
                      ngbDatepicker #di="ngbDatepicker">
                    <div class="input-group-append">
                      <button class="btn" (click)="di.toggle()" type="button"><i class="fa fa-calendar"
                          aria-hidden="true"></i></button>
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </fieldset>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="save()" [disabled]="isDisabled()">Salveaza</button>
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Anuleaza</button>
  </div>
  