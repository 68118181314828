<div class="modal-header bg-info text-white">
    <h4 class="modal-title">Zile concediu</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="container form-horizontal" #f="ngForm">
  
      <fieldset *ngIf="persoana">
        <div class="form-row">
          <div class="col-6">
            <label class="col-form-label">Nume</label>
            <input type="text" class="form-control form-control-sm" name="nume" [(ngModel)]="persoana.s_nume" disabled>
          </div>
          <div class="col-6">
            <label class="col-form-label">Prenume</label>
            <input type="text" class="form-control form-control-sm" name="prenume" [(ngModel)]="persoana.s_prenume" disabled>
          </div>
        </div>
        <div class="form-row">
            <div class="col-12">
                <h3>Zile concediu pe Anul {{an}}</h3>
            </div>
        </div>
        <div class="form-row">
          <div class="col-4">
            <label class="col-form-label">Cuvenite</label>
            <input type="number" class="form-control form-control-sm" name="an" [(ngModel)]="zc" (blur)="getZileConcediuEfectuat()">
          </div>
          <div class="col-4">
            <label class="col-form-label">Efectuate</label>
            <input type="number" class="form-control form-control-sm" name="zce" [(ngModel)]="zce" disabled>
          </div>
          <div class="col-4">
            <label class="col-form-label">Ramase</label>
            <input type="number" class="form-control form-control-sm" name="zcr" [(ngModel)]="zcr" disabled>
          </div>
        </div>
              </fieldset>

    </form>
  </div>
  <div class="col-12">
    <div class="col-12">
      <h5>Selecteaza persoane pentru echipa</h5>
      <ag-grid-angular style="height: 30vh;" class="ag-theme-alpine" [gridOptions]="grid.gridOptions">
      </ag-grid-angular>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="save()" [disabled]="isDisabled()">Salveaza</button>
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Anuleaza</button>
  </div>
  