import { Component, Input, OnInit, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Compartiment, ListaUseri } from '@app/_models/personal';
import { ApiService } from '@app/_services/api.service';



@Component({
    selector: 'compartiment-edit',
    templateUrl: './compartiment.edit.html',
})

export class CompartimentEdit implements OnInit {
    constructor(public activeModal: NgbActiveModal, private apiService: ApiService) { }

    @Input() id;
    
    compartiment: Compartiment;
    listaSupervizor: ListaUseri[];
    sefCompartiment
    supervizor

    colUtilizator = [
        { property: "s_numefull", class: "col-4 col-lg-4" },
        { property: "s_functie", class: "col-4 col-lg-4" },
        { property: "s_compartiment", class: "col-4 col-lg-4" },
    ]

    ngOnInit() {
        if (this.id == 0) {
            this.compartiment = new Compartiment;
            this.compartiment.s_idcompartiment = '';
            this.compartiment.s_idsefcompartiment = '';
            this.compartiment.s_idsupervizor = '';
            this.compartiment.s_numefull = '';
            this.compartiment.s_functie = '';
            this.compartiment.s_numefullsupervizor = '';
            this.compartiment.s_functiesupervizor = '';
            console.log(this.compartiment)
            this.sefCompartiment = ''
            this.supervizor = ''
        } else {
            this.apiService.fdbGetCompartimentById(this.id).subscribe((d) => {
                this.compartiment = d[0];
                this.sefCompartiment = this.compartiment.s_numefull;
                this.supervizor = this.compartiment.s_numefullsupervizor;
                //this.utilizatori(this.compartiment.s_numefull)

                console.log(this.compartiment)
            })
        }

    }

    

    utilizatori = (search) => {
        //if (this.compartiment.s_idsefcompartiment) search = this.compartiment.s_numefull
        return this.apiService.fdbGetListaUseri(search, 0 )
    }


    supervizori = (search) => {
        //if (this.compartiment.s_idsefcompartiment) search = this.compartiment.s_numefull
        return this.apiService.fdbGetListaUseri(search, 0 )
    }

    setUtilizator(item, tip) {
        console.log(item)
        switch (tip){
            case 'sefcompartiment': this.compartiment.s_idsefcompartiment = item.s_idpersonal; break;
            case 'supervizor': this.compartiment.s_idsupervizor = item.s_idpersonal; break;
        }
    }


    save() {
        this.apiService.fdbAddCompartiment(this.compartiment).subscribe(() => {
            console.log('S-a salvat');
        });
        this.activeModal.close()

    }

    isDisabled(){
        if (this.compartiment && this.compartiment.s_compartiment) {
            return false
        }else{
            return true
        }
    }

}
