import { ToastrService } from 'ngx-toastr';
import { AgGridColumnSize } from './../controls/agGrid/agGridController';
import { AgGrid } from './../controls/agGrid/agGrid';
import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Functie, Compartiment } from '@app/_models/personal';
import { ApiService } from '@app/_services/api.service';
import { UserPersonal } from '@app/_models';
import { ColDef } from '@app/controls';



@Component({
    selector: 'punct-lucru-edit',
    templateUrl: './punct-lucru.edit.html',
})

export class PunctlucruEdit implements OnInit {
    constructor(public activeModal: NgbActiveModal, private apiService: ApiService, private log: ToastrService) { }

    @Input() idPunctLucru;
    @Input() idCompartiment;
    punctLucru: any;
    listaCompartimente


    ngOnInit() {
        if (this.idPunctLucru == "") {
            this.punctLucru = {
                s_idpl: 0, s_idpunctlucru: "", s_punctlucru: "", s_idcompartiment: "", s_stamp: new Date()
            }
            console.log(this.punctLucru.s_idpunctlucru)
        } else {
            this.getPunctLucru()
        }

    }


    getPunctLucru() {
        this.apiService.fdbGetElementById('s_punctlucru', 's_idpunctlucru', this.idPunctLucru, 1).subscribe((d) => {
            this.punctLucru = d[0];
        })
    }

    
    isDisabled(){
        var p = this.punctLucru
        if (!p.s_punctlucru){
             return true
        }else{
             return false
        }
    }

    save() {
        this.apiService.fdbAddPunctLucru(this.punctLucru.s_idpunctlucru, this.punctLucru.s_punctlucru, this.idCompartiment).subscribe((idNou) => {
            this.log.success('Punct lucru salvat');
            console.log('d', idNou);
            this.activeModal.close()
        });

    }
}
