<div class="modal-header bg-info text-white">
  <h4 class="modal-title">Introdu adresa de email</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="container form-horizontal" #f="ngForm">
    <fieldset>
      <div class="form-row col-12">
        <div class="col-12">
          <input type="text" class="form-control form-control-sm" required [(ngModel)]="email" name="email" />
        </div>
      </div>
      <br />
      <div class="form-row col-12">
        <div class="col-12">
          <div class="alert alert-warning">
            <p>Parola noua va fi trimisa la adresa dvs. de email.</p>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" (click)="save()" [disabled]="isDisabled()">Trimite email cu noua
    parola</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Anuleaza</button>
</div>