<div class="col-12">
  <h4>Utilizatori</h4>
  <form #f="ngForm">
    <div class="alert alert-warning col-12">
      <div class="col-12">
        <div class="form-group row">

          <div class="col-6 row">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-search"></i></div>
              </div>

              <input type="text" class="form-control form-control-sm col-4" name="cauta" [(ngModel)]="searchText"
                (ngModelChange)="getListaUtilizatori()">
            </div>
          </div>
          <div class="col-6 text-right">
            <button class="btn btn-sm btn-success ml-1" (click)="addUtilizator()"><i
                class="fa fa-plus-circle"></i>&nbsp;Adauga cont</button>
            <button class="btn btn-sm btn-primary ml-1" (click)="editUtilizator()"
              [disabled]="disableButton('editUtilizator')"><i class="fa fa-edit"></i>&nbsp;Modifica</button>
            <button class="btn btn-sm btn-danger ml-1" (click)="stergUtilizator()"
              [disabled]="disableButton('stergUtilizator')"><i class="fa fa-trash"></i>&nbsp;Sterge</button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="form-row col-12">
    <div class="col-6">
      <ag-grid-angular style="height: 76vh;" class="ag-theme-alpine" [gridOptions]="gridUtilizatori.gridOptions">
      </ag-grid-angular>
    </div>
    <div class="col-6">
      <div class="alert alert-primary col-12">
        <div class="form-row col-12">
          <h6>Compartimente pentru pontaj {{numeUtilizator}}</h6>

        </div>
        <div class="form-row col-12">
          <button class="btn btn-sm btn-danger" (click)="stergCompartiment()"
            [disabled]="disableButton('stergCompartiment')"><i class="fas fa-trash"></i>&nbsp;Elimina
            compartiment</button>
        </div>
      </div>
      <ag-grid-angular style="height: 65vh;" class="ag-theme-alpine" [gridOptions]="gridCompartiment.gridOptions">
      </ag-grid-angular>

    </div>
  </div>
