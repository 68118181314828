import { UtilsService } from '../_services/utils.service';
import { Component, Input, OnInit, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Schimb, ListaCompartimente } from '@app/_models/personal';
import { ApiService } from '@app/_services/api.service';
import { ToastrService } from 'ngx-toastr';



@Component({
    selector: 'pontaj-update',
    templateUrl: './pontaj.update.html',
})

export class PontajUpdate implements OnInit {
    constructor(public activeModal: NgbActiveModal, private apiService: ApiService, private utils: UtilsService, private log: ToastrService) { }

    @Input() idPontaj;
    @Input() campUpdate;
    @Input() valoareUpdate;
    @Input() descriereUpdate;
    @Input() transformSec: boolean;

    valoareUpdateH: number = 0;
    durataHH: number = 0;
    durataMM: number = 0;


    ngOnInit() {
        if (!this.descriereUpdate) this.descriereUpdate = 'Modifica';

        var durata = this.utils.getHourMinFromSec(this.valoareUpdate, false)
        if (this.transformSec == true){
            this.durataHH = durata[0];
            this.durataMM = durata[1];
        } 
            

    }



    save() {
        var u = 0;
        if (this.transformSec == true) {
            u = this.utils.getSeconds(this.durataHH, this.durataMM)
        } else {
            u = this.valoareUpdate
        }
        this.apiService.fdbUpdatePontaj(this.idPontaj, this.campUpdate, u).subscribe(() => {
            this.log.success('S-a salvat');
            this.activeModal.close();
        });
    }


}
