import { PersonalStatus } from './personal.status';
import { AuthenticationService } from './../_services/authentication.service';
import { DialogService } from '@app/_services/dialog.service';
import { PersonalEdit } from './personal.edit';
import { ApiService } from '@app/_services/api.service';
import { Personal } from '@app/_models/personal';
import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AgGrid, AgGridColumnSize, ColDef, Renderers, RowNode } from '@app/controls';
import { UtilsService } from '@app/_services/utils.service';
import { PersonalZileConcediu } from './personal.ZileConcediu';

//dayjs.locale('es') // use Spanish locale globally


@Component({
  selector: 'lista-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css']
})



export class PersonalComponent implements OnInit {

  constructor(private apiService: ApiService,
    private dialog: DialogService, private activatedRoute: ActivatedRoute,
    private log: ToastrService, private auth: AuthenticationService,
    private utils: UtilsService, private router: Router) { }


  grid: AgGrid;
  listaPersonal: Personal[];
  campSort: string = 's_numefull';
  ordineSort: string = 'ASC'
  inactiv: number = 0;
  searchText: string = '';
  idCompartiment;
  idPersonal: string = '';
  isInactiv: number;
  numeCompartiment: string = "toate compartimentele";
  anLunaPontaj
  user


  ngOnInit(): void {
    localStorage.setItem('curentUrl', this.router.url)
    this.auth.getUserLogat.emit(this.auth.currentUserValue)
    this.user = this.auth.currentUserValue
    this.anLunaPontaj = this.utils.anLunaPontaj
    this.idCompartiment = this.activatedRoute.snapshot.paramMap.get('idCompartiment') || "";
    if (this.idCompartiment) {
      this.apiService.fdbGetCompartimentById(this.idCompartiment).subscribe((d) => {
        this.numeCompartiment = d[0].s_compartiment;
      });
    }

    this.setupGrid();
    this.getListaPersonal()
  }

  setupGrid() {
    var colDefs: ColDef[] = [
      { headerName: 'Nume', field: 's_numefull' },
      { headerName: 'Marca', field: 's_marca' },
      { headerName: 'Functie', field: 's_functie', },
      { headerName: 'Compartiment', field: 's_compartiment' },
      { headerName: 'Echipa', field: 's_echipa' },
      { headerName: 'Data inactiv', field: 's_data_inactiv', cellRenderer: Renderers.Date },
      { headerName: 'CO cuvenit', field: 's_zc' },
      { headerName: 'Ian', field: 'co_ian' },
      { headerName: 'Feb', field: 'co_feb' },
      { headerName: 'Mar', field: 'co_mar' },
      { headerName: 'Apr', field: 'co_apr' },
      { headerName: 'Mai', field: 'co_mai' },
      { headerName: 'Iun', field: 'co_iun' },
      { headerName: 'Iul', field: 'co_iul' },
      { headerName: 'Aug', field: 'co_aug' },
      { headerName: 'Sep', field: 'co_sep' },
      { headerName: 'Oct', field: 'co_oct' },
      { headerName: 'Nov', field: 'co_nov' },
      { headerName: 'Dec', field: 'co_dec' },
    ];
    this.grid = new AgGrid(colDefs, null, null);
    this.grid.onDataLoaded = () => {
      //this.gridDetaliu.gridOptions.api.sizeColumnsToFit();
      var allColumnIds = [];
      this.grid.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.getColId());
      });

      this.grid.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);

    }

    this.grid.onAddKey = () => { }
    this.grid.onEditKey = () => { }
    this.grid.onDeleteKey = () => { }
    this.grid.onRowDoubleClick = () => {
      this.idPersonal = this.grid.selectedRow.s_idpersonal
      this.edit(this.idPersonal);
    }
    this.grid.onRowSelected = () => {
      this.isInactiv = this.grid.selectedRow.s_inactiv
      this.idPersonal = this.grid.selectedRow.s_idpersonal
    }

    this.grid.gridOptions.getRowClass = (node) => {
      if (node.data.s_inactiv == 1) { return 'text-warning' }
    }
  }

  getListaPersonal() {
    console.log(this.idPersonal)
    this.apiService.fdbGetPersonalConcediu(
      this.idCompartiment, this.campSort, this.ordineSort, this.inactiv,
      this.auth.currentUserValue.s_idpersonal, this.auth.currentUserValue.s_tipuser, this.searchText
    ).subscribe((d: Personal[]) => {
      this.listaPersonal = d;
      this.grid.setDataSource(this.listaPersonal).then(() => {
        this.grid.gridOptions.api.forEachNode(node => {
          if (node.data.s_idpersonal == this.idPersonal) {
            //console.log('nod', node.data.s_idpersonal)
            node.setSelected(true);
          }
        });
      })

    })
  }


  edit(idPersonal) {
    this.dialog.custom(PersonalEdit, { id: idPersonal, idCompartiment: this.idCompartiment }).result.then(() => {
      this.getListaPersonal();
    })
  }

  zileConcediu(){
    var id = this.idPersonal;
    var an = (this.utils.anLunaPontaj/100).toFixed(0);
    var zc = this.grid.selectedRow.s_zc
    this.dialog.custom(PersonalZileConcediu, { id: id, an: an, zc: zc }, { size: 'lg'}).result.then(() => {
      this.getListaPersonal();
    })
  }

  setStatus() {
    var id = this.idPersonal
    var numePersoana = this.grid.selectedRow.s_numefull
    var status = this.isInactiv
    var msg = status == 0 ? 'Doriti sa dezactivati persoana?' : 'Doriti sa activati persoana?';
    // this.dialog.confirm({ message: msg, confirmBtn: status == 0 ? 'Dezactiveaza' : 'Activeaza', cancelBtn: 'Anulare' }).then(() => {
    //   this.apiService.fdbSetStatus('s_personal', 's_inactiv', 's_idpersonal', id, status == 0 ? 1 : 0, 0).subscribe(() => {
    //     this.getListaPersonal();
    //   });
    // })
    this.dialog.custom(PersonalStatus, {id : id, numePersoana: numePersoana }).result.then(()=>{
      this.getListaPersonal();
    })
  }

  sterge(idPersonal) {
    var nume = this.grid.selectedRow.s_numefull
    var msg = 'Doriti sa stergeti\n' + nume + '?';
    this.apiService.fdbCheckExists('s_programarepersonal', 's_idpersonalprog', this.idPersonal, 1).then((d) => {
      console.log(d)
      if (d[0].result == 0) {
        this.dialog.confirm({ message: msg, confirmBtn: 'Sterge', cancelBtn: 'Anulare' }).then(() => {
          this.apiService.fdbDeleteElementById('s_personal', 's_idpersonal', idPersonal, 1).subscribe(() => {
            this.log.success(nume + '\n a fost sters!')
            this.getListaPersonal()
          })
        })
      } else {
        this.log.error('Nu se poate sterge, deoarece are pontaj')
      }
    })
  }

  ymd2dmy(data) {
    if (data && data != '1900-01-01' && data != '0000-00-00')
      return dayjs(data).format('DD.MM.YYYY');
  }

  disableButton() {
    if (this.grid.selectedRow.s_idpersonal) {
      return false;
    } else {
      return true;
    }
  }

  visibleButton() {
    if (this.user.s_tipuser == 1 || this.user.s_tipuser == 3 || this.user.s_tipuser == 4) {
      return true
    } else {
      return false
    }
  }

}
