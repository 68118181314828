<div class="col-12">

    <h4>Pontaj {{numeCompartiment}}</h4>
 
    <div class="alert alert-primary col-12">
      <div class="form-group row">
        <div class="col-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text"><i class="fa fa-search"></i></div>
            </div>
            <input type="text" class="form-control form-control-sm" name="cauta" [(ngModel)]="searchText"
              (ngModelChange)="afiseaza()">
          </div>
        </div>
        <div class="col-4">
          <div class="form-row col-12">
            <div class="col-6">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" [(ngModel)]="isExport" id="isExport" name="isExport"
                  (click)="afiseaza()">
                <label class="form-check-label" for="isExport">
                  Ascunde schimburi
                </label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" [(ngModel)]="isDecimal" id="isDecimal" name="isDecimal"
                  (click)="afiseaza()">
                <label class="form-check-label" for="isDecimal">
                  Ore in format zecimal
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <button class="btn btn-sm btn-light" (click)="setEchipeForPerioadaPontaj()"><i
              class="fas fa-user-friends"></i>&nbsp;Actualizeaza echipe</button>
        </div>
        
        <div class="col-3">
          <button class="btn btn-sm btn-success ml-1" (click)="exportExcel()" [disabled]="!listaPersonalPontaj"><i
              class="far fa-file-excel"></i>&nbsp;Excel</button>
          <button class="btn btn-sm btn-dark ml-1" (click)="printPontaj()" [disabled]="!listaPersonalPontaj"><i
                class="fa fa-print"></i>&nbsp;Print</button>
          <button class="btn btn-sm btn-primary ml-1" (click)="printProgram()" [disabled]="!listaPersonalPontaj"><i
                  class="fa fa-print"></i>&nbsp;Print programare</button>      
        </div>

      </div>
      
    </div>
    <!-- NAV TABS-->
    <div class="col-12" *ngIf="idCompartiment">
      <navigaretab></navigaretab>
    </div>
    
    
    <div  id="div-pontaj">
      <!-- HEADER -->
      <!-- <div class="pontaj-head">
        <div class="form-row col-12">
          <div class="col-6">
            SC URBIS SA<br />
            Baia Mare<br />
            Nr.: ______________/______________________
          </div>
          <div class="col-4">
            &nbsp;
          </div>
          <div class="col-2 text-center">
            Aprob <br/>
            Director General<br />
            ing. Rusu Tudor
          </div>
        </div>
        <br />
      </div> -->
      <table class="mytable table table-sm table-hover table-bordered" id="tblPontaj">
        <thead>
          <tr>
            <th [attr.colspan]="colspanZile"  class="pontaj-head">
              <div class="col-12">
                SC URBIS SA<br />
                Baia Mare<br />
                Nr.: ______________/______________________
              </div>
              <br />
            </th>
            <th colspan="30"  class="pontaj-head">
              <div class="col-12 text-center">
                Aprob <br/>
                Director General<br />
                {{aprobDirectorGeneral}}
              </div>
              <br />
            </th>
          </tr>
          <tr>
            <th [attr.colspan]="isPrint ? '4' : '5'" class="bg-light">
              <button class="btn btn-sm btn-success ml-1" *ngIf="!isPrint" (click)="addPersoanaForPontaj()"><i
                class="far fa-user-plus"></i>&nbsp;Adauga persoana</button>
            </th>
            <th [attr.colspan]="colspanZile" class="bg-light text-center">
              {{titluPontaj}}
            </th>
            <th colspan="7" class="bg-light" *ngIf="!isPrintProgram">
              Zile lucratoare: <strong>{{zileLucratoare}}</strong>
            </th>
            <th colspan="17" class="bg-light" *ngIf="!isPrintProgram">
              Ore/luna: <strong>{{oreLucratoare}}</strong>
            </th>
            <th class="bg-light" *ngIf="!isPrint && !isPrintProgram">&nbsp;</th>
          </tr>
          <tr>
            <th class="LV" rowspan="2">Nr.</th>
            <th class="LV" rowspan="2"  *ngIf="!isPrint && !isPrintProgram"><a href="javascript:void(0)" (click)="ordoneazaNume('echipa')"  ngbTooltip="Ordoneaza dupa echipa">Echipa</a></th>
            <th class="LV" rowspan="2"><a href="javascript:void(0)" (click)="ordoneazaNume('nume')"  ngbTooltip="Ordoneaza dupa nume">Nume</a></th>
            <th class="LV" rowspan="2">Marca</th>
            <th class="LV" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Report ore din luna precedenta">RLP</span></th>
            <th [ngClass]="getCodZi(i+1)" *ngFor="let z of arrZl; index as i">{{i+1}}</th>
            <th class="ORE" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Zile active">ZA</span></th>
            <th class="ORE" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Ore lucrate">OL</span></th>
            <th class="ORE" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Ore platite">OP</span></th>
            <th class="NO" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Ore noapte">ON</span></th>
            <th class="OSL" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Ore suplimentare lucrate">OSL</span></th>
            <th class="OSL" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Ore suplimentare platite">OSP</span></th>
            <th class="OSL" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Ore de reportat luna urmatoare">REP</span></th>
            <th class="CO" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Concediu de odihna">CO</span></th>
            <th class="B" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Concediu medical">B</span></th>
            <th class="CFS" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Concediu fara salar">CFS</span></th>
            <th class="R" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Repaus">R</span></th>
            <th class="VMA" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Vizita medicala">VM</span></th>
            <th class="ES" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Eveniment special">ES</span></th>
            <th class="LP" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Libera platita">LP</span></th>
            <th class="RS" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Repaus solicitat SD">RS</span></th>
            <th class="RV" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Revizii">RV</span></th>
            <th class="DS" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Donare sange">DS</span></th>
            <th class="PSI" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Examinare psihologica">PSI</span></th>
            <th class="N" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Absenta nemotivata">N</span></th>
            <th class="M" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Maternitate">M</span></th>
            <th class="ORE" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Ore circulatie">OC</span></th>
            <th class="ORE" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Ore garaj">OG</span></th>
            <th class="ORE" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Ore conditii">OCN</span></th>
            <th class="SD" rowspan="2" *ngIf="!isPrintProgram"><span placement="top" ngbTooltip="Simbete si duminici lucrate">SD</span></th>
            <th class="LV" rowspan="2" *ngIf="!isPrint && !isPrintProgram">
              &nbsp;
            </th>
          </tr>
          <tr>
            <th [ngClass]="getCodZi(i+1)" *ngFor="let z of arrZl; index as i">{{getNumeZi(i+1)}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let p of listaPersonalPontaj; index as i" draggable="true"
            (dragover)="allowDrop($event)"
            (dragstart)="onDragStart(i)"
            (drop)="onDrop($event, i)"> 
            <td class="LV text-right">{{ i+1 }}</td>
            <td class="LV" style="text-align: left;" *ngIf="!isPrint && !isPrintProgram">{{ p.s_echipa }}</td>
            <td [ngClass]="checkOreLucrateComplet(i)"  style="text-align: left;">{{ p.s_nume}}&nbsp;<br />{{p.s_prenume }}</td>
            <td class="LV" style="text-align: left;">{{ p.s_marca}}</td>
            <td class="LV" *ngIf="!isPrintProgram"><span *ngIf="p.s_srlp!=0">{{afiseazaOre(p.s_srlp)}}</span></td>
            <td [ngClass]="getCodZi(j+1)" *ngFor="let z of arrZl; index as j">
              <a href="javascript:void(0)"
                (click)="notaZi(p.s_idpontaj, (j+1), getField('nota', i, (j+1)), p.s_numefull)"
                title="{{getField('nota', i, (j+1))}}" *ngIf="p.s_idpersonalprog && !isExport">
                <div class="{{getField('nota', i, (j+1))!='' ? 'zinotafull' : 'zinota'}}"><i
                    class="fas fa-comment-alt"></i></div>
              </a>
              <a href="javascript:void(0)"
                (click)="pontajZi(
                    p.s_idpontaj
                  , p.s_idechipa
                  , p.s_numefull
                  , (j+1)
                  , getField('codprg', i, (j+1))
                  , getField('durata', i, (j+1))
                  , getField('duratasuplimentara', i, (j+1))
                  , getField('duratanoapte', i, (j+1))
                  )"
                *ngIf="p.s_idpersonalprog">
                <div
                  [innerHtml]="getSchimbById(getField('codprg', i, (j+1)), getField('durata', i, (j+1)), getField('duratasuplimentara', i, (j+1)))">
                </div>
              </a>
              <div *ngIf="listaPuncteLucru && listaPuncteLucru.length && (getField('tipactivitate', i, (j+1)) == 'S')">
                <a href="javascript:void(0)" 
                  (click)="setPunctLucruZi(p.s_idpontaj, (j+1), getField('punctlucru', i, (j+1)), p.s_numefull)"
                  title="{{getField('nota', i, (j+1))}}" *ngIf="p.s_idpersonalprog && !isExport">
                  <div
                    [innerHtml]="getPunctLucruById(getField('punctlucru', i, (j+1)))">
                  </div>
                  
                </a>
            </div>
            </td>
            <td class="ORE" *ngIf="!isPrintProgram">
              <div><strong>{{ (p.za) }}</strong></div>
            </td>
            <td class="ORE" *ngIf="!isPrintProgram">
              <div *ngIf="p.secpontaj!=0 && !isPrint">{{ afiseazaOre(p.secpontaj) }}</div>
              <div *ngIf="p.secpontajlucrat!=0 && !isPrint" class="OSL" >{{ afiseazaOre(p.secduratasuplimentara) }}</div>
              <div *ngIf="p.secpontajlucrat!=0"><strong>{{ afiseazaOre(p.secpontajlucrat) }}</strong></div>
            </td>
            <td class="ORE" *ngIf="!isPrintProgram">
              <div *ngIf="p.secpontajzl!=0"><strong>{{ afiseazaOre(p.secpontajzl) }}</strong></div>
            </td>
            <td class="NO" *ngIf="!isPrintProgram">
              <span *ngIf="p.secnoapte!=0"><strong>{{ afiseazaOre(p.secnoapte) }}</strong></span>
            </td>
            <td class="OSL" *ngIf="!isPrintProgram">
              <span *ngIf="p.s_sosl!=0">{{ afiseazaOre(p.s_sosl) }}</span>
            </td>
            <td class="OSL" *ngIf="!isPrintProgram">
              <span><a href="javascript:void(0)" (click)="pontajUpdate('s_sosp', p.s_sosp, p.s_idpontaj, true, 'Ore suplimentare platite')">{{ afiseazaOre(p.s_sosp) }}</a></span>
            </td>
            <td class="DEP" *ngIf="!isPrintProgram">
              <span *ngIf="p.s_sosrep!=0"><strong>{{ afiseazaOre(p.s_sosrep) }}</strong></span>
            </td>
            <td class="CO" *ngIf="!isPrintProgram">
              <span *ngIf="p.zco!=0">{{p.zco}}</span>
              <!-- <br />
              <span *ngIf="p.sco!=0 && !isExport">{{afiseazaOre(p.sco)}}</span> -->
            </td>
            <td class="CM" *ngIf="!isPrintProgram">
              <span *ngIf="p.zb!=0">{{p.zb}}</span>
              <!-- <br />
              <span *ngIf="p.sb!=0 && !isExport">{{afiseazaOre(p.sb)}}</span> -->
            </td>
            <td class="CFS" *ngIf="!isPrintProgram">
              <span *ngIf="p.zcfs!=0">{{p.zcfs}}</span>
              <!-- <br />
              <span  *ngIf="p.scfs!=0 && !isExport">{{afiseazaOre(p.scfs)}}</span> -->
            </td>
            <td class="R" *ngIf="!isPrintProgram">
              <span *ngIf="p.zr!=0">{{p.zr}}</span>
              <!-- <br />
              <span *ngIf="p.sr!=0 && !isExport">{{afiseazaOre(p.sr)}}</span> -->
            </td>
            <td class="VMA" *ngIf="!isPrintProgram">
              <span *ngIf="p.zvma!=0">{{p.zvma}}</span>
              <!-- <br />
              <span *ngIf="p.svma!=0 && !isExport">{{afiseazaOre(p.svma)}}</span> -->
            </td>
            <td class="ES" *ngIf="!isPrintProgram">
              <span *ngIf="p.zes!=0">{{p.zes}}</span>
              <!-- <br />
              <span  *ngIf="p.ses!=0 && !isExport">{{afiseazaOre(p.ses)}}</span> -->
            </td>
            <td class="LP" *ngIf="!isPrintProgram">
              <span *ngIf="p.zlp!=0">{{p.zlp}}</span>
              <!-- <br />
              <span  *ngIf="p.slp!=0 && !isExport">{{afiseazaOre(p.slp)}}</span> -->
            </td>
            <td class="RS" *ngIf="!isPrintProgram">
              <span *ngIf="p.zrs!=0">{{p.zrs}}</span>
              <!-- <br />
              <span  *ngIf="p.srs!=0 && !isExport">{{afiseazaOre(p.srs)}}</span> -->
            </td>
            <td class="RV" *ngIf="!isPrintProgram">
              <span *ngIf="p.zrv!=0">{{p.zrv}}</span>
             <br />
              <span  *ngIf="p.srv!=0 && !isExport">{{afiseazaOre(p.srv)}}</span>
            </td>
            <td class="DS" *ngIf="!isPrintProgram">
              <span *ngIf="p.zds!=0">{{p.zds}}</span>
              <!-- <br />
              <span  *ngIf="p.sds!=0 && !isExport">{{afiseazaOre(p.sds)}}</span> -->
            </td>
            <td class="PSI" *ngIf="!isPrintProgram">
              <span *ngIf="p.zpsi!=0">{{p.zpsi}}</span>
              <!-- <br />
              <span  *ngIf="p.spsi!=0 && !isExport">{{afiseazaOre(p.spsi)}}</span> -->
            </td>
            <td class="N" *ngIf="!isPrintProgram">
              <span *ngIf="p.zn!=0">{{p.zn}}</span>
              <!-- <br />
              <span  *ngIf="p.sn!=0 && !isExport">{{afiseazaOre(p.sn)}}</span> -->
            </td>
            <td class="M" *ngIf="!isPrintProgram">
              <span *ngIf="p.zm!=0">{{p.zm}}</span>
              <!-- <br />
              <span  *ngIf="p.sm!=0 && !isExport">{{afiseazaOre(p.sm)}}</span> -->
            </td>
            <td class="ORE" *ngIf="!isPrintProgram">
              <div><a href="javascript:void(0)" (click)="pontajUpdate('s_soc', p.s_soc, p.s_idpontaj, true, 'Ore circulatie')">{{ afiseazaOre(p.s_soc) }}</a></div>
            </td>
            <td class="ORE" *ngIf="!isPrintProgram">
              <div><a href="javascript:void(0)" (click)="pontajUpdate('s_sog', p.s_sog, p.s_idpontaj, true, 'Ore garaj')">{{ afiseazaOre(p.s_sog) }}</a></div>
            </td>
            <td class="ORE" *ngIf="!isPrintProgram">
              <div><a href="javascript:void(0)" (click)="pontajUpdate('s_socn', p.s_socn, p.s_idpontaj, true, 'Ore conditii nocive')">{{ afiseazaOre(p.s_socn) }}</a></div>
            </td>
            <td class="SD" *ngIf="!isPrintProgram"><span *ngIf="p.zsd!=0">{{p.zsd}}</span></td>
            <td class="LV" *ngIf="!isPrint && !isPrintProgram">
              <button class="btn btn-light btn-sm" (click)="addPontaj(p.s_idpersonal, anLunaPontaj)"
                *ngIf="!p.s_idpersonalprog" placement="left" ngbTooltip="Adauga in pontaj"><i class="far fa-clock"></i></button>
              <button class="btn btn-light btn-sm" (click)="stergPontaj(p.s_idpontaj)" *ngIf="p.s_idpersonalprog" placement="left" ngbTooltip="Sterge din pontaj"><i
                  class="far fa-times"></i></button>
            </td>
          </tr>
          <tr>
            <td [attr.colspan]="isPrint ? '3' : '4'" class="LV">
              <strong>TOTAL</strong>
            </td>
            <td class="LV" *ngIf="!isPrintProgram">
              <strong>{{afiseazaOre(total.totalSrlp)}}</strong>
            </td>
            <td [attr.colspan]="colspanZile">

            </td>
            <td class="ORE" *ngIf="!isPrintProgram">
              <strong>{{total.totalZa}}</strong>
            </td>
            <td class="ORE" *ngIf="!isPrintProgram">
              <strong>{{afiseazaOre(total.totalSecpontajlucrat)}}</strong>
            </td>
            <td class="ORE" *ngIf="!isPrintProgram">
              <strong>{{afiseazaOre(total.totalSecpontajzl)}}</strong>
            </td>
            <td class="NO" *ngIf="!isPrintProgram">
              <strong>{{afiseazaOre(total.totalSecnoapte)}}</strong>
            </td>
            <td class="OSL" *ngIf="!isPrintProgram">
              <strong>{{ afiseazaOre(total.totalSecosl) }}</strong>
            </td>
            <td class="OSL" *ngIf="!isPrintProgram">
              <strong>{{ afiseazaOre(total.totalSosp) }}</strong>
            </td>
            <td class="DEP" *ngIf="!isPrintProgram">
              <strong>{{afiseazaOre(total.totalSosrep)}}</strong>
            </td>
            <td class="CO" *ngIf="!isPrintProgram">{{total.totalZco}}</td>
            <td class="CM" *ngIf="!isPrintProgram">{{total.totalZb}}</td>
            <td class="CFS" *ngIf="!isPrintProgram">{{total.totalZcfs}}</td>
            <td class="R" *ngIf="!isPrintProgram">{{total.totalZr}}</td>
            <td class="VMA" *ngIf="!isPrintProgram">{{total.totalZvma}}</td>
            <td class="ES" *ngIf="!isPrintProgram">{{total.totalZes}}</td>
            <td class="LP" *ngIf="!isPrintProgram">{{total.totalZlp}}</td>
            <td class="RS" *ngIf="!isPrintProgram">{{total.totalZrs}}</td>
            <td class="RV" *ngIf="!isPrintProgram">{{total.totalZrv}}</td>
            <td class="DS" *ngIf="!isPrintProgram">{{total.totalZds}}</td>
            <td class="PSI" *ngIf="!isPrintProgram">{{total.totalZpsi}}</td>
            <td class="N" *ngIf="!isPrintProgram">{{total.totalZn}}</td>
            <td class="M" *ngIf="!isPrintProgram">{{total.totalZm}}</td>
            <td class="ORE" *ngIf="!isPrintProgram">{{afiseazaOre(total.totalSecOc)}}</td>
            <td class="ORE" *ngIf="!isPrintProgram">{{afiseazaOre(total.totalSecOg)}}</td>
            <td class="ORE" *ngIf="!isPrintProgram">{{afiseazaOre(total.totalSecOcn)}}</td>
            <td class="SD" *ngIf="!isPrintProgram">{{total.totalZsd}}</td>
            <td *ngIf="!isPrint && !isPrintProgram">&nbsp;</td>
          </tr>
        </tbody>
        <tfoot *ngIf="!isPrintProgram">
          <tr>
            <td [attr.colspan]="colspanZile"  class="pontaj-footer">
              <div class="col-12">
                {{dataIntocmit}}
              </div>
            </td>
            <td colspan="30"  class="pontaj-footer">  
              <div class="col-12 text-center">
                <div>{{intocmit.s_tipuser == 3 ? 'Printat' : 'Intocmit'}}</div>
                <div *ngIf="intocmit.s_functie">{{intocmit.s_functie}}</div>
                {{intocmit.s_numeprenume}}
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <!-- footer -->
      <!-- <div class="pontaj-head">
        <div class="form-row col-12">
          <div class="col-6">
            {{dataIntocmit}}
          </div>
          <div class="col-4">
            &nbsp;
          </div>
          <div class="col-2 text-center">
            <div>{{intocmit.s_tipuser == 3 ? 'Printat' : 'Intocmit'}}</div>
            <div *ngIf="intocmit.s_functie">{{intocmit.s_functie}}</div>
            {{intocmit.s_numeprenume}}
          </div>
        </div>
      </div> -->
  

    </div>
    