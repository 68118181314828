import { ToastrService } from 'ngx-toastr';
import { AgGridColumnSize } from './../controls/agGrid/agGridController';
import { AgGrid } from './../controls/agGrid/agGrid';
import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Functie, Compartiment } from '@app/_models/personal';
import { ApiService } from '@app/_services/api.service';
import { UserPersonal } from '@app/_models';
import { ColDef } from '@app/controls';



@Component({
    selector: 'utilizator-edit',
    templateUrl: './utilizator.edit.html',
})

export class UtilizatorEdit implements OnInit {
    constructor(public activeModal: NgbActiveModal, private apiService: ApiService, private log: ToastrService) { }

    @Input() idUtilizator;
    grid: AgGrid
    utilizator: UserPersonal;
    listaFunctii: Functie[];
    listaCompartimente
    listaUtilizatori
    pwd1: string = "";
    pwd2: string = "";
    isNou: boolean = false
    isSetParola: boolean = false;

    listaTipUser: any[] = [
        { s_tipUser: 2, numeTipUser: 'Drepturi Pontaj' },
        { s_tipUser: 3, numeTipUser: 'Drepturi Personal' },
        { s_tipUser: 4, numeTipUser: 'Drepturi Personal + Pontaj' },
        { s_tipUser: 1, numeTipUser: 'Admin' },

    ]

    colUtilizator = [
        { property: "s_numefull", class: "col-4" },
        { property: "s_functie", class: "col-4" },
        { property: "s_compartiment", class: "col-4" },
    ]

    ngOnInit() {
        if (this.idUtilizator == 0) {
            this.isNou = true;
            this.isSetParola = true;
        } else {
            this.isNou = false
            this.isSetParola = false;
            this.getUtilizator()
        }

        this.getFunctii();
        this.getUtilizatoriDisponibili();
        this.setupGrid()
        console.log(this.isSetParola)
    }

    setupGrid() {
        var colDefs: ColDef[] = [
            { headerName: 'Compartiment', field: 's_compartiment', checkboxSelection: true, headerCheckboxSelection: true, },
        ];
        this.grid = new AgGrid(colDefs, false, true);
        this.grid.onDataLoaded = () => {
            //this.gridDetaliu.gridOptions.api.sizeColumnsToFit();
            var allColumnIds = [];
            this.grid.gridOptions.columnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.getColId());
            });
            this.grid.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);
        }
        this.grid.onRowSelected = () => {
        }
    }

    getUtilizator() {
        this.apiService.fdbGetUtilizatorById(this.idUtilizator).subscribe((d) => {
            this.utilizator = d[0];
            console.log(this.utilizator)
            this.getCompartimente(this.utilizator.s_idpersonal);
        })
    }

    utilizatori = (search) => {
        return this.apiService.fdbGetUtilizatoriDisponibili(search);
    }

    setUtilizator(item) {
        this.idUtilizator = item.s_idpersonal;
        this.getUtilizator()
    }

    getUtilizatoriDisponibili() {
        this.apiService.fdbGetUtilizatoriDisponibili('').subscribe((d) => {
            this.listaUtilizatori = d;
        })
    }

    getFunctii() {
        this.apiService.fdbGetFunctii('').subscribe((d) => {
            this.listaFunctii = d;
        })
    }

    getCompartimente(idUtilizator) {
        this.apiService.fdbGetCompartimenteDisponibile(idUtilizator, '').subscribe((d) => {
            this.listaCompartimente = d;
            this.grid.setDataSource(this.listaCompartimente)
        })
    }

    isDisabled(){
        return !this.utilizator || !this.utilizator.s_email || !this.utilizator.s_tipuser;
    }

    save() {
        var msgErr = ""
        var listaIds = "";
        const ids = this.grid.getSelectedRows().map(r => r.s_idcompartiment);
        console.log(ids);
        for (var i = 0; i < ids.length; i++) {
            listaIds += "'" + ids[i] + "',";
        }
        listaIds = listaIds.slice(0, -1);
        if (this.isSetParola) {
            if (this.pwd1 != this.pwd2 || this.pwd1 == "" || this.pwd2 == "") msgErr += "Parolele nu sunt identice";
            if (!this.utilizator.s_email || this.utilizator.s_email == "") msgErr += "Introduceti adresa de email";
            if (msgErr == "") {
                this.utilizator.s_pwd = this.pwd1;
                this.apiService.fdbCheckExists('s_personal', 's_email', this.utilizator.s_email, 1).then((d) => {
                    // verifica daca acest email exista deja in DB
                    if (d[0].result == 0) {
                        // daca nu exista salveaza datele
                        this.commitSave(listaIds)
                    } else {
                        if (this.isNou) {
                            //daca exista si e user nou, nu salveaza datele
                            this.log.error('Acest email exista deja la alt utiilizator');
                        }else{
                            this.commitSave(listaIds)
                        }
                    }
                })
            }
        } else {
            this.apiService.fdbSetCompartimenteUtilizator(this.utilizator.s_idpersonal, listaIds).subscribe(() => {
                this.log.success('Compartimente salvate');
                this.activeModal.close()
            });
        }

    }

    commitSave(listaIds?){
        this.apiService.fdbSetUtilizator(this.utilizator).subscribe(() => {
            this.log.success('Utilizator salvat');
            if (listaIds) {
                this.apiService.fdbSetCompartimenteUtilizator(this.utilizator.s_idpersonal, listaIds).subscribe(() => {
                    this.log.success('Compartimente salvate');
                    this.activeModal.close();
                })
            }else{
                this.activeModal.close();
            }
        });
    }

    


}
