<div class="col-md-3">
    <div class="card bg-dark">
        <h4 class="card-header text-white bg-primary">Login</h4>
        <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username" class="text-light">Email</label>
                    <input type="email" formControlName="username" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Introduceti utilizator</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password" class="text-light">Parola</label>
                    <input type="password" formControlName="password" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Introduceti parola</div>
                    </div>
                </div>
                <div class="form-row">
                   <div class="col-6">
                    <button [disabled]="loading" class="btn btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Login
                    </button>
                   </div>
                   <div class="col-6">
                       <a href="javascript:void(0)" class="text-info" (click)="resetPassword()">Am uitat parola</a>
                   </div>
                </div>
               
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>
    </div>
</div>