import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '@app/_services/authentication.service';
import { DialogService } from '@app/_services';
import { ForgotPassword } from './forgot.password';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private auth: AuthenticationService,
        private dialog: DialogService
    ) {
        // redirect to home if already logged in
        if (this.auth.currentUserValue) {
            console.log('returnUrl', this.returnUrl)
            if (this.returnUrl)   this.router.navigate([this.returnUrl]);
        }
    }

    ngOnInit() {
        // Resets the login service.  
        // This is one of the events that should cause the refresh.
        this.auth.logout();
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || './dashboard';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        //this.loading = true;
        this.auth.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    console.log('data', data)
                    //this.router.navigate([this.returnUrl]);
                    window.location.reload()
                },
                error => {
                    console.log(error);
                    this.error = "Email sau parola invalida";
                    this.loading = false;
                });
    }

    resetPassword() {
        this.dialog.custom(ForgotPassword, {}, { size: 'md', backdrop: false },).result.then(() => {
            
        })
    }
}