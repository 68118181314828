import { AuthenticationService } from './../_services/authentication.service';
import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Functie, Persoana, Compartiment } from '@app/_models/personal';
import { ApiService } from '@app/_services/api.service';
import { AgGrid } from './../controls/agGrid/agGrid';
import { ColDef } from '@app/controls';




@Component({
    selector: 'personal-zile-concediu',
    templateUrl: './personal.ZileConcediu.html',
})

export class PersonalZileConcediu implements OnInit {
    constructor(public activeModal: NgbActiveModal, private apiService: ApiService,
        private auth: AuthenticationService) { }

    @Input() id;
    @Input() an;
    @Input() zc;
    zce: number = 0; //zile concediu efectuate
    zcr: number = 0; //zile conceoiu ramase
    persoana: Persoana;

    grid: AgGrid;
    listaConcediuLuni

    ngOnInit() {
        this.apiService.fdbGetPersoanaById(this.id).subscribe((d) => {
            this.persoana = d[0];
        });
        this.setupGrid();
        this.getZileConcediu()
    }

    setupGrid() {
        var colDefs: ColDef[] = [
            { headerName: 'Luna', field: 'luna', },
            { headerName: 'Concediu efectuat', field: 'co' },
        ];
        this.grid = new AgGrid(colDefs, false, true);
        this.grid.onDataLoaded = () => {
            //this.gridDetaliu.gridOptions.api.sizeColumnsToFit();
            //var allColumnIds = [];
            // this.grid.gridOptions.columnApi.getAllColumns().forEach(function (column) {
            //     allColumnIds.push(column.getColId());
            // });

            // this.grid.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);
            this.getZileConcediuEfectuat();
        }
        this.grid.onRowSelected = () => {
        }
    }

    getZileConcediu() {
        this.apiService.fdbGetConcediuPersoana(this.id, this.an).then((d) => {
            this.listaConcediuLuni = d;
            this.grid.setDataSource(this.listaConcediuLuni)
        })
    }

    getZileConcediuEfectuat() {
        this.zce = 0;
        this.zcr = 0;
        this.grid.gridOptions.api.forEachNode(node => {
            this.zce += 1 * node.data.co
        });
        this.zcr = this.zc - this.zce
    }

    save() {
        this.apiService.fdbAddZileConcediu(this.id, this.an, this.zc).subscribe(() => {
            console.log('S-a salvat');
            this.activeModal.close()
        });


    }

    isDisabled() {
        var p = this.persoana
        if (!p || !p.s_nume || !p.s_prenume) {
            return true
        } else {
            return false
        }
    }

}
