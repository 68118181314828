import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AuthenticationService } from './_services/authentication.service';
import { User, UserPersonal } from './_models/user';

@Component({
    selector: 'app',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
    currentUser: UserPersonal;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);


    }

    ngOnInit() {
        // asta cica redirectioneaza la acelasi url
        // this.router.routeReuseStrategy.shouldReuseRoute = function () {
        //     return false;
        // };

        // this.router.events.subscribe((evt) => {
        //     if (evt instanceof NavigationEnd) {
        //         this.router.navigated = false;
        //         window.scrollTo(0, 0);
        //     }
        // });
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    
}