<div class="modal-header bg-info text-white">
  <h4 class="modal-title">Compartiment</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="container form-horizontal" #f="ngForm">

    <fieldset *ngIf="compartiment">
      <div class="form-row">
        <div class="col-12">
          <label class="col-form-label">Compartiment</label>
          <input type="text" class="form-control form-control-sm" name="nume" [(ngModel)]="compartiment.s_compartiment">
        </div>
      </div>
      <div class="form-row">
        <div class="col-12">
          <label class="col-form-label">Sef compartiment</label>
          <input type="text" class="form-control form-control-sm" autocomplete="off"
            [(ngModel)]="compartiment.s_idsefcompartiment" name="idsefcompartiment" [typeahead]="utilizatori"
            (selectItem)="setUtilizator($event, 'sefcompartiment')" display="s_numefull" bind="s_idpersonal" [colDef]="colUtilizator"/>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12">
          <label class="col-form-label">Supervizor</label>
          <input type="text" class="form-control form-control-sm" autocomplete="off"
            [(ngModel)]="compartiment.s_idsupervizor" name="idsupervizor" [typeahead]="supervizori"
            (selectItem)="setUtilizator($event, 'supervizor')" display="s_numefull" bind="s_idpersonal" [colDef]="colUtilizator"/>
        </div>
      </div>
    </fieldset>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" (click)="save()" [disabled]="isDisabled()">Salveaza</button>
    <button type=" button" class="btn btn-danger" (click)="activeModal.close('Close click')">Anuleaza</button>
</div>
