import { UtilsService } from './../_services/utils.service';
import { Component, Input, OnInit, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Schimb, ListaCompartimente } from '@app/_models/personal';
import { ApiService } from '@app/_services/api.service';
import { ToastrService } from 'ngx-toastr';
import { AgGrid } from './../controls/agGrid/agGrid';
import { ColDef } from '@app/controls';


@Component({
    selector: 'pontaj-punct-lucru',
    templateUrl: './pontaj.punct.lucru.html',
})

export class PontajPunctLucru implements OnInit {
    constructor(public activeModal: NgbActiveModal, private apiService: ApiService, private utils: UtilsService, private log: ToastrService) { }

    @Input() idpunctlucru;
    @Input() zi;
    @Input() idCompartiment
    @Input() tipzi: string
    @Input() anLuna: number;
    @Input() idpontaj: string;
    @Input() numefull: string;

    dataPontaj
    ziSapt
    listaPuncteLucru
    toataSaptamana: boolean = false;

    ngOnInit() {

        var al = this.anLuna.toString();
        this.dataPontaj = this.zi + "." + al.substring(4, 6) + "." + al.substring(0, 4);
        var dp = new Date(+al.substring(0, 4), +al.substring(4, 6) - 1, this.zi)
        this.ziSapt = dp.getDay();
        this.getListaPuncteLucru()
    }


    getListaPuncteLucru() {
        this.apiService.fdbGetListaPuncteLucru(this.idCompartiment, 'activ', '').then((d) => {
            this.listaPuncteLucru = d;
        })

    }

    isDisabled() {
        return !this.idpunctlucru;
    }
    
    save() {
        if (this.toataSaptamana) {
            this.apiService.fdbSavePunctLucruPontajSapt(this.zi, this.idpontaj, this.idpunctlucru).subscribe(() => {
                this.log.success('S-a salvat punctul de lucru');
                this.activeModal.close()
            });
        } else {
            this.apiService.fdbSavePunctLucruPontajZi(this.zi, this.idpontaj, this.idpunctlucru).subscribe(() => {
                this.log.success('S-a salvat punctul de lucru');
                this.activeModal.close()
            });
        }
    }
    anuleaza() {
        this.activeModal.close()
    }
    sterge() {
        if (this.toataSaptamana) {
            this.apiService.fdbStergPunctLucruSapt(this.zi, this.idpontaj).subscribe(() => {
                this.activeModal.close()
            })
        } else {
            this.apiService.fdbStergPunctLucruZi(this.zi, this.idpontaj).subscribe(() => {
                this.activeModal.close()
            })
        }


    }

}
