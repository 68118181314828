<div class="col-12">
  <div class="alert alert-success col-12">
  <form #f="ngForm">
    <div class="col-12">
      <div class="form-group row">
        <div class="col-6 row">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text"><i class="fa fa-search"></i></div>
            </div>

            <input type="text" class="form-control form-control-sm col-4" name="cauta" [(ngModel)]="searchText"
              (ngModelChange)="getListaCompartimente()">
          </div>
        </div>
        <div class="col-6 text-right">
          <button class="btn btn-sm btn-success" *ngIf="user.s_tipuser == 1" (click)="edit(0)"><i
              class="fa fa-plus-circle"></i>&nbsp;Adauga</button>
        </div>
      </div>
    </div>
  </form>
  </div>
  <div>
    <table class="table table-sm table-striped table-hover table-bordered">
      <thead class="thead-dark">
        <tr>
          <th>Nr.</th>
          <th>&nbsp;</th>
          <th>Compartiment</th>
          <th>Sef compartiment</th>
          <th>Supervizor</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let p of listaCompartimente; index as i">
          <td class="text-right">{{ i+1 }}</td>
          <td class="text-center">
            <button class="btn btn-sm btn-primary" (click)="edit(p.s_idcompartiment)"><i
                class="fa fa-edit"></i></button>
          </td>
          <td><a  [routerLink]="['/pontaj', p.s_idcompartiment]">{{ p.s_compartiment }}</a></td>
          <td>{{ p.s_sefcompartiment }}</td>
          <td>{{ p.s_supervizor }}</td>
          <td class="text-center">
            <a  [routerLink]="['/personal', { idCompartiment: p.s_idcompartiment}]"  class="btn btn-sm btn-warning"><i
                class="fa fa-users"></i>&nbsp;Personal</a>
          </td>
          <td class="text-center">
            <a [routerLink]="['/schimburi', p.s_idcompartiment]" class="btn btn-sm btn-info"><i class="fas fa-business-time"></i>&nbsp;Schimb</a>
          </td>
          <td class="text-center">
            <a [routerLink]="['/echipa', p.s_idcompartiment]" class="btn btn-sm btn-secondary" ><i class="fas fa-user-friends"></i>&nbsp;Echipa</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
