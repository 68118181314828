import { UtilsService } from './../_services/utils.service';
import { Component, Input, OnInit, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Schimb, ListaCompartimente } from '@app/_models/personal';
import { ApiService } from '@app/_services/api.service';
import { isJSDocThisTag } from 'typescript';
import { DYNAMIC_TYPE } from '@angular/compiler';
import { ToastrService } from 'ngx-toastr';



@Component({
    selector: 'set-nota-set',
    templateUrl: './setNotaZi.html',
})

export class SetNotaZi implements OnInit {
    constructor(public activeModal: NgbActiveModal, private apiService: ApiService, private utils: UtilsService, private log: ToastrService) { }

    @Input() zi;
    @Input() idpontaj: string;
    @Input() numefull: string;
    @Input() textNota: string
    dataPontaj: string;


    ngOnInit() {
        var al = this.utils.anLunaPontaj.toString();
        console.log(al)
        this.dataPontaj = this.zi + "." + al.substring(4, 6) + "." + al.substring(0, 4);
        var dp = new Date(+al.substring(0, 4), +al.substring(4, 6) - 1, this.zi)
    }

   


    save() {
        this.apiService.fdbSaveNota(this.zi, this.idpontaj, this.textNota).subscribe(() => {
                this.log.success('S-a salvat nota');
                this.activeModal.close()
        });
    }
   

}
