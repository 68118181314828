import { AgGrid, ColDef } from '@app/controls';
import { AuthenticationService } from '@app/_services/authentication.service';
import { UtilsService } from './../_services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
//import { Schimburidit } from './compartiment.edit';
import { Compartiment, Schimb } from '@app/_models/personal';
import { Component, OnInit, NgModule } from '@angular/core';
import { ApiService } from '@app/_services/api.service';
import { DialogService } from '@app/_services/dialog.service';
import { SchimbEdit } from './schimb.edit';

@Component({
  selector: 'lista-schimb',
  templateUrl: './schimburi.component.html',
  styleUrls: ['./schimburi.component.css']
})
export class SchimburiComponent implements OnInit {

  constructor(private apiService: ApiService, private dialog: DialogService, private activatedRoute: ActivatedRoute, private utils: UtilsService,
    private auth: AuthenticationService, private router: Router) { }

  grid: AgGrid
  idSchimb
  listaSchimburi
  searchText: string = '';
  idCompartiment: string;
  numeCompartiment: string;
  isInactiv: boolean;


  ngOnInit(): void {
    this.auth.getUserLogat.emit(this.auth.currentUserValue)
    localStorage.setItem('curentUrl', this.router.url)
    this.idCompartiment = this.activatedRoute.snapshot.paramMap.get('idCompartiment');
    console.log(this.idCompartiment)
    this.apiService.fdbGetCompartimentById(this.idCompartiment).subscribe((d) => {
      this.numeCompartiment = d[0].s_compartiment;
    });
    this.setupGrid();
    this.getListaSchimburi();
  }

  setupGrid() {
    var colDefs: ColDef[] = [
      { headerName: 'Simbol', field: 's_codprg' },
      { headerName: 'Nume', field: 's_codprg_' },
      { headerName: 'Ora start', field: 's_orastart' },
      { headerName: 'Durata', field: 's_durata' },
      { headerName: 'Ore noapte', field: 's_duratanoapte' },
      { headerName: 'Compartiment', field: 's_compartiment' },
    ];
    this.grid = new AgGrid(colDefs, null, null);
    this.grid.onDataLoaded = () => {
      //this.gridDetaliu.gridOptions.api.sizeColumnsToFit();
      var allColumnIds = [];
      this.grid.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.getColId());
      });

      this.grid.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);

    }

    this.grid.onAddKey = () => { }
    this.grid.onEditKey = () => { }
    this.grid.onDeleteKey = () => { }
    this.grid.onRowDoubleClick = () => {
      this.idSchimb = this.grid.selectedRow.s_idschimb
      this.edit(this.idSchimb);
    }
    this.grid.onRowSelected = () => {
      this.idSchimb = this.grid.selectedRow.s_idschimb
      if (this.grid.selectedRow.s_flag_inactiv == 1) {
        this.isInactiv = true;
      } else {
        this.isInactiv = false;
      }
    }

    this.grid.gridOptions.getRowClass = (node) => {
      if (node.data.s_flag_inactiv == 1) { return 'text-warning' }
    }


  }

  getListaSchimburi() {
    this.grid.clearData()

    this.apiService.fdbGetListaSchimburi(this.idCompartiment, this.searchText).then((d) => {
      this.listaSchimburi = d;
      this.grid.setDataSource(this.listaSchimburi).then(() => {
        this.grid.gridOptions.api.forEachNode(node => {
          if (node.data.s_idschimb == this.idSchimb) {
            node.setSelected(true);
          }
        });
      })
    })

  }

  getTimp(sec) {
    var os = this.utils.getHourMinFromSec(sec);
    return os[2];
  }

  edit(id) {
    this.dialog.custom(SchimbEdit, { id: id, idCompartiment: this.idCompartiment }).result.then(() => {
      this.getListaSchimburi();
    })
  }

  setStatus(id) {
    var numeSchimb = this.grid.selectedRow.s_codprg + ' - ' + this.grid.selectedRow.s_codprg_
    var status = this.grid.selectedRow.s_flag_inactiv
    var msg = status == 0 ? 'Doriti sa dezactivati schimbul\n' + numeSchimb + '?' : 'Doriti sa activati schimbul\n' + numeSchimb + '?';
    this.dialog.confirm({ message: msg, confirmBtn: status == 0 ? 'Dezactiveaza' : 'Activeaza', cancelBtn: 'Anulare' }).then(() => {
      this.apiService.fdbSetStatus('s_schimb', 's_flag_inactiv', 's_idschimb', id, status == 0 ? 1 : 0, 0).subscribe(() => {
        this.getListaSchimburi();
      });
    })
  }

  isDisabled(){
    return !this.grid.selectedRow.s_idschimb
  }

}

