import { EchipaEdit } from './echipa.edit';
import { AuthenticationService } from '@app/_services/authentication.service';
import { UtilsService } from './../_services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Compartiment, Schimb } from '@app/_models/personal';
import { Component, OnInit, NgModule } from '@angular/core';
import { ApiService } from '@app/_services/api.service';
import { DialogService } from '@app/_services/dialog.service';
import { AgGrid, ColDef } from '@app/controls';
// import { SchimbEdit } from './schimb.edit';

@Component({
  selector: 'app-echipa',
  templateUrl: './echipa.component.html',
  styleUrls: ['./echipa.component.css']
})
export class EchipaComponent implements OnInit {

  constructor(private apiService: ApiService, private dialog: DialogService, private activatedRoute: ActivatedRoute, private utils: UtilsService,
    private auth: AuthenticationService, private router: Router) { }

  grid: AgGrid;
  gridPersonal: AgGrid;
  listaEchipe
  listaPersonalEchipa;
  searchText: string = '';
  idCompartiment;
  idEchipa: string = '';
  isInactiv: number;
  numeCompartiment: string = "toate compartimentele";
  echipa: string;
  anLunaPontaj

  ngOnInit(): void {
    this.auth.getUserLogat.emit(this.auth.currentUserValue)
    localStorage.setItem('curentUrl', this.router.url)
    this.anLunaPontaj = this.utils.anLunaPontaj
    this.idCompartiment = this.activatedRoute.snapshot.paramMap.get('idCompartiment') || "";
    if (this.idCompartiment) {
      this.apiService.fdbGetCompartimentById(this.idCompartiment).subscribe((d) => {
        this.numeCompartiment = d[0].s_compartiment;
      });
    }

    this.setupGrid();
    this.getListaEchipe()
    this.setupGridPersonal()
  }

  setupGrid() {
    var colDefs: ColDef[] = [
      { headerName: 'Nume', field: 's_echipa' },
      { headerName: 'Compartiment', field: 's_compartiment' },
    ];
    this.grid = new AgGrid(colDefs, null, null);
    this.grid.onDataLoaded = () => {
      //this.gridDetaliu.gridOptions.api.sizeColumnsToFit();
      var allColumnIds = [];
      this.grid.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.getColId());
      });

      this.grid.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);

    }

    this.grid.onAddKey = () => { }
    this.grid.onEditKey = () => { }
    this.grid.onDeleteKey = () => { }
    this.grid.onRowDoubleClick = () => {
      this.idEchipa = this.grid.selectedRow.s_idEchipa
      this.edit(this.idEchipa);
    }
    this.grid.onRowSelected = () => {
      this.idEchipa = this.grid.selectedRow.s_idechipa
      this.getListaPersonalEchipa();
      this.echipa = this.grid.selectedRow.s_echipa;
    }

    this.grid.gridOptions.getRowClass = (node) => {
      if (node.data.s_inactiv == 1) { return 'text-warning' }
    }
  }

  setupGridPersonal() {
    var colDefs: ColDef[] = [
      { headerName: 'Nume', field: 's_numeprenume' },
      { headerName: 'Functie', field: 's_functie' },
    ];
    this.gridPersonal = new AgGrid(colDefs, null, null);
    this.gridPersonal.onDataLoaded = () => {
      //this.gridDetaliu.gridOptions.api.sizeColumnsToFit();
      var allColumnIds = [];
      this.gridPersonal.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.getColId());
      });

      this.gridPersonal.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);

    }

    this.gridPersonal.onAddKey = () => { }
    this.gridPersonal.onEditKey = () => { }
    this.gridPersonal.onDeleteKey = () => { }
    this.gridPersonal.onRowDoubleClick = () => {
      // this.idEchipa = this.grid.selectedRow.s_idEchipa
      // this.edit(this.idEchipa);
    }
    this.gridPersonal.onRowSelected = () => {
      // this.idEchipa = this.grid.selectedRow.s_idechipa
    }

    this.gridPersonal.gridOptions.getRowClass = (node) => {
      if (node.data.s_inactiv == 1) { return 'text-warning' }
    }
  }

  getListaEchipe() {
    this.apiService.fdbGetListaEchipe(this.idCompartiment, "").subscribe((d) => {
      this.listaEchipe = d;
      this.grid.setDataSource(this.listaEchipe).then(() => {
        this.grid.gridOptions.api.forEachNode(node => {
          if (node.data.s_idechipa == this.idEchipa) {
            node.setSelected(true);
          }
        });
      })

    })
  }

  getListaPersonalEchipa() {
    this.apiService.fdbGetPersoaneForEchipa(this.idEchipa, this.idCompartiment, 1, "").subscribe((d) => {
      this.listaPersonalEchipa = d;
      this.gridPersonal.setDataSource(this.listaPersonalEchipa)
    })
  }


  edit(idEchipa) {
    this.dialog.custom(EchipaEdit, { idEchipa: idEchipa, idCompartiment: this.idCompartiment }, { size: 'lg', backdrop: false },).result.then(() => {
      this.getListaEchipe();
    })
  }

  isDisabled(){
    return !this.grid.selectedRow.s_idechipa
  }
}