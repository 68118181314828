import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@app/_services/api.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/_services';



@Component({
    selector: 'change-password',
    templateUrl: './change.password.html',
})

export class ChangePassword implements OnInit {
    constructor(public activeModal: NgbActiveModal, 
        private apiService: ApiService, 
        private log: ToastrService,  
        private router: Router,
        private auth: AuthenticationService) { }

    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
    isSuccess: boolean = false;
    
    ngOnInit() {

        var generatePassword = (
            length = 12,
            wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
          ) =>
            Array.from(crypto.getRandomValues(new Uint32Array(length)))
              .map((x) => wishlist[x % wishlist.length])
              .join('')
          
          //console.log(generatePassword())
          //console.log(this.auth.currentUserValue.s_idpersonal)
       
    }
    isDisabled(){
        return !this.newPassword || !this.currentPassword || !this.confirmPassword;
    }

    save() {
        var msg = this.isPasswordChange()
        
        if (msg==""){
            this.apiService.fdbVerificaSchimbParola(this.auth.currentUserValue.s_email, this.currentPassword).then((d)=>{
                if (d) {
                    this.apiService.fdbChangePassword(this.auth.currentUserValue.s_email, this.newPassword).then(()=>{
                        this.log.success("Parola a fost schimbata.");
                        this.activeModal.close();
                        this.auth.logout();
                        this.router.navigate(['./login'])
                    })
                }else{
                    this.log.error("Nu s-a schimbat parola");
                }
            })

        }else{
            this.log.error(msg)
        }
      
      }

    
isPasswordChange(){
    var msgErr ="";
    if (this.newPassword != this.confirmPassword) {
        msgErr +="Parola noua si parola confirm nu sunt la fel";
    }
    if (!this.auth.currentUserValue.s_email){
        msgErr +="A survenit o eroare. Nu se poate schimba parola";
    }
    return msgErr;
}




}
