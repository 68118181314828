import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})

export class UtilsService {

  constructor(private http: HttpClient, private api: ApiService) { }


  anLunaPontaj: any = this.getAnLunaPontaj();
  anLunaPontajString: string

  //phpServer = "http://localhost/programator.urbis/api";
  //phpServer = "https://appang.codecloud.ro/planperson/programator.urbis/api"

  luni: any[] = [
    'Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie',
    'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'
  ]

  luni2 = [
    { indexLuna: 0, numarLuna: 1, numeLuna: 'Ianuarie' },
    { indexLuna: 1, numarLuna: 2, numeLuna: 'Februarie' },
    { indexLuna: 2, numarLuna: 3, numeLuna: 'Martie' },
    { indexLuna: 3, numarLuna: 4, numeLuna: 'Aprilie' },
    { indexLuna: 4, numarLuna: 5, numeLuna: 'Mai' },
    { indexLuna: 5, numarLuna: 6, numeLuna: 'Iunie' },
    { indexLuna: 6, numarLuna: 7, numeLuna: 'Iulie' },
    { indexLuna: 7, numarLuna: 8, numeLuna: 'August' },
    { indexLuna: 8, numarLuna: 9, numeLuna: 'Septembrie' },
    { indexLuna: 9, numarLuna: 10, numeLuna: 'Octombrie' },
    { indexLuna: 10, numarLuna: 11, numeLuna: 'Noiembrie' },
    { indexLuna: 11, numarLuna: 12, numeLuna: 'Decembrie' },
  ]

  getPerioadaByIndex(an, indexLuna){
    var anluna = (indexLuna+1) +"/"+an  ;
    for (var i = 0; i<this.luni2.length; i++){
      if (indexLuna == i){
        anluna = this.luni2[i].numeLuna + " " + an;
      }
    }
    return anluna
  }


  getAnLunaPontaj() {
    var alp = localStorage.getItem('anLunaPontaj')
    console.log('anLunaPontaj', alp)
    if (!alp) {
      this.anLunaPontaj = this.getAnlunaFromDate(new Date())

    } else {
      this.anLunaPontaj = alp;
    }
  }

  getSeconds(h, m) {
    return h * 3600 + m * 60;
  }

  getHourMinFromSec(sec, onlyStr?: boolean, hms?: boolean): any[] | string {
    // char daca intoarce string, pnetru afisare
    var arrTime = []
    var h = Math.floor(+(sec / 3600))
    var m = Math.floor(+(sec / 60) % 60)
    var s = Math.floor(+(((sec % 86400) % 3600)) % 60);
    /*
    2hr+30min×1hr60min+0s×1hr3600s
    */
    var hdec = h + m * 1 / 60 + s * 1 / 3600;


    var strH = h.toString()
    var strM = m.toString()
    var strTime = (strH.length == 1 && onlyStr ? '0' + strH : strH) + ':' + (strM.length == 1 ? '0' + strM : strM);
    arrTime[0] = h;
    arrTime[1] = m
    arrTime[2] = strTime
    arrTime[3] = hdec > Math.floor(hdec) ? hdec.toFixed(2) : hdec.toFixed(0)
    if (onlyStr) {
      return strTime
    } else {
      return arrTime

    }
  }

  getAnlunaFromDate(datacur: Date) {
    var y = datacur.getFullYear()
    var m = datacur.getMonth() + 1
    return y * 100 + m;
  }

  getAnLunaExtended(datacur?: Date) {
    // intoarce un obiect cu 4 proprietati:
    //datacur = 23-04-2021
    //indexLuna: 3, numarLuna: 4, numeLuna: 'Aprilie', an: 2021
    console.log(this.luni)
    if (!datacur) datacur = new Date()
    var an = datacur.getFullYear()
    var numarLuna = datacur.getMonth() + 1
    var indexLuna = datacur.getMonth()
    var numeLuna = this.luni[indexLuna]
    var anluna = an * 100 + numarLuna
    var codper = an / 100
    return { indexLuna: indexLuna, numarLuna: numarLuna, numeLuna: numeLuna, an: an, anLuna: anluna, codper: codper }
  }

  getAlExtendedFromAnLuna(anLuna: number) {
    // intoarce un obiect cu 4 proprietati:
    // anluna = 202104
    //indexLuna: 3, numarLuna: 4, numeLuna: 'Aprilie', an: 2021

    var an = (anLuna / 100).toFixed(0)
    var numarLuna = anLuna - parseInt(an) * 100
    var indexLuna = numarLuna - 1
    var numeLuna = this.luni[indexLuna]
    return { indexLuna: indexLuna, numarLuna: numarLuna, numeLuna: numeLuna, an: an, anLuna: anLuna }
  }


  getZileInLuna(an, indexLuna) {
    var d = new Date(an, indexLuna+1, 0)
    console.log('d', d)
    return d.getDate();
  }

  getDow(zi, an, indexLuna) {
    // zile saptamana fara sarbatori legale
    var dd = new Date(an, indexLuna, zi)
    var z = ""
    switch (dd.getDay()) {
      case 0: z = "D"; break;
      case 6: z = "S"; break;
      default: z = "LV"; break;
    }
    return z;
  }


  getDowSL(zi, an, indexLuna, arrSl) {
    // zile saptamana cu sarbatori legale
    var dd = new Date(an, indexLuna, zi)
    var z = ""
    switch (dd.getDay()) {
      case 0: z = "D"; break;
      case 6: z = "S"; break;
      default: z = "LV"; break;
    }
    for (var i = 0; i < arrSl.length; i++) {
      if (dd.getDate() == arrSl[i].s_zsl) {
        z = z + "SL"
      }
    }
    return z;
  }

  getZiSaptamana(zi, an, luna) {
    var dd = new Date(an, luna, zi)
    var z = ""
    switch (dd.getDay()) {
      case 0: z = "DUM"; break;
      case 1: z = "LUN"; break;
      case 2: z = "MAR"; break;
      case 3: z = "MIE"; break;
      case 4: z = "JOI"; break;
      case 5: z = "VIN"; break;
      case 6: z = "SAM"; break;
    }
    return z;
  }

  getZileLucratoareLuna(an, indexLuna) {
    console.log(an, indexLuna)

    var zileInLuna = this.getZileInLuna(an, indexLuna);
    console.log('zileInLuna', zileInLuna)
    var zileLucratoare = 0;
    for (var i = 1; i <= zileInLuna; i++) {
      var dd = new Date(an, indexLuna, i)
      console.log(dd)
      if (dd.getDay() > 0 && dd.getDay() < 6) {
        zileLucratoare += 1;
      }
    }

    return zileLucratoare;

  }

  getSl(an, numarLuna) {
    return this.api.fdbGetSarbatoriLegale(an, numarLuna).then((result) => {
      return result;
    })

  }

  getAnlunaPrecedenta(anLuna: number) {
    //anLuna e in format numeric aaaall
    var aa = parseInt((anLuna / 100).toFixed(0))
    console.log(aa)
    var ll = (anLuna - aa * 100)
    var llprec = ll - 1
    if (llprec == 0) {
      llprec = 12;
      aa -= 1;
    }

    return (aa * 100 + llprec);

  }

  pickerToDate(arrDate) {
    var y = 0;
    var m = 0;
    var d = 0;
    for (var name in arrDate) {
      // name is the name of each property, so:
      //console.log(name+ " = " arrDate['name'])
      if (name == 'year') y = arrDate[name];
      if (name == 'month') m = arrDate[name];
      if (name == 'day') d = arrDate[name];
    }
    var mm = m < 10 ? "0" + m : m;
    var dd = d < 10 ? "0" + d : d;

    return y + "-" + mm + "-" + dd;

  }

}
