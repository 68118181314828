import { AuthenticationService } from '@app/_services/authentication.service';
import { UtilsService } from './../_services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Compartiment, Schimb } from '@app/_models/personal';
import { Component, OnInit, NgModule } from '@angular/core';
import { ApiService } from '@app/_services/api.service';
import { DialogService } from '@app/_services/dialog.service';
import { AgGrid, ColDef } from '@app/controls';
import { PunctlucruEdit } from './punct-lucru.edit';

@Component({
  selector: 'app-punct-lucru',
  templateUrl: './punct-lucru.component.html',
  styleUrls: ['./punct-lucru.component.css']
})
export class PunctLucruComponent implements OnInit {

  constructor(private apiService: ApiService, private dialog: DialogService, private activatedRoute: ActivatedRoute, private utils: UtilsService,
    private auth: AuthenticationService, private router: Router) { }

  grid: AgGrid;
  gridPersonal: AgGrid;
  listaPuncteLucru
  searchText: string = '';
  idCompartiment;
  idPunctLucru: string = '';
  punctlucru: string='';
  isInactiv: boolean=false;
  numeCompartiment: string = "toate compartimentele";
  echipa: string;
  anLunaPontaj

  ngOnInit(): void {
    this.auth.getUserLogat.emit(this.auth.currentUserValue)
    localStorage.setItem('curentUrl', this.router.url)
    this.anLunaPontaj = this.utils.anLunaPontaj
    this.idCompartiment = this.activatedRoute.snapshot.paramMap.get('idCompartiment') || "";
    if (this.idCompartiment) {
      this.apiService.fdbGetCompartimentById(this.idCompartiment).subscribe((d) => {
        this.numeCompartiment = d[0].s_compartiment;
      });
    }

    this.setupGrid();
    this.getListaPuncteLucru()
  }

  setupGrid() {
    var colDefs: ColDef[] = [
      { headerName: 'Nume', field: 's_punctlucru' },
      { headerName: 'Compartiment', field: 's_compartiment' },
    ];
    this.grid = new AgGrid(colDefs, null, null);
    this.grid.onDataLoaded = () => {
      //this.gridDetaliu.gridOptions.api.sizeColumnsToFit();
      var allColumnIds = [];
      this.grid.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.getColId());
      });

      this.grid.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);

    }

    this.grid.onAddKey = () => { }
    this.grid.onEditKey = () => { }
    this.grid.onDeleteKey = () => { }
    this.grid.onRowDoubleClick = () => {
      this.idPunctLucru = this.grid.selectedRow.s_idPunctLucru
      this.edit(this.idPunctLucru);
    }
    this.grid.onRowSelected = () => {
      this.idPunctLucru = this.grid.selectedRow.s_idpunctlucru
      this.punctlucru = this.grid.selectedRow.s_punctlucru;
      if (this.grid.selectedRow.s_flag_inactiv == 1) {
        this.isInactiv = true;
      } else {
        this.isInactiv = false;
      }
    }

    this.grid.gridOptions.getRowClass = (node) => {
      if (node.data.s_flag_inactiv == 1) { return 'text-warning' }
    }
  }


  getListaPuncteLucru() {
    this.apiService.fdbGetListaPuncteLucru(this.idCompartiment, this.searchText).then((d) => {
      this.listaPuncteLucru = d;
      this.grid.setDataSource(this.listaPuncteLucru).then(() => {
        this.grid.gridOptions.api.forEachNode(node => {
          if (node.data.s_idechipa == this.idPunctLucru) {
            node.setSelected(true);
          }
        });
      })

    })
  }

  
  edit(idPunctLucru) {
    this.dialog.custom(PunctlucruEdit, { idPunctLucru: idPunctLucru, idCompartiment: this.idCompartiment }, { size: 'md', backdrop: false },).result.then(() => {
      this.getListaPuncteLucru();
    })
  }

  setStatus(id) {
    var status = this.grid.selectedRow.s_flag_inactiv
    var msg = status == 0 ? 'Doriti sa dezactivati punctul de lucru\n' + this.punctlucru + '?' : 'Doriti sa activati punctul de lucru\n' + this.punctlucru + '?';
    this.dialog.confirm({ message: msg, confirmBtn: status == 0 ? 'Dezactiveaza' : 'Activeaza', cancelBtn: 'Anulare' }).then(() => {
      this.apiService.fdbSetStatus('s_punctlucru', 's_flag_inactiv', 's_idpunctlucru', id, status == 0 ? 1 : 0, 0).subscribe(() => {
        this.getListaPuncteLucru();
      });
    })
  }

  isDisabled(){
    return !this.grid.selectedRow.s_idpunctlucru
  }
}


