<div class="modal-header bg-info text-white">
  <h4 class="modal-title">{{dataPontaj}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="container form-horizontal" #f="ngForm">
    <h5>{{numefull}}</h5>
    <fieldset *ngIf="listaOptiuniPontaj">
      <div class="form-row col-12">
        <div class="col-6">
          <label class="col-form-label">Pontaj</label>
          <select class="form-select form-select-sm" name="s_idcompartiment" [(ngModel)]="idschimb" (blur)="setDurata()"
            (change)="setDurata()">
            <option *ngFor="let p of listaOptiuniPontaj" [value]="p.s_idschimb">{{p.s_codprg + " - " + p.s_codprg_}}
            </option>
          </select>
        </div>
        <div class="col-6">
          <label class="col-form-label">Ore suplimentare</label>
          <div class="input-group input-group-sm">
            <input type="number" class="form-control form-control-sm col" name="durataSuplHH" [(ngModel)]="durataSuplHH"
              min="0" max="23" [disabled]="isschimb == 'A'">
            <div class="input-group-append">
              <span class="input-group-text" id="inputGroup-sizing-sm">:</span>
            </div>
            <input type="number" class="form-control form-control-sm col" name="durataSuplMM" [(ngModel)]="durataSuplMM"
              min="0" max="59" [disabled]="isschimb == 'A'">
          </div>
        </div>
      </div>
      <div class="form-row col-12">
        <div class="col-6">
          <label class="col-form-label">Durata totala (HH:MM)</label>
          <div class="input-group input-group-sm">
            <input type="number" class="form-control form-control-sm col" name="durataHH" [(ngModel)]="durataHH" min="0"
              max="23" [disabled]="iseditabil == 0">
            <div class="input-group-append">
              <span class="input-group-text" id="inputGroup-sizing-sm">:</span>
            </div>
            <input type="number" class="form-control form-control-sm col" name="durataMM" [(ngModel)]="durataMM" min="0"
              max="59" [disabled]="iseditabil == 0">
          </div>
        </div>
        <div class="col-6">
          <label class="col-form-label">Ore noapte (HH:MM)</label>
          <div class="input-group input-group-sm">
            <input type="number" class="form-control form-control-sm col" name="oreNoapteHH" [(ngModel)]="oreNoapteHH"
              min="0" max="23" [disabled]="isorenoapte == 0">
            <div class="input-group-append">
              <span class="input-group-text" id="inputGroup-sizing-sm">:</span>
            </div>
            <input type="number" class="form-control form-control-sm col" name="oreNoapteMM" [(ngModel)]="oreNoapteMM"
              min="0" max="59" [disabled]="isorenoapte == 0">
          </div>
        </div>
      </div>
      <br />
      <div class="form-row col-12">
        <div class="col-6">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="toataSaptamana" id="toataSaptamana"
              name="toataSaptamana" [disabled]="ziSapt != 1">
            <label class="form-check-label" for="toataSaptamana">
              Toata saptamana
            </label>
          </div>
        </div>
        <div class="col-6">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="toataEchipa" id="toataEchipa"
              name="toataEchipa" [disabled]="!idechipa">
            <label class="form-check-label" for="toataEchipa">
              Toata echipa
            </label>
          </div>
        </div>
      </div>

    </fieldset>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning mr-auto" (click)="sterge()" *ngIf="!isNou">Sterge</button>
  <button type="button" class="btn btn-success" (click)="save()"
    [disabled]="!idschimb||!isschimb">Salveaza</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Anuleaza</button>
</div>