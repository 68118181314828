import { EchipaComponent } from './echipa/echipa.component';
import { PontajComponent } from './pontaj/pontaj.component';
import { SchimburiComponent } from './schimburi/schimburi.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_helpers/auth.guard';
import { PersonalComponent } from './personal/personal.component';
import { CompartimentComponent } from './compartiment/compartiment.component';
import { UtilizatoriComponent } from './utilizatori/utilizatori.component';
import { SetariComponent } from './setari/setari.component';
import { SarbatoriLegaleComponent } from './setari/sarbatori-legale/sarbatori-legale.component';
import { ConcediiComponent } from './concedii/concedii.component';
import { PunctLucruComponent } from './punct-lucru/punct-lucru.component';

const routes: Routes = [
    { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'personal', component: PersonalComponent, canActivate: [AuthGuard] },
    { path: 'personal/:idCompartiment', component: PersonalComponent, canActivate: [AuthGuard] },
    { path: 'compartiment', component: CompartimentComponent, canActivate: [AuthGuard] },
    { path: 'schimburi/:idCompartiment', component: SchimburiComponent, canActivate: [AuthGuard] },
    { path: 'echipa/:idCompartiment', component: EchipaComponent, canActivate: [AuthGuard] },
    { path: 'pontaj/:idCompartiment', component: PontajComponent, canActivate: [AuthGuard] },
    { path: 'utilizatori', component: UtilizatoriComponent, canActivate: [AuthGuard] },
    { path: 'setari', component: SetariComponent, canActivate: [AuthGuard] },
    { path: 'sarbatori-legale', component: SarbatoriLegaleComponent, canActivate: [AuthGuard] },
    { path: 'concedii', component: ConcediiComponent, canActivate: [AuthGuard] },
    { path: 'concedii/:idCompartiment', component: ConcediiComponent, canActivate: [AuthGuard] },
    { path: 'punct-lucru/:idCompartiment', component: PunctLucruComponent, canActivate: [AuthGuard] },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const appRoutingModule = RouterModule.forRoot(routes);