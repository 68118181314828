import { AuthenticationService } from './../_services/authentication.service';
import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@app/_services/api.service';
import * as dayjs from 'dayjs';
 


@Component({
    selector: 'personal-status',
    templateUrl: './personal.status.html',
})

export class PersonalStatus implements OnInit {
    constructor(public activeModal: NgbActiveModal, private apiService: ApiService,
        private auth: AuthenticationService) { }

    @Input() id;
    @Input() numePersoana;
    @Input() statusCurent;
    dataInactiv
    

    ngOnInit() {
        var d = new Date()
        this.dataInactiv = d

    }

    

    save() {
        // this.apiService.fdbAddPersonal(this.persoana).subscribe(() => {
        //     console.log('S-a salvat');
        //     this.activeModal.close()
        // });
       

    }

    isDisabled(){
        // var p = this.persoana
        // if (!p.s_nume || !p.s_prenume){
        //     return true
        // }else{
        //     return false
        // }
    }

}
