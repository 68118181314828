<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" class="navbar-brand"><img src="./assets/logo/logo.png"/></a>
    </div>
    <div class="navbar-header">
      <a href="javascript:void(0)" class="{{btnClass}}" (click)="editAnLunaPontaj()" *ngIf="userLogat"><i class="fas fa-calendar"></i>&nbsp;{{anLunaString}}</a>
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" *ngIf="userLogat">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="userLogat">
      <ul class="nav navbar-nav">
        <li><a class="nav-link" [routerLink]="['/personal']" name="personal">Personal</a></li>
        <li><a class="nav-link" [routerLink]="['/compartiment']" name="compartiment">Compartimente</a></li>
        <li *ngIf="isAdmin"><a class="nav-link" [routerLink]="['/utilizatori']" name="utilizatori">Utilizatori</a></li>
        <li *ngIf="isAdmin"><a class="nav-link" [routerLink]="['/setari']" name="setari">Setari</a></li>
      </ul>
      <ul class="navbar-nav ms-auto" *ngIf="userLogat">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuContulMeu"
            data-bs-toggle="dropdown">{{userLogat.s_email}}</a>
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuContulMeu">
            <a class="dropdown-item" [routerLink]="['/dashboard', {id: rndString}]" name="dashboard">Contul meu</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#" (click)="logout()">Logout</a>
          </div>
        </li>
      </ul>
      
    </div>
    <div class="navbar-nav" *ngIf="!userLogat">
      <a href="#" class="nav nav-link" (click)="clearls()"><i class="fa fa-user"></i></a>
    </div>
  </div>
</nav>
<br />