<div class="modal-header bg-info text-white">
    <h4 class="modal-title">Personal</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="container form-horizontal" #f="ngForm">
  
      <fieldset *ngIf="persoana">
        <div class="form-row">
          <div class="col-6">
            <label class="col-form-label">Nume</label>
            <input type="text" class="form-control form-control-sm" name="nume" [(ngModel)]="persoana.s_nume">
          </div>
          <div class="col-6">
            <label class="col-form-label">Prenume</label>
            <input type="text" class="form-control form-control-sm" name="prenume" [(ngModel)]="persoana.s_prenume">
          </div>
        </div>
        <div class="form-row">
          <div class="col-6">
            <label class="col-form-label">CNP</label>
            <input type="text" class="form-control form-control-sm" name="cnp" [(ngModel)]="persoana.s_cnp">
          </div>
          <div class="col-6">
            <label class="col-form-label">Marca</label>
            <input type="text" class="form-control form-control-sm" name="marca" [(ngModel)]="persoana.s_marca">
          </div>
        </div>
        <div class="form-row">
          <div class="col-12">
            <label class="col-form-label">Functie</label>
            <select class="form-select form-select-sm" name="idFunctie" [(ngModel)]="persoana.s_idfunctie">
              <option *ngFor="let f of listaFunctii" [value]="f.s_idfunctie">{{f.s_functie}}</option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="col-12">
            <label class="col-form-label">Compartiment</label>
            <select class="form-select form-select-sm" name="idCompartiment" [(ngModel)]="persoana.s_idcompartiment">
              <option *ngFor="let f of listaCompartimente" [value]="f.s_idcompartiment">{{f.s_compartiment}}</option>
            </select>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="save()" [disabled]="isDisabled()">Salveaza</button>
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Anuleaza</button>
  </div>
  