import { ToastrService } from 'ngx-toastr';
import { AgGridColumnSize } from './../controls/agGrid/agGridController';
import { AgGrid } from './../controls/agGrid/agGrid';
import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Functie, Compartiment } from '@app/_models/personal';
import { ApiService } from '@app/_services/api.service';
import { UserPersonal } from '@app/_models';
import { ColDef } from '@app/controls';



@Component({
    selector: 'echipa-edit',
    templateUrl: './echipa.edit.html',
})

export class EchipaEdit implements OnInit {
    constructor(public activeModal: NgbActiveModal, private apiService: ApiService, private log: ToastrService) { }

    @Input() idEchipa;
    @Input() idCompartiment;
    grid: AgGrid
    echipa: any;
    listaFunctii: Functie[];
    listaCompartimente
    listaPersoaneDisponibile


    ngOnInit() {
        if (this.idEchipa == "") {
            this.echipa = {
                s_ide: 0, s_idechipa: "", s_echipa: "", s_idcompartiment: "", s_stamp: new Date()
            }
            console.log(this.echipa.s_idechipa)
        } else {
            this.getEchipa()
        }

        this.getPersoaneForEchipa();
        this.setupGrid()
    }

    setupGrid() {
        var colDefs: ColDef[] = [
            { headerName: 'Nume', field: 's_numeprenume', checkboxSelection: true, headerCheckboxSelection: true, },
            { headerName: 'Functie', field: 's_functie' },
        ];
        this.grid = new AgGrid(colDefs, false, true);
        this.grid.onDataLoaded = () => {
            //this.gridDetaliu.gridOptions.api.sizeColumnsToFit();
            var allColumnIds = [];
            this.grid.gridOptions.columnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.getColId());
            });

            this.grid.gridOptions.api.getRenderedNodes().forEach(function (node:any) {
                if (node.data.s_idechipa) {
                    node.setSelected(true);
                }
            });

            this.grid.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);
        }
        this.grid.onRowSelected = () => {
        }
    }

    getEchipa() {
        this.apiService.fdbGetElementById('s_echipa', 's_idechipa', this.idEchipa, 1).subscribe((d) => {
            this.echipa = d[0];
            this.getPersoaneForEchipa();
        })
    }

    getPersoaneForEchipa() {
        this.apiService.fdbGetPersoaneForEchipa(this.idEchipa, this.idCompartiment, 2, "").subscribe((d) => {
            this.listaPersoaneDisponibile = d;
            this.grid.setDataSource(this.listaPersoaneDisponibile)
        })
    }

    getFunctii() {
        this.apiService.fdbGetFunctii('').subscribe((d) => {
            this.listaFunctii = d;
        })
    }

    isDisabled(){
        var p = this.echipa
        if (!p.s_echipa){
             return true
        }else{
             return false
        }
    }

    save() {
        var msgErr = ""
        var listaIds = "";
        const ids = this.grid.getSelectedRows().map(r => r.s_idpersonal);
        console.log(ids);
        for (var i = 0; i < ids.length; i++) {
            listaIds += "'" + ids[i] + "',";
        }
        listaIds = listaIds.slice(0, -1);

        this.apiService.fdbAddEchipa(this.echipa.s_idechipa, this.echipa.s_echipa, this.idCompartiment).subscribe((idNou) => {
            this.log.success('Echipa salvata');
            console.log('d', idNou)
            this.apiService.fdbSetPersoaneEchipa(idNou, listaIds).subscribe(() => {
                this.log.success('Persoanele au fost repartizate in echipa');
                this.activeModal.close();
            })
        });

    }
}
