<!-- NAV TABS-->
<div class="col-12">
    <ul class="nav nav-tabs">
        <li class="nav-item">
            <!-- <a class="nav-link active" aria-current="page" href="#">Active</a> -->
            <a [routerLink]="['/sarbatori-legale']" class="nav-link">Zile sarbatori legale {{an}}</a>
        </li>
    </ul>
</div>
<div class="row">
    <div class="col-4">
        <ag-grid-angular style="height: 70vh;" class="ag-theme-alpine" [gridOptions]="grid.gridOptions">
        </ag-grid-angular>
    </div>
    <div class="col-8">
        <form class="container form-horizontal" #f="ngForm">

            <fieldset>
                <div class="form-row">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <div class="input-group">
                                <input class="form-control" placeholder="dd.mm.yyyy" name="dataZsl"
                                    [(ngModel)]="dataZsl" ngbDatepicker #di="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn" (click)="di.toggle()" type="button"><i class="fa fa-calendar"
                                            aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <button type="button" class="btn btn-success" (click)="addZsl()"
                            [disabled]="isDisabled('add')">Adauga sarbatoare legala</button>
                    </div>

                </div>
                <div class="form-row">
                    <button type="button" class="btn btn-danger" (click)="delZsl()" [disabled]="isDisabled('del')">Sterge sarbatoare legala</button>

                </div>
            </fieldset>
        </form>
    </div>
</div>