<div class="col-12">

    <h4>Concedii {{numeCompartiment}}</h4>
    <div class="alert alert-primary col-12">
        <div class="form-group row">
          <div class="col-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-search"></i></div>
              </div>
              <input type="text" class="form-control form-control-sm" name="cauta" [(ngModel)]="searchText"
                (ngModelChange)="afiseazaConcedii()">
            </div>
          </div>
          <div class="col-3">
            <button class="btn btn-sm btn-success ml-1" (click)="exportExcel()" [disabled]="!listaPersonalConcediu"><i
                class="far fa-file-excel"></i>&nbsp;Excel</button>
            <button class="btn btn-sm btn-dark ml-1" (click)="printConcedii()" [disabled]="!listaPersonalConcediu"><i
                  class="fa fa-print"></i>&nbsp;Print</button>
          </div>
  
        </div>
        
      </div>

    <!-- NAV TABS-->
    <div class="col-12" *ngIf="idCompartiment">
        <navigaretab></navigaretab>
    </div>


    <div id="div-pontaj">
        <table class="mytable table table-sm table-hover table-bordered" id="tblPontaj">
            <thead>
                <tr>
                    <th colspan="16" class="bg-light text-center">
                        {{titluTabel}}
                    </th>
                </tr>
                <tr>
                    <th class="D">Nr.</th>
                    <th class="D"><a href="javascript:void(0)" (click)="ordoneazaNume('s_nume')"
                            ngbTooltip="Ordoneaza dupa nume">Nume</a></th>
                    <th class="D">Marca</th>
                    <th class="D" *ngIf="!isPrint"><a href="javascript:void(0)" (click)="ordoneazaNume('s_echipa')"
                            ngbTooltip="Ordoneaza dupa echipa">Echipa</a></th>
                    <th class="D">CO<br/>cuvenit<br/>{{anPontaj}}</th>
                    <th class="D">CO<br/>efectuat<br/>{{anlunaPontajString}}</th>
                    <th class="D">CO<br/>ramas<br/>{{anPontaj}}</th>
                    <th class="D">Ian</th>
                    <th class="D">Feb</th>
                    <th class="D">Mar</th>
                    <th class="D">Apr</th>
                    <th class="D">Mai</th>
                    <th class="D">Iun</th>
                    <th class="D">Iul</th>
                    <th class="D">Aug</th>
                    <th class="D">Sep</th>
                    <th class="D">Oct</th>
                    <th class="D">Nov</th>
                    <th class="D">Dec</th>
                    <th class="CO">CO<br/>programat<br/>{{anPontaj}}</th>
            </thead>
            <tbody>
                <tr *ngFor="let p of listaPersonalConcediu; index as i" draggable="true" (dragover)="allowDrop($event)"
                    (dragstart)="onDragStart(i)" (drop)="onDrop($event, i)">
                    <td class="LV text-right">{{ i+1 }}</td>
                    <td class="LV" style="text-align: left;">{{ p.s_numefull }}</td>
                    <td class="LV" style="text-align: left;">{{ p.s_marca}}</td>
                    <td class="LV" style="text-align: left;" *ngIf="!isPrint">{{ p.s_echipa }}</td>
                    <td class="LV">{{p.s_zc}}</td>
                    <td class="LV">{{p.s_zcefectuat}}</td>
                    <td class="LV">{{p.s_zcramas}}</td>
                    <td class="LV">{{p.co_ian}}</td>
                    <td class="LV">{{p.co_feb}}</td>
                    <td class="LV">{{p.co_mar}}</td>
                    <td class="LV">{{p.co_apr}}</td>
                    <td class="LV">{{p.co_mai}}</td>
                    <td class="LV">{{p.co_iun}}</td>
                    <td class="LV">{{p.co_iul}}</td>
                    <td class="LV">{{p.co_aug}}</td>
                    <td class="LV">{{p.co_sep}}</td>
                    <td class="LV">{{p.co_oct}}</td>
                    <td class="LV">{{p.co_nov}}</td>
                    <td class="LV">{{p.co_dec}}</td>
                    <td class="CO">{{p.s_zcprogramat}}</td>
                </tr>
                <tr>
                    <td [attr.colspan]="isPrint ? '3' : '4'" class="CO">
                        <strong>TOTAL</strong>
                    </td>
                    <td class="CO"><strong>&nbsp;</strong></td>
                    <td class="CO"><strong>&nbsp;</strong></td>
                    <td class="CO"><strong>&nbsp;</strong></td>
                    <td class="CO"><strong>{{total.ian}}</strong></td>
                    <td class="CO"><strong>{{total.feb}}</strong></td>
                    <td class="CO"><strong>{{total.mar}}</strong></td>
                    <td class="CO"><strong>{{total.apr}}</strong></td>
                    <td class="CO"><strong>{{total.mai}}</strong></td>
                    <td class="CO"><strong>{{total.iun}}</strong></td>
                    <td class="CO"><strong>{{total.iul}}</strong></td>
                    <td class="CO"><strong>{{total.aug}}</strong></td>
                    <td class="CO"><strong>{{total.sep}}</strong></td>
                    <td class="CO"><strong>{{total.oct}}</strong></td>
                    <td class="CO"><strong>{{total.nov}}</strong></td>
                    <td class="CO"><strong>{{total.dec}}</strong></td>
                    <td class="CO"><strong>&nbsp;</strong></td>

                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="4" class="pontaj-footer">
                        <div class="col-12">
                            {{dataIntocmit}}
                        </div>
                    </td>
                    <td colspan="12" class="pontaj-footer">
                        <div class="col-12 text-center">
                            <div>{{intocmit.s_tipuser == 3 ? 'Printat' : 'Intocmit'}}</div>
                            <div *ngIf="intocmit.s_functie">{{intocmit.s_functie}}</div>
                            {{intocmit.s_numeprenume}}
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>



    </div>