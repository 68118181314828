import { ApiService } from './api.service';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserPersonal } from '@app/_models/user';
import { Router } from '@angular/router';
import { AppConfiguration } from './app-config.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<UserPersonal>;
  public currentUser: Observable<UserPersonal>;

  constructor(private http: HttpClient, private apiService: ApiService, private router: Router, private appcfg: AppConfiguration) {
    this.currentUserSubject = new BehaviorSubject<UserPersonal>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  @Output() getUserLogat: EventEmitter<any> = new EventEmitter();


  authUser = { s_email: '', s_pwd: '' }
  updateCont = { user: '', nume: '', prenume: '', email: '' }

  // PHP_API_SERVER = "http://localhost/programator.urbis/api";
  // PHP_API_SERVER = this.utils.phpServer;

  public get currentUserValue(): UserPersonal {
    var u = this.currentUserSubject.value
    //console.log('u', u)
    return u;
  }

  get PHP_API_SERVER() {
    return this.appcfg.apiUrl
  }



  login(username: string, password: string) {
    this.authUser.s_pwd = password;
    this.authUser.s_email = username;
    //console.log(this.authUser)
    return this.http.post<UserPersonal>(`${this.PHP_API_SERVER}/userauth.php?op=fdbGetUserAuth`, this.authUser)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.getUserLogat.emit(user);
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('curentUrl');
    this.currentUserSubject.next(null);
  }

  updatecont(userModificat) {
    console.log('userModificat', userModificat)
    return this.http.post<UserPersonal>(`${this.PHP_API_SERVER}/userauth.php?op=fdbUpdateCont`, userModificat);
  }

}