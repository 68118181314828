import { AuthenticationService } from './../_services/authentication.service';
import { UtilsService } from './../_services/utils.service';
import { CompartimentEdit } from './compartiment.edit';
import { Compartiment } from '@app/_models/personal';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/_services/api.service';
import { DialogService } from '@app/_services/dialog.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'lista-compartimente',
  templateUrl: './compartiment.component.html',
  styleUrls: ['./compartiment.component.css']
})
export class CompartimentComponent implements OnInit {

  constructor(private apiService: ApiService, private dialog: DialogService, private activatedRoute: ActivatedRoute, private router: Router,
    private utils: UtilsService, private auth: AuthenticationService) { 

      // this.router.onSameUrlNavigation = 'reload';
      // this.router.navigateByUrl(this.router.url).then(() => {
      
      //     this.router.onSameUrlNavigation = 'reload';
      
      // });
    }

  listaCompartimente 
  searchText: string = '';
  anLuna
  user


  ngOnInit(): void {
    localStorage.setItem('curentUrl', this.router.url)
    this.auth.getUserLogat.emit(this.auth.currentUserValue)
    this.user = this.auth.currentUserValue
    this.getListaCompartimente()
    this.anLuna = this.utils.anLunaPontaj
  }

  getListaCompartimente() {
    this.apiService.fdbGetListaCompartimente(this.user.s_idpersonal, this.user.s_tipuser, this.searchText).subscribe((d) => {
      this.listaCompartimente = d;
    })

  }

  edit(id) {
      this.dialog.custom(CompartimentEdit, { id: id }).result.then(() => {
         this.getListaCompartimente();
    })
  }

  


}
