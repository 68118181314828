import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { User } from '@app/_models/user';
import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from './app-config.service';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient, private appcfg: AppConfiguration) { }

    // PHP_API_SERVER = "http://localhost/programator.urbis/api";
    //PHP_API_SERVER = this.utils.phpServer;

    get PHP_API_SERVER(){
        return this.appcfg.apiUrl;
    }

    getAll() {
        return this.http.get<User[]>(`${this.PHP_API_SERVER}/userauth.php?op=fdbGetAllUsers`);
    }
}