import { AuthenticationService } from './../_services/authentication.service';
import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Functie, Persoana, Compartiment } from '@app/_models/personal';
import { ApiService } from '@app/_services/api.service';



@Component({
    selector: 'personal-edit',
    templateUrl: './personal.edit.html',
})

export class PersonalEdit implements OnInit {
    constructor(public activeModal: NgbActiveModal, private apiService: ApiService,
        private auth: AuthenticationService) { }

    @Input() id;
    @Input() idCompartiment;
    persoana: Persoana;
    listaFunctii: Functie[];
    listaCompartimente: Compartiment[]

    ngOnInit() {
        if (this.id == 0) {
            this.persoana = new Persoana;
            this.persoana.s_idpersonal = '';
            this.persoana.s_inactiv=0;
            this.persoana.s_idcompartiment = this.idCompartiment
            console.log(this.persoana)
        } else {
            this.apiService.fdbGetPersoanaById(this.id).subscribe((d) => {
                this.persoana = d[0];
            })
        }

        this.getFunctii();
        this.getCompartimente()

    }

    getFunctii() {
        this.apiService.fdbGetFunctii('').subscribe((d) => {
            this.listaFunctii = d;
        })
    }

    getCompartimente() {
        this.apiService.fdbGetListaCompartimente(this.auth.currentUserValue.s_idpersonal, this.auth.currentUserValue.s_tipuser, '').subscribe((d) => {
            this.listaCompartimente = d;
        })
    }

    save() {
        this.apiService.fdbAddPersonal(this.persoana).subscribe(() => {
            console.log('S-a salvat');
            this.activeModal.close()
        });
       

    }

    isDisabled(){
        var p = this.persoana
        if (!p.s_nume || !p.s_prenume){
            return true
        }else{
            return false
        }
    }

}
