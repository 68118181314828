import { AuthenticationService } from './../_services/authentication.service';
import { UtilsService } from './../_services/utils.service';
import { Component, Input, OnInit, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Schimb, ListaCompartimente } from '@app/_models/personal';
import { ApiService } from '@app/_services/api.service';




@Component({
    selector: 'schimb-edit',
    templateUrl: './schimb.edit.html',
})

export class SchimbEdit implements OnInit {
    constructor(public activeModal: NgbActiveModal, private apiService: ApiService, private utils: UtilsService,
        private auth: AuthenticationService) { }

    @Input() id;
    @Input() idCompartiment
    schimb: Schimb;
    listaCompartimente: ListaCompartimente[];
    oraStartHH: number = 0
    oraStartMM: number = 0
    durataHH: number = 0
    durataMM: number = 0
    duratanHH: number = 0
    duratanMM: number = 0
    oraSfarsitHH: number = 0
    oraSfarsitMM: number = 0
    oreNoapteHH: number = 0;
    oreNoapteMM: number = 0;

    ngOnInit() {
        if (this.id == 0) {
            this.schimb = new Schimb;
            this.schimb.s_idschimb = '';
            this.schimb.s_codprg = '';
            this.schimb.s_codprg_ = '';
            this.schimb.s_orastartsec = 0;
            this.schimb.s_duratasec = 0;
            this.schimb.s_duratasecnoapte = 0;
            this.schimb.s_idcompartiment = this.idCompartiment;
            this.schimb.s_flag_inactiv = 0;
            console.log(this.schimb)
        } else {
            this.apiService.fdbGetSchimbById(this.id).subscribe((d) => {
                this.schimb = d[0];
                var oraStartSec = +this.schimb.s_orastartsec
                var durataSec = +this.schimb.s_duratasec
                var durataSecNoapte = this.schimb.s_duratasecnoapte;
                console.log(this.schimb)
                var os = this.utils.getHourMinFromSec(oraStartSec)
                this.oraStartHH = os[0]
                this.oraStartMM = os[1]

                var dt = this.utils.getHourMinFromSec(durataSec)
                this.durataHH = dt[0]
                this.durataMM = dt[1]

                var dtn = this.utils.getHourMinFromSec(durataSecNoapte)
                this.oreNoapteHH = dtn[0]
                this.oreNoapteMM = dtn[1]

                let oraSfasitSec: number
                oraSfasitSec = oraStartSec + durataSec
                console.log(oraSfasitSec)

            })
        }

        this.getCompartimente();

    }

    getCompartimente() {
        this.apiService.fdbGetListaCompartimente(this.auth.currentUserValue.s_idpersonal, this.auth.currentUserValue.s_tipuser, '').subscribe((d) => {
            this.listaCompartimente = d;
        })
    }

    calcOraSfarsit() {
        //86400 - secunde pana la ora 24
        //79200 - secunde pana la ora 22
        var oraStartSecNew = this.utils.getSeconds(this.oraStartHH, this.oraStartMM)
        var durataSecNew = this.utils.getSeconds(this.durataHH, this.durataMM)
        var oraSfarsitSecNew = 0;
        var oreNoapte = 0;

        // 79200 - ora 22 - incep ore de noapte
        // 21600 - ora 6 dimineata - se termina ore de noapte
        // 86400 - ora 24

        var oraSfarsitSecBrut = oraStartSecNew + durataSecNew;
        console.log(oraSfarsitSecBrut)
        var oreNoapte = oraSfarsitSecBrut - 79200;
        if (oreNoapte < 0) oreNoapte = 0;
       
        if (oraSfarsitSecBrut >= 86400) {
            oraSfarsitSecNew = oraSfarsitSecBrut - 86400;
            var oraDupa6 = oraSfarsitSecNew - 21600
            console.log(oraDupa6)
            if (oraDupa6 > 0) oreNoapte = oreNoapte - oraDupa6

        } else {
            oraSfarsitSecNew = oraSfarsitSecBrut
        }
        var oe = this.utils.getHourMinFromSec(oraSfarsitSecNew);
        this.oraSfarsitHH = oe[0];
        this.oraSfarsitMM = oe[1]

        var on = this.utils.getHourMinFromSec(oreNoapte);
        this.oreNoapteHH = on[0];
        this.oreNoapteMM = on[1]


    }

    save() {
        this.schimb.s_orastartsec = this.utils.getSeconds(this.oraStartHH, this.oraStartMM)
        this.schimb.s_duratasec = this.utils.getSeconds(this.durataHH, this.durataMM)
        this.schimb.s_duratasecnoapte = this.utils.getSeconds(this.oreNoapteHH, this.oreNoapteMM)

        this.apiService.fdbAddSchimb(this.schimb).subscribe(() => {
            console.log('S-a salvat');
            this.activeModal.close()
        });

    }

}
