import { DialogService } from '@app/_services/dialog.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserPersonal } from '@app/_models/user';
import { UtilsService } from '@app/_services/utils.service';
import { SetAnLunaPontaj } from './setAnLunaPontaj';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  currentUser: UserPersonal;
  constructor(private auth: AuthenticationService, private router: Router, private route: ActivatedRoute,
    private utils: UtilsService, private dialog: DialogService) {

  }

  @Output() loadedComponent = new EventEmitter<string>();
  anLunaString
  userLogat: UserPersonal
  isAdmin: boolean = false;
  anLunaExt
  rndString: any
  btnClass: string = 'btn btn-light'

  ngOnInit(): void {
    this.rndString = Date.now()
    this.auth.getUserLogat.subscribe(user => this.setUserLogat(user));
    this.utils.getAnLunaPontaj()
    this.setAnLunaPontaj()
  }

  setUserLogat(user) {
    this.userLogat = user;
    //console.log('this.userLogat',this.userLogat)
    if (this.userLogat && this.userLogat.s_tipuser == 1) this.isAdmin = true;
    //console.log('this.isAdmin', this.isAdmin)
  }

  setComponentLoaded(event) {
    console.log(event)
    this.loadedComponent.emit(event)
  }
  logout() {
    this.auth.logout();
    this.userLogat = null
    this.router.navigate(['./login'])
  }

  editAnLunaPontaj() {
    this.dialog.custom(SetAnLunaPontaj, { anLunaExtended: this.anLunaExt }, { size: 'md', backdrop: false }).result.then(() => {
      this.setAnLunaPontaj()
    })
  }
  setAnLunaPontaj() {
    var d = new Date()
    var anLunaCurenta = this.utils.getAnlunaFromDate(d)
    console.log('anLunaCurenta', anLunaCurenta)

    this.btnClass = 'btn btn-light'
    if (anLunaCurenta > this.utils.anLunaPontaj) {
      this.btnClass = 'btn btn-warning'
    } else if (anLunaCurenta < this.utils.anLunaPontaj) {
      this.btnClass = 'btn btn-success'
    }

    this.anLunaExt = this.utils.getAlExtendedFromAnLuna(this.utils.anLunaPontaj);
    this.anLunaString = this.anLunaExt.numeLuna + " " + this.anLunaExt.an
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url])

  }

  clearls() {
    // clear local storage
    localStorage.removeItem('currentUser');
    localStorage.removeItem('curentUrl');

  }
}
