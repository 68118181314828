import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Functie, Compartiment } from '@app/_models/personal';
import { ApiService } from '@app/_services/api.service';
import { UserPersonal } from '@app/_models';
import { ColDef } from '@app/controls';
import { UtilsService } from '@app/_services/utils.service';



@Component({
    selector: 'setAnLunaPontaj',
    templateUrl: './setAnLunaPontaj.html',
})

export class SetAnLunaPontaj implements OnInit {
    constructor(public activeModal: NgbActiveModal, private apiService: ApiService, private log: ToastrService, private utils: UtilsService) { }

    @Input() anLunaExtended;
    luni
    indexLunaPontaj
    anPontaj
    


    ngOnInit() {
        console.log(this.anLunaExtended)
        this.luni = this.utils.luni2
        this.indexLunaPontaj = this.anLunaExtended.indexLuna
        this.anPontaj = this.anLunaExtended.an
    }

    setAnLunaPontaj() {
        this.utils.anLunaPontaj = 1 * this.anPontaj * 100 + 1 * this.indexLunaPontaj + 1;
        localStorage.setItem('anLunaPontaj', this.utils.anLunaPontaj)
        this.activeModal.close()
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.onSameUrlNavigation = 'reload';
        // this.router.navigate(['/pontaj', this.idCompartiment, this.utils.anLunaPontaj])
      }
    
      
}
