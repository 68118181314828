import { Injectable, OnInit } from '@angular/core';
import { Compartiment, Functie, Persoana, Personal, ListaCompartimente, ListaUseri, ListaSchimburi, Schimb } from '@app/_models/personal';
import { User, UserPersonal } from '@app/_models/user';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { PersonalPontaj } from '@app/_models/pontaj';
import { AppConfiguration } from './app-config.service';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(private httpClient: HttpClient, private appcfg: AppConfiguration) { }

	//PHP_API_SERVER = "http://localhost/programator.urbis/api";
	//PHP_API_SERVER = this.utils.phpServer;

	get PHP_API_SERVER(){
		return this.appcfg.apiUrl
	}

	


	fdbGetPersonal(idCompartiment, camp, ordine, activ, idUtilizator, tipUser, searchText): Observable<Personal[]> {
		return this.httpClient.get<Personal[]>(`${this.PHP_API_SERVER}/dbpersonal.php?op=fdbGetPersonal&idCompartiment=${idCompartiment}&camp=${camp}&ordine=${ordine}&idUtilizator=${idUtilizator}&tipUser=${tipUser}&activ=${activ}&searchText=${searchText}`);
	}

	fdbGetPersonalConcediu(idCompartiment, camp, ordine, activ, idUtilizator, tipUser, searchText): Observable<Personal[]> {
		return this.httpClient.get<Personal[]>(`${this.PHP_API_SERVER}/dbpersonal.php?op=fdbGetPersonalConcediu&idCompartiment=${idCompartiment}&camp=${camp}&ordine=${ordine}&idUtilizator=${idUtilizator}&tipUser=${tipUser}&activ=${activ}&searchText=${searchText}`);
	}

	fdbGetPersonalPontaj(idCompartiment, camp, ordine, activ, anluna, searchText) {
		return this.httpClient.get<PersonalPontaj[]>(`${this.PHP_API_SERVER}/dbpersonal.php?op=fdbGetPersonalPontaj&idCompartiment=${idCompartiment}&camp=${camp}&ordine=${ordine}&activ=${activ}&anluna=${anluna}&searchText=${searchText}`);
	}

	fdbGetPersoaneForPontaj(idCompartiment, anLuna) {
		const promise = this.httpClient.get(`${this.PHP_API_SERVER}/dbpersonal.php?op=fdbGetPersoaneForPontaj&idCompartiment=${idCompartiment}&anLuna=${anLuna}`).toPromise();
		console.log(promise);
		return promise.then((data) => {
			console.log(data)
			return data;
		}).catch((error) => {
			console.log("Promise rejected with " + JSON.stringify(error));
		});

	}
	fdbGetPersoanaById(id): Observable<Personal[]> {
		return this.httpClient.get<Personal[]>(`${this.PHP_API_SERVER}/dbpersonal.php?op=fdbGetPersoanaById&id=${id}`);
	}
	fdbGetUtilizatorById(id): Observable<UserPersonal[]> {
		return this.httpClient.get<UserPersonal[]>(`${this.PHP_API_SERVER}/dbpersonal.php?op=fdbGetPersoanaById&id=${id}`);
	}
	fdbGetFunctii(searchText) {
		return this.httpClient.get<Functie[]>(`${this.PHP_API_SERVER}/dbpersonal.php?op=fdbGetFunctii&searchText=${searchText}`);
	}
	fdbGetListaUseri(searchText, flag_inactiv) {
		return this.httpClient.get<ListaUseri[]>(`${this.PHP_API_SERVER}/dbpersonal.php?op=fdbGetListaUseri&flag_inactiv=${flag_inactiv}&searchText=${searchText}`);
	}

	//compartiment, schimb, echipe
	fdbGetCompartimentById(id) {
		return this.httpClient.get<Compartiment[]>(`${this.PHP_API_SERVER}/dbcompartiment.php?op=fdbGetCompartimentById&id=${id}`);
	}
	fdbGetListaCompartimente(idUtilizator, tipUser, searchText) {
		return this.httpClient.get<ListaCompartimente[]>(`${this.PHP_API_SERVER}/dbcompartiment.php?op=fdbGetListaCompartimente&idUtilizator=${idUtilizator}&tipUser=${tipUser}&searchText=${searchText}`);
	}
	fdbGetSchimbById(id) {
		return this.httpClient.get<Schimb[]>(`${this.PHP_API_SERVER}/dbcompartiment.php?op=fdbGetSchimbById&id=${id}`);
	}
	fdbGetListaSchimburiOld(idCompartiment, searchText) {
		return this.httpClient.get<ListaSchimburi[]>(`${this.PHP_API_SERVER}/dbcompartiment.php?op=fdbGetListaSchimburi&idCompartiment=${idCompartiment}&searchText=${searchText}`);
	}

	fdbGetListaSchimburi(idCompartiment, searchText) {
		const promise = this.httpClient.get(`${this.PHP_API_SERVER}/dbcompartiment.php?op=fdbGetListaSchimburi&idCompartiment=${idCompartiment}&searchText=${searchText}`).toPromise();
		console.log(promise);
		return promise.then((data) => {
			console.log(data)
			return data;
		}).catch((error) => {
			console.log("Promise rejected with " + JSON.stringify(error));
		});
	}

	fdbGeSchimbForPontaj(idCompartiment, tipzi, status?) {
		if (!tipzi) tipzi='tot';
		if (!status) status='tot';
		return this.httpClient.get(`${this.PHP_API_SERVER}/dbcompartiment.php?op=fdbGeSchimbForPontaj&idcompartiment=${idCompartiment}&tipzi=${tipzi}&status=${status}`)
	}

	fdbGetActivitate(tipzi, searchText) {
		return this.httpClient.get(`${this.PHP_API_SERVER}/dbcompartiment.php?op=fdbGetActivitate&tipzi=${tipzi}&searchText=${searchText}`);
	}
	fdbGetListaEchipe(idCompartiment, searchText) {
		return this.httpClient.get(`${this.PHP_API_SERVER}/dbcompartiment.php?op=fdbGetListaEchipe&idCompartiment=${idCompartiment}&searchText=${searchText}`);
	}
	fdbGetPersoaneForEchipa(idEchipa, idCompartiment, isInEchipa, searchText) {
		return this.httpClient.get(`${this.PHP_API_SERVER}/dbcompartiment.php?op=fdbGetPersoaneForEchipa&idEchipa=${idEchipa}&idCompartiment=${idCompartiment}&isInEchipa=${isInEchipa}&searchText=${searchText}`);
	}

	fdbGetPontajZi(idpontaj, zi) {
		const promise = this.httpClient.get(`${this.PHP_API_SERVER}/dbcompartiment.php?op=fdbGetPontajZi&idpontaj=${idpontaj}&zi=${zi}`).toPromise();
		console.log(promise);
		return promise.then((data) => {
			console.log(data)
			return data;
		}).catch((error) => {
			console.log("Promise rejected with " + JSON.stringify(error));
		});
	}

	//puncte lucru
	fdbGetListaPuncteLucru(idCompartiment, status?, searchText?) {
		if (!status) status='tot';
		if (!searchText) searchText =''
;		const promise = this.httpClient.get(`${this.PHP_API_SERVER}/dbcompartiment.php?op=fdbGetListaPuncteLucru&idCompartiment=${idCompartiment}&status=${status}&searchText=${searchText}`).toPromise();
		return promise.then((data) => {
			return data;
		}).catch((error) => {
			console.log("Promise rejected with " + JSON.stringify(error));
		});
	}

	//utilizatori
	fdbGetUtilizatori(idUtilizator, searchText) {
		return this.httpClient.get(`${this.PHP_API_SERVER}/dbutilizator.php?op=fdbGetUtilizatori&idUtilizator=${idUtilizator}&searchText=${searchText}`)
	}

	fdbGetUtilizatorCompartiment(idUtilizator, tipUser, searchText) {
		return this.httpClient.get(`${this.PHP_API_SERVER}/dbutilizator.php?op=fdbGetUtilizatorCompartiment&idUtilizator=${idUtilizator}&tipUser=${tipUser}&searchText=${searchText}`)
	}

	fdbGetUtilizatoriDisponibili(searchText) {
		return this.httpClient.get(`${this.PHP_API_SERVER}/dbutilizator.php?op=fdbGetUtilizatoriDisponibili&searchText=${searchText}`)
	}

	fdbGetCompartimenteDisponibile(idUtilizator, searchText) {
		return this.httpClient.get(`${this.PHP_API_SERVER}/dbutilizator.php?op=fdbGetCompartimenteDisponibile&idUtilizator=${idUtilizator}&searchText=${searchText}`)
	}


	//utils
	fdbGetElementById(tabel, camp, id, isString) {
		return this.httpClient.get(`${this.PHP_API_SERVER}/utils.php?op=fdbGetElementById&tabel=${tabel}&camp=${camp}&id=${id}&isstring=${isString}`)
	}
	fdbDeleteElementById(tabel, camp, id, isString) {
		return this.httpClient.get(`${this.PHP_API_SERVER}/utils.php?op=fdbDeleteElementById&tabel=${tabel}&camp=${camp}&id=${id}&isstring=${isString}`)
	}

	fdbDeleteElementByWhere(tabel, where) {
		return this.httpClient.get(`${this.PHP_API_SERVER}/utils.php?op=fdbDeleteElementByWhere&tabel=${tabel}&where=${where}`)
	}
	// fdbCheckExists(tabel, camp, valoare, isString){
	// 	return this.httpClient.get(`${this.PHP_API_SERVER}/utils.php?op=fdbCheckExists&tabel=${tabel}&camp=${camp}&valoare=${valoare}&isstring=${isString}`)
	// }

	fdbStergUtilizator(idUtilizator) {
		return this.httpClient.get(`${this.PHP_API_SERVER}/dbutilizator.php?op=fdbStergUtilizator&idUtilizator=${idUtilizator}`)
	}

	fdbCheckExists(tabel, camp, valoare, isString) {
		const promise = this.httpClient.get(`${this.PHP_API_SERVER}/utils.php?op=fdbCheckExists&tabel=${tabel}&camp=${camp}&valoare=${valoare}&isstring=${isString}`).toPromise();
		console.log(promise);
		return promise.then((data) => {
			console.log(data)
			return data;
		}).catch((error) => {
			console.log("Promise rejected with " + JSON.stringify(error));
		});
	}

	fdbGetListaUseri2(searchText, flag_inactiv) {
		const promise = this.httpClient.get<ListaUseri[]>(`${this.PHP_API_SERVER}/dbpersonal.php?op=fdbGetListaUseri&flag_inactiv=${flag_inactiv}&searchText=${searchText}`).toPromise();
		console.log(promise);
		return promise.then((data) => {
			console.log(data)
			return data;
		}).catch((error) => {
			console.log("Promise rejected with " + JSON.stringify(error));
		});
	}



	fdbGetSarbatoriLegale(an, luna) {
		const promise = this.httpClient.get(`${this.PHP_API_SERVER}/utils.php?op=fdbGetSarbatoriLegale&an=${an}&luna=${luna}`).toPromise();
		return promise.then((data) => {
			return data;
		}).catch((error) => {
			console.log("Promise rejected with " + JSON.stringify(error));
		});
	}

	fdbGetConcediuPersoana(idPersonal, an){
		const promise = this.httpClient.get(`${this.PHP_API_SERVER}/dbpersonal.php?op=fdbGetConcediuPersoana&an=${an}&idpersonal=${idPersonal}`).toPromise();
		return promise.then((data) => {
			return data;
		}).catch((error) => {
			console.log("Promise rejected with " + JSON.stringify(error));
		});
	}
	//post
	fdbAddPersonal(persoana): Observable<Persoana> {
		return this.httpClient.post<Persoana>(`${this.PHP_API_SERVER}/dbpost.php?op=fdbAddPersonal`, persoana)
	}
	fdbAddCompartiment(compartiment): Observable<Compartiment> {
		return this.httpClient.post<Compartiment>(`${this.PHP_API_SERVER}/dbpost.php?op=fdbAddCompartiment`, compartiment)
	}

	fdbSetStatus(tabel, campstatus, campid, id, valoare, flag_id_numeric) {
		var dataToSend = {
			tabel: tabel,
			campstatus: campstatus,
			campid: campid,
			id: id,
			valoare: valoare,
			flag_id_numeric: flag_id_numeric
		}
		return this.httpClient.put(`${this.PHP_API_SERVER}/dbpost.php?op=fdbSetStatus`, dataToSend)
	}

	fdbAddSchimb(schimb): Observable<Schimb> {
		console.log(schimb);
		return this.httpClient.post<Schimb>(`${this.PHP_API_SERVER}/dbpost.php?op=fdbAddSchimb`, schimb)
	}

	fdbAddPontaj(idpersonal, codper, codperprec, maxoresupl) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbAddPontaj`,
			{
				s_idpersonalprog: idpersonal,
				s_codper: codper,
				s_codperprec: codperprec,
				s_maxoresupl: maxoresupl
			})
	}

	fdbAddPontajCompartiment(idcompartiment, codper, codperprec, maxoresupl) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbAddPontajCompartiment`,
			{
				s_idcompartiment: idcompartiment,
				s_codper: codper,
				s_codperprec: codperprec,
				s_maxoresupl: maxoresupl
			})
	}

	fdbSaveZi(zi, schimb, sch, durata, duratanoapte, duratasuplimentara, idpontaj, idechipa, anluna) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbSaveZi`,
			{
				s_zi: zi,
				s_codprg: schimb,
				s_sch: sch,
				s_durata: durata,
				s_duratanoapte: duratanoapte,
				s_duratasuplimentara: duratasuplimentara,
				s_idpontaj: idpontaj,
				s_idechipa: idechipa,
				s_codper: anluna,
			})
	}

	fdbSaveSapt(zi, schimb, sch, durata, duratanoapte, duratasuplimentara, idpontaj, idechipa, anluna) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbSaveSapt`,
			{
				s_zi: zi,
				s_codprg: schimb,
				s_sch: sch,
				s_durata: durata,
				s_duratanoapte: duratanoapte,
				s_duratasuplimentara: duratasuplimentara,
				s_idpontaj: idpontaj,
				s_idechipa: idechipa,
				s_codper: anluna
			})
	}

	fdbStergZiua(zi, schimb, sch, durata, duratanoapte, idpontaj) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbStergZiua`,
			{
				s_zi: zi,
				s_codprg: schimb,
				s_sch: sch,
				s_durata: durata,
				s_duratanoapte: duratanoapte,
				s_idpontaj: idpontaj,
			})
	}

	fdbStergSaptamana(zi, schimb, sch, durata, duratanoapte, idpontaj) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbStergSaptamana`,
			{
				s_zi: zi,
				s_codprg: schimb,
				s_sch: sch,
				s_durata: durata,
				s_duratanoapte: duratanoapte,
				s_idpontaj: idpontaj,
			})
	}

	fdbStergPunctLucruZi(zi, idpontaj){
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbStergPunctLucruZi`,
			{
				s_zi: zi,
				s_idpontaj: idpontaj,
			})
	}

	fdbStergPunctLucruSapt(zi, idpontaj){
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbStergPunctLucruSapt`,
			{
				s_zi: zi,
				s_idpontaj: idpontaj,
			})
	}

	fdbSaveNota(zi, idpontaj, nota) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbSaveNota`,
			{
				s_zi: zi,
				s_idpontaj: idpontaj,
				s_nota: nota,
			})
	}

	fdbSavePunctLucruPontajZi(zi, idpontaj, idpunctlucru) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbSavePunctLucruPontajZi`,
			{
				s_zi: zi,
				s_idpontaj: idpontaj,
				s_idpunctlucru: idpunctlucru,
			})
	}
	fdbSavePunctLucruPontajSapt(zi, idpontaj, idpunctlucru) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbSavePunctLucruPontajSapt`,
			{
				s_zi: zi,
				s_idpontaj: idpontaj,
				s_idpunctlucru: idpunctlucru,
			})
	}


	fdbSetUtilizator(utilizator) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbSetUtilizator`, utilizator)
	}

	fdbSetCompartimenteUtilizator(idUtilizator, listaIds) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbSetCompartimenteUtilizator`,
			{
				idUtilizator: idUtilizator,
				listaIds: listaIds,
			})
	}

	fdbAddEchipa(idEchipa, echipa, idCompartiment) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbAddEchipa`,
			{
				idEchipa: idEchipa,
				echipa: echipa,
				idCompartiment: idCompartiment
			})

	}
	fdbSetPersoaneEchipa(idEchipa, listaIds) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbSetPersoaneEchipa`,
			{
				idEchipa: idEchipa,
				listaIds: listaIds,
			})
	}

	fdbSetEchipeForPerioadaPontaj(idCompartiment, anLuna) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbSetEchipeForPerioadaPontaj`,
			{
				idCompartiment: idCompartiment,
				anLuna: anLuna,
			})

	}

	fdbAddPunctLucru(idPunctLucru, punctLucru, idCompartiment) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbAddPunctLucru`,
			{
				idPunctLucru: idPunctLucru,
				punctLucru: punctLucru,
				idCompartiment: idCompartiment
			})

	}

	fdbSetPersoaneForPontaj(idCompartiment, anLuna, listaIds) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbSetPersoaneForPontaj`,
			{
				idCompartiment: idCompartiment,
				anLuna: anLuna,
				listaIds: listaIds,
			})

	}
	fdbUpdatePontaj(idpontaj, camp, valoare) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbUpdatePontaj`,
			{
				idpontaj: idpontaj,
				campUpdate: camp,
				valoareUpdate: valoare,
			})
	}

	fdbUpdatePontajMultiple(idpontaj, listacampuri, listavalori) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbUpdatePontajMultiple`,
			{
				idpontaj: idpontaj,
				campUpdate: listacampuri,
				valoareUpdate: listavalori,
			})
	}

	fdbAddZileConcediu(idPersonal, an, zc){
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbAddZileConcediu`,
			{
				idpersonal: idPersonal,
				an: an,
				zc: zc,
			})

	}

	fdbAddSarbatoareLegala(datasl){
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbAddSarbatoareLegala`,
			{
				s_datasl: datasl,
			})
	}

	fdbReordonare(ord, idcompartiment, anluna, idpersonal){
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbReordonare`,
			{
				ord: ord,
				idcompartiment: idcompartiment,
				anluna: anluna,
				idpersonal: idpersonal
			})
	}

	fdbReordonareCamp(campsort, idcompartiment, anluna){
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbReordonareCamp`,
			{
				campsort: campsort,
				idcompartiment: idcompartiment,
				anluna: anluna,
			})
	}

	fdbRecuperareParola(email) {
		return this.httpClient.post(`${this.PHP_API_SERVER}/dbpost.php?op=fdbRecuperareParola`,
			{
				email: email,
			})
	}
	// nefolosit
	fdbRecuperareParola2(email) {
		const promise = this.httpClient.get(`${this.PHP_API_SERVER}/dbpost.php?op=fdbRecuperareParola&email=${email}`).toPromise();
		console.log(promise);
		return promise.then((data) => {
			console.log('data', data)
			return data;
		}).catch((error) => {
			console.log("Promise rejected with " + JSON.stringify(error));
		});
	}

	fdbVerificaSchimbParola(email, pwd) {
		const promise = this.httpClient.post(`${this.PHP_API_SERVER}/userauth.php?op=fdbGetUserAuth`, 
		{
			s_email: email,
			s_pwd: pwd
		} ).toPromise();
		return promise.then((data) => {
			console.log('data', data)
			return data;
		}).catch((error) => {
			console.log("Promise rejected with " + JSON.stringify(error));
		});
	}

	fdbChangePassword(email, pwdnou) {
		const promise = this.httpClient.post(`${this.PHP_API_SERVER}/userauth.php?op=fdbChangePassword`, 
		{
			s_email: email,
			s_pwdnou: pwdnou
		} ).toPromise();
		return promise.then((data) => {
			console.log('data', data)
			return data;
		}).catch((error) => {
			console.log("Promise rejected with " + JSON.stringify(error));
		});
	}

}
