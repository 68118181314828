<div class="col-12">
  <h4>Personal {{numeCompartiment}}</h4>
  <form #f="ngForm">
    <div class="alert alert-info col-12">
      <div class="col-12">
        <div class="form-group row">

          <div class="col-6 row">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-search"></i></div>
              </div>

              <input type="text" class="form-control form-control-sm col-4" name="cauta" [(ngModel)]="searchText"
                (ngModelChange)="getListaPersonal()">
            </div>
          </div>
          <div class="col-6 text-right">
            <button class="btn btn-sm btn-success  ml-1" (click)="edit(0)" *ngIf="visibleButton()"><i
                class="fa fa-plus-circle"></i>&nbsp;Adauga</button>
            <button class="btn btn-sm btn-primary ml-1" (click)="edit(idPersonal)" [disabled]="disableButton()"
              *ngIf="visibleButton()"><i class="fa fa-edit"></i>&nbsp;Modifica</button>
            <button class="btn btn-sm btn-info ml-1" (click)="zileConcediu()" [disabled]="disableButton()"
              *ngIf="visibleButton()"><i class="fa fa-edit"></i>&nbsp;Zile concediu</button>
            <button class="btn btn-sm btn-warning ml-1" (click)="setStatus()" [disabled]="disableButton()"><i
                class="{{isInactiv == 1 ? 'fa fa-lock' : 'fa fa-unlock'}}"></i>&nbsp;Status</button>
            <button class="btn btn-sm btn-danger ml-1" (click)="sterge(idPersonal)" [disabled]="disableButton()"
              *ngIf="visibleButton()"><i class="fa fa-edit"></i>&nbsp;Sterge</button>
          </div>
        </div>

      </div>
    </div>
  </form>
  <div class="col-12" *ngIf="idCompartiment">
   <navigaretab></navigaretab>
  </div>
  <ag-grid-angular style="height: 70vh;" class="ag-theme-alpine" [gridOptions]="grid.gridOptions">
  </ag-grid-angular>
  <!-- <div>
    <table class="table table-sm table-striped table-hover table-bordered">
      <thead class="thead-dark">
        <tr>
          <th>Nr.</th>
          <th><a class="lnkTableHeadDark" href="#" (click)="setSort('s_numefull')">Nume</a></th>
          <th><a class="lnkTableHeadDark" href="#" (click)="setSort('s_marca')">Marca</a></th>
          <th><a class="lnkTableHeadDark" href="#" (click)="setSort('s_data_inactiv')">Dezactivat</a></th>
          <th><a class="lnkTableHeadDark" href="#" (click)="setSort('s_coloana')">Coloana</a></th>
          <th><a class="lnkTableHeadDark" href="#" (click)="setSort('s_functie')">Functie</a></th>
          <th><a class="lnkTableHeadDark" href="#" (click)="setSort('s_compartiment')">Compartiment</a></th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let p of listaPersonal; index as i">
          <td class="text-right">{{ i+1 }}</td>
          <td>{{ p.s_numefull }}</td>
          <td>{{ p.s_marca }}</td>
          <td>{{ ymd2dmy(p.s_data_inactiv) }}</td>
          <td>{{ p.s_coloana }}</td>
          <td>{{ p.s_functie }}</td>
          <td>{{ p.s_compartiment }}</td>
          <td>
            <button class="btn btn-sm btn-success" (click)="edit(p.s_idpersonal)"><i class="fa fa-edit"></i></button>
            &nbsp;
            <button id="activare" class="btn btn-sm {{p.s_inactiv == 1 ? 'btn-dark' : 'btn-warning'}}"
              (click)="setActiv(p.s_idpersonal, p.s_inactiv)"><i
                class="{{p.s_inactiv == 1 ? 'fa fa-lock' : 'fa fa-unlock'}}"></i></button>
          </td>
        </tr>
      </tbody>
    </table>

  </div> -->