<div class="modal-header bg-info text-white">
  <h4 class="modal-title">Utilizator</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="container form-horizontal" #f="ngForm">
    <fieldset *ngIf="isNou">
      
      <div class="form-row col-12">
        <div class="col-8">
          <input type="text" class="form-control form-control-sm" required [(ngModel)]="idUtilizator"
            name="idUtilizator" [typeahead]="utilizatori" (selectItem)=setUtilizator($event) [disabled]="!isNou"  [colDef]="colUtilizator"
            display="s_numefull" bind="s_idpersonal"/>
        </div>
        <div class="col-4">
          
        </div>
      </div>
    </fieldset>
    <fieldset *ngIf="!isNou && utilizator">
        <div class="form-row col-12">
          <div class="col-8">
            <input type="text" class="form-control form-control-sm" required [(ngModel)]="utilizator.s_numefull"
              name="s_numefull" disabled/>
          </div>
          <div class="col-4">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [(ngModel)]="isSetParola" id="isSetParola"
                name="isSetParola" [disabled]="isNou">
              <label class="form-check-label" for="isSetParola">
                Modifica datele de conectare
              </label>
            </div>
          </div>
        </div>
      </fieldset>
    <fieldset *ngIf="utilizator">
      <div class="form-row col-12">
        <div class="col-4">
          <label class="col-form-label">Tip utilizator</label>
          <select class="form-select form-select-sm" name="s_tipuser" [(ngModel)]="utilizator.s_tipuser">
            <option *ngFor="let p of listaTipUser" [value]="p.s_tipUser">{{p.numeTipUser}}</option>
        </select>
        </div>
      </div>
      <div class="form-row col-12">
        <div class="col-4">
          <label class="col-form-label">Email</label>
          <input type="text" class="form-control form-control-sm" name="email" [(ngModel)]="utilizator.s_email"
            [disabled]="!isSetParola">
        </div>
        <div class="col-4">
          <label class="col-form-label">Parola</label>
          <input type="password" class="form-control form-control-sm" name="pwd1" [(ngModel)]="pwd1"
            [disabled]="!isSetParola">
        </div>
        <div class="col-4">
          <label class="col-form-label">Repeta parola</label>
          <input type="password" class="form-control form-control-sm" name="pwd2" [(ngModel)]="pwd2"
            [disabled]="!isSetParola">
        </div>
      </div>
      <div class="col-12">
        <h5>Selecteaza compartimente pentru pontaj</h5>
        <ag-grid-angular style="height: 30vh;" class="ag-theme-alpine" [gridOptions]="grid.gridOptions">
        </ag-grid-angular>
      </div>
    </fieldset>
  </form>

</div>
<div class="modal-footer" *ngIf="utilizator">
  <button type="button" class="btn btn-success" (click)="save()" [disabled]="isDisabled()">Salveaza</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Anuleaza</button>
</div>
